


































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'

@Component
export default class ChangeCoordinationDialog extends Vue {
  @Prop({ default: null, required: true } ) recorderName?: string
  changeTownRecorderCoordDialog = false

  mounted() {
    this.changeTownRecorderCoordDialog = true
  }

  @Emit()
  cancel(): void {
    this.changeTownRecorderCoordDialog = false
  }

  @Emit()
  changeCoordination(): void {
    this.changeTownRecorderCoordDialog = false
  }
}
