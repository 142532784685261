<template v-if="hasCustomerAdminPermission">
  <v-container>
    <p class="title">VPN設定</p>
    <v-layout
      justify-center
      wrap
      align-space-between>
      <template v-if="this.isLTE(computedRecorder.type)">
        <v-flex xs6> <p class="subheading">バーチャルIPアドレス: </p> </v-flex>
        <v-flex xs6> {{ computedRecorder.virtualip }} </v-flex>
      </template>
      <v-flex xs6> <p class="subheading">VPNコード: </p> </v-flex>
      <v-flex xs6> {{ computedRecorder.code }} </v-flex>
      <v-flex xs6> <p class="subheading">サーバーURL: </p> </v-flex>
      <v-flex xs6> {{ computedRecorder.url }} </v-flex>
      <v-flex xs6> <p class="subheading">テナントID: </p> </v-flex>
      <v-flex xs6> {{ computedRecorder.tenant }} </v-flex>
      <v-flex xs6> <p class="subheading">グループID: </p> </v-flex>
      <v-flex xs6> {{ computedRecorder.group }} </v-flex>
      <v-flex xs6> <p class="subheading">UserID: </p> </v-flex>
      <v-flex xs6> {{ computedRecorder.username }} </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  props: {
    recorder: {
      type: Object,
      required: true,
    },
  },
  computed: {
    computedRecorder() {
      if (this.recorder.vpn){
        return  {
          code : this.recorder.vpn.code,
          url : this.recorder.vpn.url,
          tenant : this.recorder.vpn.tenant,
          group : this.recorder.vpn.group,
          username : this.recorder.vpn.username,
          type: this.recorder.type,
          virtualip: this.recorder.vpn.virtualip,
        }
      }
      return this.recorder;
    },
    status() {
      return this.$store.state.recorders.recorder.status;
    },
  },
  mounted() {
  },
  methods: {
  },
  watch: {
    recorder: {
      immediate: true,
      handler() {
        if (!this.recorder.id){
          return;
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
