<template>
  <v-container>
    <p class="title">
      バージョン
    </p>
    <v-layout
      justify-center
      wrap
      column
      align-space-between
    >
      <v-flex xs16>
        <v-flex xs16>
          <p class="subheading">
            タウンレコーダーファームウェアバージョン:
          </p>
        </v-flex>
        <v-flex xs16>
          <p>
            {{ info.fw_ver }}
            <template v-if="this.isLTE(recorder.type) && !isLTEMailNotificationPlan(recorder.plan)">
              <v-btn
                :disabled="!this.hasMaintenanceOperation(recorder.organization_id) || processing"
                @click.prevent="showVersionUpDialog('townrec')"
              >
                実行
              </v-btn>
            </template>
          </p>
        </v-flex>
      </v-flex>
      <template v-if="this.isLTE(recorder.type)">
        <v-flex xs16>
          <p class="subheading">
            LTEファームウェアバージョン:
          </p>
        </v-flex>
        <v-flex>
          <p>
            {{ info.lte_ver }}
            <v-btn
              v-if="!isLTEMailNotificationPlan(recorder.plan)"
              :disabled="!this.hasMaintenanceOperation(recorder.organization_id) || processing"
              @click.prevent="showVersionUpDialog('ltemodule')"
            >
              実行
            </v-btn>
          </p>
        </v-flex>
      </template>
      <template v-if="this.isSigfox(recorder.type)">
        <v-flex xs16>
          <p class="subheading">
            sigfoxファームウェアバージョン:
          </p>
        </v-flex>
        <v-flex xs16>
          <p>{{ info.lte_ver }}</p>
        </v-flex>
      </template>
      <template v-if="!this.isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs16>
          <p class="subheading">
            カメラファームウェアバージョン:
          </p>
        </v-flex>
        <v-flex xs16>
          <p>
            {{ info.cam_ver }}
            <template v-if="this.isLTE(recorder.type) && !isLTEMailNotificationPlan(recorder.plan)">
              <v-btn
                :disabled="!this.hasMaintenanceOperation(recorder.organization_id) || processing"
                @click.prevent="showVersionUpDialog('camera')"
              >
                実行
              </v-btn>
            </template>
          </p>
        </v-flex>
      </template>
      <template v-if="!this.isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs16>
          <p class="subheading">
            カメラマイコンファームウェアバージョン:
          </p>
        </v-flex>
        <v-flex xs16>
          <p>
            {{ info.cam_mcu_ver }}
            <template v-if="this.isLTE(recorder.type) && !isLTEMailNotificationPlan(recorder.plan)">
              <v-btn
                :disabled="!this.hasMaintenanceOperation(recorder.organization_id) || processing"
                @click.prevent="showVersionUpDialog('cam_mcu')"
              >
                実行
              </v-btn>
            </template>
          </p>
        </v-flex>
      </template>

      <template v-if="this.isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs16>
          <p class="subheading">
            タウンレコーダーマイコンファームウェアバージョン:
          </p>
        </v-flex>
        <v-flex xs16>
          <p>
            {{ info.mcu_ver }}
          </p>
        </v-flex>
      </template>
    </v-layout>

    <v-dialog
      v-model="dialog"
      max-width="520"
    >
      <v-card>
        <v-card-title class="subheading">
          <v-flex xs12 class="dialog-title-margin">
            ファームウェア({{ targetNameMap[target] }})のバージョンアップをします。
          </v-flex>
          <v-flex xs12 class="dialog-title-margin">
            よろしいですか？
          </v-flex>
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn
            @click="dialog = false"
            class="dialog-button-margin"
            color="grey darken-2"
            outline
          >
            いいえ
          </v-btn>

          <v-btn
            @click="submit(target)"
            class="dialog-button-margin"
            color="primary"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as types from "~/store/types";
export default {
  props: {
    recorder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      processing: false,
      target: '',
      targetNameMap: {
        'townrec': 'タウンレコーダー',
        'ltemodule': 'LTE',
        'camera': 'カメラ',
        'cam_mcu': 'カメラマイコン'
      },
    }
  },
  computed: {
    info() {
      return this.$store.state.recorders.recorder.info;
    },
  },
  watch: {
    recorder: {
      immediate: true,
      async handler() {
        if (!this.recorder.id){
          return;
        }
        await this.$store.dispatch("setLoadingState", true);
        await this.$store.dispatch('recorders/getInfo', this.recorder.id);
        await this.$store.dispatch("setLoadingState", false);
      }
    },
  },
  mounted() {
  },
  methods: {
    showVersionUpDialog(target) {
      this.dialog = true;
      this.target = target;
    },
    submit(target) {
      this.dialog = false;
      if (this.processing) return;
      this.processing = true;
      this.fwupStart(target)
        .then(() => {
          this.processing = false;
        });
    },
    fwupStart(target){
      // デバイス制御CGI実行
      // const url = "http://" + this.recorder.vpn.virtualip + '/device_ctrl.cgi'
      // const cgi = new this.$cgi('POST', url, this.recorder.vpn.username, this.recorder.vpn.password);

      const cgiParams = "firmup=" + target + "&schedule=0&";

      // CGI実行
      this.$axios.post(process.env.cgiBaseUrl, {
        recorderID: this.recorder.id,
        method: 'POST',
        url: '/device_ctrl.cgi',
        data: cgiParams,
      }, {
        timeout: 300000,
      }).then(data => {
        console.log(data)
        console.log("デバイス制御CGI実行 [成功]")
      }).catch(errorCode => {
        console.log(errorCode)
        console.log("デバイス制御CGI実行 [失敗]")
        if(errorCode.response.status == 403) return;
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: 'デバイス制御に失敗したため、ファームアップ設定を変更しませんでした。'});
      });
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // 多重送信防止タイムアウト
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
