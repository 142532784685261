import Vue from 'vue';
import * as PLAN from '~/constants/plan';
import moment from 'moment';


Vue.mixin({
  computed: {
    hasSystemAdminPermission() {
      return this.$store.getters['auth/hasSystemAdminPermission'];
    },
    hasCustomerAdminPermission() {
      return this.$store.getters['auth/hasCustomerAdminPermission'];
    },
    hasUserAdminPermission() {
      return this.$store.getters['auth/hasUserAdminPermission'];
    },
  },
  methods: {
    /**
     * 指定された時間処理を停止する。
     *
     * @param timeAsMs 待つ時間。単位はms。
     * @returns {Promise<void>} 処理が完了した際にreturnされる。
     */
    sleep(timeAsMs) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, timeAsMs);
      });
    },
    /**
     * ギガバイト→キロバイトに変換する。
     *
     * @param gigabyteNumber ギガバイトで表された数値
     * @returns {number} キロバイトで表された数値
     */
    convertGigabyteToKilobyte(gigabyteNumber) {
      return gigabyteNumber * 1000000;
    },
    /**
     * キロバイト→ギガバイトに変換する。
     *
     * @param kilobyteNumber キロバイトで表された数値
     * @returns {number} ギガバイトで表された数値
     */
    convertKilobyteToGigabyte(kilobyteNumber) {
      return kilobyteNumber / 1000000;
    },
    isIE() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1;
    },
    // auth.jsのstateに設定したレコーダー権限を全取得
    getUserRecorderPermissions() {
      return this.$store.getters['auth/getRecorderPermissions'];
    },
    resetAllState() {
      this.$store.commit('auth/resetState');
      this.$store.commit('broadcastgroups/resetState');
      this.$store.commit('resetState');
      this.$store.commit('map/resetState');
      this.$store.commit('operationhistories/resetState');
      this.$store.commit('organizations/resetState');
      this.$store.commit('patterns/resetState');
      this.$store.commit('recorders/resetState');
      this.$store.commit('streaming_ctrl/resetState');
      this.$store.commit('users/resetState');
      this.$store.commit('view/resetState');
    },
    /**
     * 低遅延モードライブの開始権限を持つか判定する。
     *
     * 低遅延モードライブは、ライブ閲覧権限に加え、以下のいずれかの権限を持っている場合開始できる。
     * ・PTZカメラ操作
     * ・接点出力制御
     * ・遠隔放送
     * ・RS-485制御
     *
     * @param {string} organizationId 選択されたレコーダーが所属する組織のID
     * @returns {boolean} 低遅延モードライブの開始権限を持っているかどうか
     */
    hasLowLatencyMode(organizationId) {
      const hasAnyAuxOutPermission = [1, 2, 3].some(n => this.hasAuxoutOperation(organizationId, n));
      return this.hasLiveOperation(organizationId) &&
        (
          this.hasPtzOperation(organizationId) ||
          hasAnyAuxOutPermission ||
          this.hasBroadcastOperation(organizationId) ||
          this.hasRs485outOperation(organizationId)
        )
    },
    /**
     * レコーダー権限判定
     *   各種レコーダー操作権限を保持するか判定
     */
    // ライブ映像確認権限判定 LiveOperation
    hasLiveOperation(organizationId) {
      const permissions = this.getUserRecorderPermissions()
      if (!permissions) {
        return false;
      }
      if (permissions[organizationId]) {
        return permissions[organizationId].live_operation;
      }
      return false;
    },
    // PTZカメラ操作権限判定 PtzOperation
    hasPtzOperation(organizationId) {
      const permissions = this.getUserRecorderPermissions()
      if (!permissions) {
        return false;
      }
      if (permissions[organizationId]) {
        return permissions[organizationId].ptz_operation;
      }
      return false;
    },
    // 接点出力n操作権限判定
    hasAuxoutOperation(organizationId, n) {
      const permissions = this.getUserRecorderPermissions()
      if (!permissions) {
        return false;
      }
      if (permissions[organizationId]) {
        switch (n) {
          case 1:
            return permissions[organizationId].auxout1_operation;
          case 2:
            return permissions[organizationId].auxout2_operation;
          case 3:
            return permissions[organizationId].auxout3_operation;
        }
      }
      return false;
    },
    // 接点出力1操作権限判定  Auxout1Operation
    hasAuxout1Operation(organizationId) {
      return this.hasAuxoutOperation(organizationId, 1);
    },
    // 接点出力2操作権限判定  Auxout2Operation
    hasAuxout2Operation(organizationId) {
      return this.hasAuxoutOperation(organizationId, 2);
    },
    // 接点出力3操作権限判定  Auxout3Operation
    hasAuxout3Operation(organizationId) {
      return this.hasAuxoutOperation(organizationId, 3);
    },
    // RS485出力操作権限判定  Rs485outOperation
    hasRs485outOperation(organizationId) {
      const permissions = this.getUserRecorderPermissions()
      if (!permissions) {
        return false;
      }
      if (permissions[organizationId]) {
        return permissions[organizationId].rs485out_operation;
      }
      return false;
    },
    // 遠隔放送権限判定 BroadcastOperation
    hasBroadcastOperation(organizationId) {
      const permissions = this.getUserRecorderPermissions();
      if (!permissions) {
        return false;
      }
      if (permissions[organizationId]) {
        return permissions[organizationId].broadcast_operation;
      }
      return false;
    },
    // メンテナンス操作権限判定 MaintenanceOperation
    hasMaintenanceOperation(organizationId) {
      const permissions = this.getUserRecorderPermissions();
      if (!permissions) {
        return false;
      }
      if (permissions[organizationId]) {
        return permissions[organizationId].maintenance_operation;
      }
      return false;
    },
    // 遠隔ダウンロード権限判定 DownloadOperation
    hasDownloadOperation(organizationId) {
      const permissions = this.getUserRecorderPermissions();
      if (!permissions) {
        return false;
      }
      if (permissions[organizationId]) {
        return permissions[organizationId].download_operation;
      }
      return false;
    },

    // レコーダータイプ：LTE
    isLTE(type) {
      return type === '1';
    },
    // レコーダータイプ：Sigfox
    isSigfox(type) {
      return type === '2';
    },

    // プラン：メール通知
    isMailNotificationPlan(plan) {
      return plan === PLAN.MAIL_NOTIFICATION || this.isLTEMailNotificationPlan(plan);
    },

    // プラン：LTEメール通知
    isLTEMailNotificationPlan(plan) {
      return plan === PLAN.LTE_MAIL_NOTIFICATION ||
             plan === PLAN.LTE_MAIL_NOTIFICATION_GR301 ||
             plan === PLAN.LTE_MAIL_NOTIFICATION_DL_WIDE;
    },

    // メール通知プラン3種
    isMailThreePlan(plan) {
      return plan === PLAN.MAIL_NOTIFICATION ||
             plan === PLAN.LTE_MAIL_NOTIFICATION ||
             plan === PLAN.LTE_MAIL_NOTIFICATION_GR301;
    },

    // 旧型LTEレコーダープラン
    isLTEMailNotificationPlanGR301(plan) {
      return plan === PLAN.LTE_MAIL_NOTIFICATION_GR301;
    },

    // プラン：ダウンロードプランワイド
    isLTEMailNotificationPlanWide(plan) {
      return plan === PLAN.LTE_MAIL_NOTIFICATION_DL_WIDE;
    },

    // 新プラン：ダウンロードプランワイド / 拡声ライブプランDLワイド
    isPlanWide(plan) {
      return (
        plan === PLAN.LTE_MAIL_NOTIFICATION_DL_WIDE ||
        plan === PLAN.LOUD_LIVE_DL_WIDE
      );
    },

    /**
     * 日付を比較し、条件を満たすかどうかを返す。
     *
     * @param start {string} 開始日
     * @param end {string} 終了日
     * @param unit {string} 単位 moment.jsのdiffメソッドで指定できる単位と同じ。
     * @param criteria {function} 比較条件を表す関数 引数としてnumber型で日付の差分を取り、booleanで比較結果を返す。
     * @returns {boolean} criteriaを満たすかどうか。
     */
    compareDate(start, end, unit, criteria) {
      const startDate = moment(start, 'YYYY-MM-DD', true);
      const endDate = moment(end, 'YYYY-MM-DD', true);
      const diff = endDate.diff(startDate, unit);
      return criteria(diff);
    },

    csvToArray(csv) {
      const reviver = function (r, c, v) { return v; };
      const chars = csv.split(''), cc = chars.length,table = [];
      let start, end, row, c = 0;
      while (c < cc) {
        table.push(row = []);
        while (c < cc && '\r' !== chars[c] && '\n' !== chars[c]) {
          start = end = c;
          if ('"' === chars[c]){
            start = end = ++c;
            while (c < cc) {
              if ('"' === chars[c]) {
                if ('"' !== chars[c+1]) { break; }
                else { chars[++c] = ''; } // unescape ""
              }
              end = ++c;
            }
            if ('"' === chars[c]) { ++c; }
            while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { ++c; }
          } else {
            while (c < cc && '\r' !== chars[c] && '\n' !== chars[c] && ',' !== chars[c]) { end = ++c; }
          }
          row.push(reviver(table.length-1, row.length, chars.slice(start, end).join('')));
          if (',' === chars[c]) { ++c; }
        }
        if ('\r' === chars[c]) { ++c; }
        if ('\n' === chars[c]) { ++c; }
      }
      return table;
    },

    extractErrorMessage( error ) {
      if(!error) return "";
      if(!error.response) return "";
      if(!error.response.data) return "";
      if(error.response.data) {
        if(error.response.data.message) {
          return error.response.data.message;
        } else {
          return error.response.data;
        }
       }
    }
  }
});
