const initialState = () => ({
  image_size_list: [],
  image_quarity_list: [],
  frame_rate_list: [],
});

export const state = initialState;

export const getters = {
  image_size_list: (state) => {
    return state.image_size_list;
  },
  image_quarity_list: (state) => {
    return state.image_quarity_list;
  },
  frame_rate_list: (state) => {
    return state.frame_rate_list;
  },
};

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setImageSizeList(state, data) {
    state.image_size_list = _.clone(data);
  },
  setImageQuarityList(state, data) {
    state.image_quarity_list = _.clone(data);
  },
  setFrameRateList(state, data) {
    state.frame_rate_list = _.clone(data);
  },
};

export const actions = {
  setImageSizeList({ commit }, data) {
    commit('setImageSizeList', data);
  },
  setImageQuarityList({ commit }, data) {
    commit('setImageQuarityList', data);
  },
  setFrameRateList({ commit }, data) {
    commit('setFrameRateList', data);
  },
};
