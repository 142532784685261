const initialState = () => ({
  // 一覧画面においてフィルタリングした結果を保持
  // フィルタ部品があるコンポーネントやフィルタ結果を反映するコンポーネント等、複数コンポーネントで共有する情報のため store に保持
  filters: {},
  data: {},
  recorders: [],
});

export const state = initialState;

// MEMO: ミューテーションは、ストアの状態を変更する。
export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setFilters(state, data) {
    state.filters = _.clone(data);
  },
  setBroadcastGroups(state, data) {
    _.each(data, i => {
      state.data[i.id] = i;
    });
  },
  setBroadcastGroupRecorders(state, data) {
    state.data[data.id].recorders = data.recorders;
    state.recorders = _.map(data.recorders, r => r.id);
  },
  setRecorders(state, id) {
    state.recorders = _.map(state.data[id].recorders, r => r.id);
  },
};

// MEMO: アクションは、ミューテーションをコミットする。
// MEMO: アクションは、 store.dispatch がトリガーとなって実行される。
export const actions = {
  // 一覧画面で「放送グループ」を絞り込むために組織フィルタを変更する。(dispatch from filter.vue)
  filter({ commit }, data) {
    commit('setFilters', data);
  },
  list({ commit }) {
    return new Promise((resolve) => {
      this.$axios.get(process.env.apiBaseUrl + "/broadcast_groups", {}, {
        //withCredentials: true,
        timeout: 300000,
      }).then(res => {
        commit('setBroadcastGroups', res.data);
        resolve(res.data);
      });
    });
  },
  listRecorders({ commit }, data) {
    this.$axios.get(process.env.apiBaseUrl + "/broadcast_groups/" + data.organizationID + "/" + data.broadcastGroupID + "/related", {}, {
      //withCredentials: true,
      timeout: 300000,
    }).then(res => {
      commit('setBroadcastGroupRecorders', res.data);
    });
  },
};

// MEMO: ゲッターは、ストア内の算出プロパティ
export const getters = {
  filters: (state) => {
    return state.filters;
  },
};
