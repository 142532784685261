import Vue from 'vue';

const initialState = () => ({
  data: {},
});

export const state = initialState;

export const getters = {
  data: (state) => {
    return state.data;
  },
};

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setPatterns(state, data) {
    state.data = data;
  },
  removePattern(state, id) {
    state.data = _.omit(state.data, id);
  },
};

export const actions = {
  list({ commit },id) {
    return new Promise(((resolve, reject) => {
      this.$axios.get(process.env.apiBaseUrl + "/patterns/" + id, {}, {
        timeout: 300000,
      }).then(res => {
        commit('setPatterns', res.data);
        resolve(res.data);
      }) .catch(err => {
        console.log(err);
        reject(err);
      });
    }));
  },
  save({ commit }, payload) {
    return new Promise(((resolve, reject) => {
      this.$axios.post(process.env.apiBaseUrl + "/pattern", payload, {
        //withCredentials: true,
        timeout: 300000,
      }).then(res => {
        resolve(res.data.id);
      }) .catch(err => {
        console.log(err);
        reject(err);
      })
    }));
  },
  update({ commit }, data) {
    return new Promise(((resolve, reject) => {
      this.$axios.put(process.env.apiBaseUrl + "/pattern/" + data.id, data.payload, {
        //withCredentials: true,
        timeout: 300000,
      }).then(res => {
        resolve(res);
      }) .catch(err => {
        console.log(err);
        reject(err);
      })
    }));
  },
  remove({ commit }, id) {
    return new Promise((resolve, reject) => {
      this.$axios.delete(process.env.apiBaseUrl + "/pattern/" + id, {
        //withCredentials: true,
        timeout: 300000,
      }).then(res => {
        commit('removePattern', id);
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    });
  },
};
