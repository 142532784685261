<template>
  <div
    v-if="this.$route.name === 'maps'"
    class="right-drawer-in-map">
    <ViewRightDrawerInMap />
  </div>
</template>

<script>
import ViewRightDrawerInMap from '~/components/maps/rightDrawerInMap.vue';

export default {
  components: {
    ViewRightDrawerInMap,
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
</style>
