<template>
  <v-container>
    <p class="title">応答制御</p>
    <v-layout justify-center wrap align-space-between>
      <v-flex xs6>
        <p class="subheading">現在の予約:</p>
      </v-flex>
      <v-flex xs6>
        <p>{{ currentControlCodeText }}</p>
      </v-flex>
      <v-flex xs6>
        <p class="subheading">予約の更新:</p>
      </v-flex>
      <v-flex xs6>
        <v-select
          v-model="controlCode"
          :items="controlCodes"
          placeholder="選択"
          label="制御"
          item-text="text"
          item-value="value"
        />
      </v-flex>
      <v-flex xs12>
        <v-btn :disabled="controlLoading" @click="dialog = true">
          更新
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- 更新確認ダイアログ -->
    <v-dialog v-model="dialog" max-width="480">
      <v-card>
        <v-card-title class="subheading">
          <v-flex xs12 class="dialog-title-margin">
            予約の更新を変更します。
          </v-flex>
          <v-flex xs12 class="dialog-title-margin">
            よろしいですか？
          </v-flex>
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialog = false" class="dialog-button-margin" color="grey darken-2" outline>
            いいえ
          </v-btn>
          <v-btn @click="updateControlCode" class="dialog-button-margin" color="primary">
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as types from '~/store/types';
export default {
  data() {
    return {
      // 応答制御用の選択肢
      controlCodes: [
        // 文字列データ型に注意
        { text: '制御なし', value: "0" },
        { text: '再起動', value: "1" },
        { text: '設定初期化', value: "2" }
      ],
      controlCode: null,  // ユーザーが更新で選択した値
      dialog: false,      // ダイアログ表示フラグ
      controlLoading: false,   // 更新中かどうか
    }
  },
  computed: {
    // Vuex の recorders モジュールの recorder.data を参照
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
    // control_code に応じた表示テキストを返す
    currentControlCodeText() {
      const mode = (this.recorder && this.recorder.control_code !== undefined) ? this.recorder.control_code : null;
      const found = this.controlCodes.find(item => item.value === mode);
      return found ? found.text : 'N/A';
    }
  },
  methods: {
    // 予約更新ボタン押下時の処理
    updateControlCode() {
      this.dialog = false;
      if (this.controlCode === null) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '制御モードを選択してください。' });
        return;
      }
      // 応答制御が利用可能かチェック（control_flag が false なら更新を中断）
      if (this.recorder && this.recorder.control_flag === false) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '応答制御対応対象外です。' });
        return;
      }
      this.controlLoading = true;
      const recorderId = this.recorder.id;
      if (!recorderId) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'Recorder ID が不正です。' });
        this.controlLoading = false;
        return;
      }
      // 更新用 payload
      const payload = { control_code: this.controlCode,  plan: this.recorder.plan, device_id: this.recorder.device_id };
      // 更新処理
      this.$axios.put(`${process.env.apiBaseUrl}/recorder-control-code/${recorderId}`, payload, {
        timeout: 300000
      })
      .then((res) => {
        //「現在の予約」値を更新
        this.$store.commit('recorders/setRecorderControl', res.data);
        this.$store.dispatch(types.ACTIVATE_INFO_NOTIFICATION, { message: '制御コードを更新しました。' });
      })
      .catch((err) => {
        let errorMessage = '制御コード設定の更新に失敗しました。';
        if (err.response && err.response.data && err.response.data.message) {
          errorMessage = err.response.data.message;
        }
        if (err.response && err.response.status) {
          errorMessage += ` (コード: ${err.response.status})`;
        }
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: errorMessage });
      })
      .finally(() => {
        // 更新ボタン押した後は、選択値をクリアして初期状態に戻す
        this.controlCode = null;
        this.controlLoading = false;
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
