const initialState = () => ({
    // フィルタ部品があるコンポーネントやフィルタ結果を反映するコンポーネント等、複数コンポーネントで共有する情報のため store に保持
    filters: {
    },
    data: [],
    // ComboBox用の配列
    names: [],
  });
  
  export const state = initialState;
  
  // MEMO: ミューテーションは、ストアの状態を変更する。
  export const mutations = {
    resetState(state) {
      const initial = initialState();
      Object.keys(initial).forEach(key => { state[key] = initial[key] });
    },
    setFilters(state, data) {
      state.filters = _.clone(data);
    },
    setPacketShareGroups(state, data) {
      state.data = _.clone(data);
    },
    setPacketShareGroupNames(state, data){
      const psgNames = _.map(data, psg => psg.name);
      state.names = psgNames;
    }
  };
  
  // MEMO: アクションは、ミューテーションをコミットする。
  // MEMO: アクションは、 store.dispatch がトリガーとなって実行される。
  export const actions = {
    // 一覧画面で「放送グループ」を絞り込むために組織フィルタを変更する。(dispatch from filter.vue)
    filter({ commit }, data) {
      commit('setFilters', data);
    },
    async list({ commit }) {
      const response = await this.$axios.get(process.env.apiBaseUrl + "/packet-share-groups", {timeout: 300000,});
      const items = response.data || [];
      // パケットシェアグループをStoreに格納
      commit('setPacketShareGroups', items);
      // 管理画面のコンボボックス用に名前の配列をStoreに格納
      commit('setPacketShareGroupNames', items);
      return items;
    },
    clearFilters({ commit }){
      commit('setFilters',{});
    },

  };
  
  // MEMO: ゲッターは、ストア内の算出プロパティ
  export const getters = {
    filters: (state) => {
      return state.filters;
    },
    isNoGroup: (state) => {
      return state.data.length === 0;
    }
  };
  