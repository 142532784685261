<template>
  <v-container>
    <p class="title">simカード</p>
    <v-layout
      justify-center
      wrap
      align-space-between
    >
      <v-flex xs6> <p class="subheading">ICCID: </p> </v-flex>
      <v-flex xs6> {{ recorder.device_id }} </v-flex>
      <template v-if="!isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex v-if="hasCustomerAdminPermission" xs6> <p class="subheading">電話番号 : </p> </v-flex>
        <v-flex v-if="hasCustomerAdminPermission" xs6> {{ recorder.phone_no }} </v-flex>
        <v-flex v-if="hasCustomerAdminPermission" xs6> <p class="subheading">IMSI: </p> </v-flex>
        <v-flex v-if="hasCustomerAdminPermission" xs6> {{ info.imsi }} </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
    info() {
      return this.$store.state.recorders.recorder.info;
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
</style>
