import Vue from 'vue';

import * as types from "./types";

export const strict = false;

const initialState = () => ({
  leftDrawerInMap: false,
  rightDrawerInMap: false,
  patternDialog: false,

  notification: {
    activate: false,
    message: '',
    color: '',
  },
  isLoading: false,
  broadcastAction: 'INIT', // INIT, START, STOP, ERROR
  broadcastDuration: 5 * 60,
  broadcastRecorder: {}, // {id, state}, state: INIT, STARTED, STOPPED, ERROR
  broadcastDisabled: false,
  broadcastAbnormalRecorders: [],
  path: '/',
  toaLogoClicked: false,
  ping : false,
  organizationsFilterFlg: false,
});

export const state = initialState;

export const getters = {
  leftDrawerInMap: (state) => {
    return state.leftDrawerInMap;
  },

  rightDrawerInMap: (state) => {
    return state.rightDrawerInMap;
  },

  notification: (state) => {
    return state.notification;
  },

  isActivate: (state) => {
    return state.notification.activate;
  },

  showStreamingInRightDrawer: (state) => {
    return state.showStreamingInRightDrawer;
  },

  isLoading: (state) => {
    return state.isLoading;
  },

  path: (state) => {
    return state.path;
  },

  ping: (state) => {
    return state.ping;
  },

  organizationsFilterFlg(state) {
    return state.organizationsFilterFlg;
  },
};

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setPath(state, newPath) {
    state.path = newPath;
  },
  clickToaLogo(state) {
    state.toaLogoClicked = true;
  },
  showRightDrawerInMap(state) {
    state.rightDrawerInMap = true;
  },
  showLeftDrawerInMap(state) {
    state.leftDrawerInMap = true;
  },
  closeRightDrawerInMap(state) {
    state.rightDrawerInMap = false;
  },
  closeLeftDrawerInMap(state) {
    state.leftDrawerInMap = false;
  },
  showPatternDialog(state) {
    state.patternDialog = true;
  },
  closePatternDialog(state) {
    state.patternDialog = false;
  },

  setNotification(state, data) {
    const cdata = _.clone(data);
    state.notification = {
      activate: cdata.activate,
      message: cdata.message,
      color: cdata.color,
    };
  },

  setLoadingState(state, currentState) {
    state.isLoading = currentState;
  },

  setBroadcastAction(state, data) {
    console.info("state: setBroadcastAction", data);
    if(data === 'START') {
      state.broadcastRecorder = {};
    }
    state.broadcastAction = data;
  },
  setBroadcastRecorderState(state, data) {
    Vue.set(state.broadcastRecorder, data.id, data.state);
  },
  setBroadcastDuration(state, duration) {
    state.broadcastDuration = duration;
  },
  setBroadcastDisabled(state, data) {
    state.broadcastDisabled = data;
  },
  setBroadcastAbnormalRecorder(state, recorder) {
    if(recorder === null) {
      state.broadcastAbnormalRecorders = [];
    } else {
      state.broadcastAbnormalRecorders.push(recorder);
    }
  },

  setPing(state, ping) {
    state.ping = ping;
  },

  setOrganizationsFilterFlg(state) {
    state.organizationsFilterFlg = true;
  },
  removeOrganizationsFilterFlg(state) {
    state.organizationsFilterFlg = false;
  },
};

export const actions = {
  showRightDrawerInMap({ commit }) {
    commit('showRightDrawerInMap');
  },
  showLeftDrawerInMap({ commit }) {
    commit('showLeftDrawerInMap');
  },
  closeRightDrawerInMap({ commit }) {
    commit('closeRightDrawerInMap');
  },
  closeLeftDrawerInMap({ commit }) {
    commit('closeLeftDrawerInMap');
  },
  showPatternDialog({ commit }) {
    commit('showPatternDialog');
  },
  closePatternDialog({ commit }) {
    commit('closePatternDialog');
  },

  // 通知を活性化する
  activateNotification({ commit }, data) {
    commit(types.SET_NOTIFICATION, {activate: true, message: data.message, color: data.color});
  },

  // INFO通知を活性化する
  activateInfoNotification({ commit }, data) {
    commit(types.SET_NOTIFICATION, {activate: true, message: data.message, color: 'success'});
  },

  // ERROR通知を活性化する
  activateErrorNotification({ commit }, data) {
    commit(types.SET_NOTIFICATION, {activate: true, message: data.message, color: 'red'});
  },

  // 通知を非活性化する
  deactivateNotification({ commit }) {
    commit(types.SET_NOTIFICATION, {activate: false, message: '', color: ''});
  },

  setLoadingState({ commit }, currentState) {
    return new Promise((resolve) => {
      commit("setLoadingState", currentState);
      resolve();
    });
  },

  setBroadcastAction({ commit }, data) {
    commit("setBroadcastAction", data);
  },
  setBroadcastRecorderState({ commit }, data) {
    commit("setBroadcastRecorderState", data);
  },
  setBroadcastDuration({ commit }, duration) {
    commit("setBroadcastDuration", duration);
  },
  setBroadcastDisabled({ commit }, data) {
    commit("setBroadcastDisabled", data);
  },
  setBroadcastAbnormalRecorder({ commit }, recorder) {
    commit("setBroadcastAbnormalRecorder", recorder);
  },
  setNotification({ commit }, notification) {
    commit("setNotification", notification);
  },

  startPing({ commit }) {
    commit("setPing", true);
  },

  stopPing({ commit }) {
    commit("setPing", false);
  },

  setOrganizationsFilterFlg({ commit }) {
    commit("setOrganizationsFilterFlg");
  },
  removeOrganizationsFilterFlg({ commit }) {
    commit("removeOrganizationsFilterFlg");
  },
};
