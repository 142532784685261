<template>
  <v-container>
    <p class="title">機器情報</p>
    <v-layout
      justify-center
      wrap
      align-space-between
    >
      <v-flex xs6> <p class="subheading">品番: </p> </v-flex>
      <v-flex xs6> {{ info.part_number }} </v-flex>
      <template v-if="!isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs6><p class="subheading">カメラ品番: </p></v-flex>
        <v-flex xs6>{{ info.cam_type }}</v-flex>
      </template>

      <template v-if="isLTE(recorder.type)">
        <v-flex xs6> <p class="subheading">個体識別番号: </p> </v-flex>
        <v-flex xs6> {{ info.mac_addr }} </v-flex>
        <v-flex v-if="hasCustomerAdminPermission" xs6> <p class="subheading">IMEI: </p> </v-flex>
        <v-flex v-if="hasCustomerAdminPermission" xs6> {{ info.imei }} </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
    info() {
      return this.$store.state.recorders.recorder.info;
    },
  },
  mounted() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
</style>
