import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _1b44fc65 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3fc4548e = () => interopDefault(import('../pages/maps/index.vue' /* webpackChunkName: "pages/maps/index" */))
const _4876d08f = () => interopDefault(import('../pages/notice/index.vue' /* webpackChunkName: "pages/notice/index" */))
const _9dfe888a = () => interopDefault(import('../pages/operation_history/index.vue' /* webpackChunkName: "pages/operation_history/index" */))
const _425db7da = () => interopDefault(import('../pages/patterns/index.vue' /* webpackChunkName: "pages/patterns/index" */))
const _68879b98 = () => interopDefault(import('../pages/recorders/index.vue' /* webpackChunkName: "pages/recorders/index" */))
const _bc09523e = () => interopDefault(import('../pages/streaming/index.vue' /* webpackChunkName: "pages/streaming/index" */))
const _b8cd61c8 = () => interopDefault(import('../pages/view/index.vue' /* webpackChunkName: "pages/view/index" */))
const _3364b3d4 = () => interopDefault(import('../pages/admin/broadcastgroups/index.vue' /* webpackChunkName: "pages/admin/broadcastgroups/index" */))
const _5b2ff6df = () => interopDefault(import('../pages/admin/organizations/index.vue' /* webpackChunkName: "pages/admin/organizations/index" */))
const _3dc6d874 = () => interopDefault(import('../pages/admin/recorders/index.vue' /* webpackChunkName: "pages/admin/recorders/index" */))
const _b67e55d2 = () => interopDefault(import('../pages/admin/simdatamanagementgroups/index.vue' /* webpackChunkName: "pages/admin/simdatamanagementgroups/index" */))
const _79efcd47 = () => interopDefault(import('../pages/admin/users/index.vue' /* webpackChunkName: "pages/admin/users/index" */))
const _23d0e7e2 = () => interopDefault(import('../pages/notice/create.vue' /* webpackChunkName: "pages/notice/create" */))
const _5eb650e4 = () => interopDefault(import('../pages/notice/detail.vue' /* webpackChunkName: "pages/notice/detail" */))
const _311cf646 = () => interopDefault(import('../pages/notice/edit.vue' /* webpackChunkName: "pages/notice/edit" */))
const _60e6116a = () => interopDefault(import('../pages/admin/broadcastgroups/create.vue' /* webpackChunkName: "pages/admin/broadcastgroups/create" */))
const _5cf65382 = () => interopDefault(import('../pages/admin/broadcastgroups/detail.vue' /* webpackChunkName: "pages/admin/broadcastgroups/detail" */))
const _32832fbf = () => interopDefault(import('../pages/admin/organizations/create.vue' /* webpackChunkName: "pages/admin/organizations/create" */))
const _00d2548d = () => interopDefault(import('../pages/admin/organizations/edit.vue' /* webpackChunkName: "pages/admin/organizations/edit" */))
const _79202978 = () => interopDefault(import('../pages/admin/recorders/audio.vue' /* webpackChunkName: "pages/admin/recorders/audio" */))
const _ba6efe6c = () => interopDefault(import('../pages/admin/recorders/create.vue' /* webpackChunkName: "pages/admin/recorders/create" */))
const _7bbe6c58 = () => interopDefault(import('../pages/admin/recorders/edit.vue' /* webpackChunkName: "pages/admin/recorders/edit" */))
const _16fbf347 = () => interopDefault(import('../pages/admin/recorders/editBroadcastSetting.vue' /* webpackChunkName: "pages/admin/recorders/editBroadcastSetting" */))
const _0baf15b0 = () => interopDefault(import('../pages/admin/recorders/editRS485.vue' /* webpackChunkName: "pages/admin/recorders/editRS485" */))
const _5e25a025 = () => interopDefault(import('../pages/admin/recorders/editStreamingCtrl.vue' /* webpackChunkName: "pages/admin/recorders/editStreamingCtrl" */))
const _1b0e1887 = () => interopDefault(import('../pages/admin/simdatamanagementgroups/create.vue' /* webpackChunkName: "pages/admin/simdatamanagementgroups/create" */))
const _e8a64548 = () => interopDefault(import('../pages/admin/simdatamanagementgroups/detail.vue' /* webpackChunkName: "pages/admin/simdatamanagementgroups/detail" */))
const _7f10cf55 = () => interopDefault(import('../pages/admin/simdatamanagementgroups/edit.vue' /* webpackChunkName: "pages/admin/simdatamanagementgroups/edit" */))
const _2883b352 = () => interopDefault(import('../pages/admin/users/create.vue' /* webpackChunkName: "pages/admin/users/create" */))
const _2debddb6 = () => interopDefault(import('../pages/admin/users/edit.vue' /* webpackChunkName: "pages/admin/users/edit" */))
const _519d594e = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

if (process.client) {
  if ('scrollRestoration' in window.history) {
    window.history.scrollRestoration = 'manual'

    // reset scrollRestoration to auto when leaving page, allowing page reload
    // and back-navigation from other pages to use the browser to restore the
    // scrolling position.
    window.addEventListener('beforeunload', () => {
      window.history.scrollRestoration = 'auto'
    })

    // Setting scrollRestoration to manual again when returning to this page.
    window.addEventListener('load', () => {
      window.history.scrollRestoration = 'manual'
    })
  }
}
const scrollBehavior = function (to, from, savedPosition) {
  // if the returned position is falsy or an empty object,
  // will retain current scroll position.
  let position = false

  // if no children detected and scrollToTop is not explicitly disabled
  if (
    to.matched.length < 2 &&
    to.matched.every(r => r.components.default.options.scrollToTop !== false)
  ) {
    // scroll to the top of the page
    position = { x: 0, y: 0 }
  } else if (to.matched.some(r => r.components.default.options.scrollToTop)) {
    // if one of the children has scrollToTop option set to true
    position = { x: 0, y: 0 }
  }

  // savedPosition is only available for popstate navigations (back button)
  if (savedPosition) {
    position = savedPosition
  }

  return new Promise((resolve) => {
    // wait for the out transition to complete (if necessary)
    window.$nuxt.$once('triggerScroll', () => {
      // coords will be used if no selector is provided,
      // or if the selector didn't match any element.
      if (to.hash) {
        let hash = to.hash
        // CSS.escape() is not supported with IE and Edge.
        if (typeof window.CSS !== 'undefined' && typeof window.CSS.escape !== 'undefined') {
          hash = '#' + window.CSS.escape(hash.substr(1))
        }
        try {
          if (document.querySelector(hash)) {
            // scroll to anchor by returning the selector
            position = { selector: hash }
          }
        } catch (e) {
          console.warn('Failed to save scroll position. Please add CSS.escape() polyfill (https://github.com/mathiasbynens/CSS.escape).')
        }
      }
      resolve(position)
    })
  })
}

export function createRouter() {
  return new Router({
    mode: 'hash',
    base: decodeURI('/'),
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/login",
      component: _1b44fc65,
      name: "login"
    }, {
      path: "/maps",
      component: _3fc4548e,
      name: "maps"
    }, {
      path: "/notice",
      component: _4876d08f,
      name: "notice"
    }, {
      path: "/operation_history",
      component: _9dfe888a,
      name: "operation_history"
    }, {
      path: "/patterns",
      component: _425db7da,
      name: "patterns"
    }, {
      path: "/recorders",
      component: _68879b98,
      name: "recorders"
    }, {
      path: "/streaming",
      component: _bc09523e,
      name: "streaming"
    }, {
      path: "/view",
      component: _b8cd61c8,
      name: "view"
    }, {
      path: "/admin/broadcastgroups",
      component: _3364b3d4,
      name: "admin-broadcastgroups"
    }, {
      path: "/admin/organizations",
      component: _5b2ff6df,
      name: "admin-organizations"
    }, {
      path: "/admin/recorders",
      component: _3dc6d874,
      name: "admin-recorders"
    }, {
      path: "/admin/simdatamanagementgroups",
      component: _b67e55d2,
      name: "admin-simdatamanagementgroups"
    }, {
      path: "/admin/users",
      component: _79efcd47,
      name: "admin-users"
    }, {
      path: "/notice/create",
      component: _23d0e7e2,
      name: "notice-create"
    }, {
      path: "/notice/detail",
      component: _5eb650e4,
      name: "notice-detail"
    }, {
      path: "/notice/edit",
      component: _311cf646,
      name: "notice-edit"
    }, {
      path: "/admin/broadcastgroups/create",
      component: _60e6116a,
      name: "admin-broadcastgroups-create"
    }, {
      path: "/admin/broadcastgroups/detail",
      component: _5cf65382,
      name: "admin-broadcastgroups-detail"
    }, {
      path: "/admin/organizations/create",
      component: _32832fbf,
      name: "admin-organizations-create"
    }, {
      path: "/admin/organizations/edit",
      component: _00d2548d,
      name: "admin-organizations-edit"
    }, {
      path: "/admin/recorders/audio",
      component: _79202978,
      name: "admin-recorders-audio"
    }, {
      path: "/admin/recorders/create",
      component: _ba6efe6c,
      name: "admin-recorders-create"
    }, {
      path: "/admin/recorders/edit",
      component: _7bbe6c58,
      name: "admin-recorders-edit"
    }, {
      path: "/admin/recorders/editBroadcastSetting",
      component: _16fbf347,
      name: "admin-recorders-editBroadcastSetting"
    }, {
      path: "/admin/recorders/editRS485",
      component: _0baf15b0,
      name: "admin-recorders-editRS485"
    }, {
      path: "/admin/recorders/editStreamingCtrl",
      component: _5e25a025,
      name: "admin-recorders-editStreamingCtrl"
    }, {
      path: "/admin/simdatamanagementgroups/create",
      component: _1b0e1887,
      name: "admin-simdatamanagementgroups-create"
    }, {
      path: "/admin/simdatamanagementgroups/detail",
      component: _e8a64548,
      name: "admin-simdatamanagementgroups-detail"
    }, {
      path: "/admin/simdatamanagementgroups/edit",
      component: _7f10cf55,
      name: "admin-simdatamanagementgroups-edit"
    }, {
      path: "/admin/users/create",
      component: _2883b352,
      name: "admin-users-create"
    }, {
      path: "/admin/users/edit",
      component: _2debddb6,
      name: "admin-users-edit"
    }, {
      path: "/",
      component: _519d594e,
      name: "index"
    }],

    fallback: false
  })
}
