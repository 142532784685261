<template>
  <div class="jpeg-container">
    <v-responsive :aspect-ratio="16/9">
      <div :style="overlayStyles">
        <img
          v-if="!isPaused && !jpegPreparing"
          :style="playerStyles"
          class="image -fit-to-container"
          :src="src"
        />
      </div>
    </v-responsive>
    <div class="btn-container mt-1">
      <v-btn
        v-if="isPaused"
        icon
        @click="startJpeg"
      >
        <v-icon>play_arrow</v-icon>
      </v-btn>
      <v-btn
        v-else
        icon
        @click="stopJpeg"
      >
        <v-icon>stop</v-icon>
      </v-btn>
      <v-btn
        v-if="isPermittedMaximize"
        @click="maximizePlayerSize"
      >
        ライブ画面へ
      </v-btn>
    </div>
  </div>
</template>

<script>
import { persistence } from '@/plugins/persistence';
import deviceStatusMixin from '@/components/mixins/deviceStatus';
import * as types from '~/store/types';

export default {
  props: ['channel', 'recorder', 'height'],
  mixins: [ deviceStatusMixin ],
  data() {
    return {
      src: null,
      isPaused: true, // プレイヤーが停止中
      jpegPreparing: false, // プレイヤーが（配信情報の取得などで）再生待ち
      mode: 'youtube',
      startTime: null,
      timerId: null,
      delay: 0/* ms */,
      playerStyles: {
        'z-index': -1,
      },
      unsubscribe: null,
    }
  },
  computed: {
    overlayStyles () {
      const thumbnailObj = {
        background: `url(${process.env.thumbnailUrl})`,
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
        width: '100%',
        height: '100%',
        position: 'relative',
      };

      return thumbnailObj;
    },
    isPermittedMaximize() {
      return this.$route.path === '/maps' && this.$vuetify.breakpoint.mdAndUp;
    },
    isMap() {
      return this.$route.path === '/maps';
    },
  },
  async beforeDestroy() {
    this.playerStyles['z-index'] = -1;
    await this.stopJpeg();
  },
  mounted() {
    this.isPaused = true;
    this.jpegPreparing = false;
    console.log("jpeg",this.recorder);

    this.unsubscribe = this.$store.subscribe(async (mutation) => {
      if (mutation.type === 'closeRightDrawerInMap' ||
        mutation.type === 'map/selectRecorder' ||
        mutation.type === 'view/changeRecorder') {
        this.isPaused = true;
        this.jpegPreparing = false;
        clearTimeout(this.timerId);

        try {
          await this.changeRecState(this.recorder.id, false);
        } catch (err) {
          console.error(err);
        }
      }
    });
  },
  methods: {
    async unlockLowLatencyLive() {
      try {
        await this.changeRecState(this.recorder.id, false);
      } catch (err) {
        console.log('error!');
        console.error(err);
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '排他制御に失敗しました。' });
        this.isPaused = true;
      }
    },
    async startJpeg() {
      this.isPaused = false;
      this.jpegPreparing = true;
      this.$store.commit('recorders/setRecorder', this.recorder);
      this.$store.dispatch('recorders/clearRecorder');
      await this.$store.dispatch('recorders/get', this.recorder.id);

      // メール通知プランであるレコーダーの場合は実行不可
      if (this.isMailNotificationPlan(this.recorder.plan)) {
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'メール通知プランの場合は再生できません。' });
        this.isPaused = true;
        this.jpegPreparing = false;
        return;
      }

      // 映像配信権限が無い場合はYoutube再生および配信制御をさせない
      // 選択されたレコーダーの組織IDでログインユーザーのレコーダー権限を判定
      if (!this.hasLowLatencyMode(this.recorder.organization_id)) {
        console.log('低遅延モードライブ配信権限無し');
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '低遅延モードライブ配信権限が付与されていません。' });
        this.isPaused = true;
        this.jpegPreparing = false;
        return;
      }

      try {
        const isAlreadyPlayed = await this.getRecStatus(this.recorder.id);
        if (isAlreadyPlayed) {
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '他ユーザーまたは他PCが利用しているため、ライブ開始できません。' });
          this.isPaused = true;
          this.jpegPreparing = false;
          return;
        }
      } catch (err) {
        console.error(err);
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '排他制御に失敗しました。' });
        this.unlockLowLatencyLive();
        this.isPaused = true;
        this.jpegPreparing = false;
        return;
      }

      try {
        await this.changeRecState(this.recorder.id, true);
      } catch (err) {
        console.error(err);
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '排他制御に失敗しました。' });
        this.isPaused = true;
        this.jpegPreparing = false;
        this.unlockLowLatencyLive();
        return;
      }

      if (this.isIE()) {
        // 脆弱性対応#1643:deviceId > recorderIdに修正のため
        // localStorage.setItem('currentRecorderDeviceId', this.recorder.device_id);
        localStorage.setItem('currentRecorderId', this.recorder.id)
        localStorage.setItem('currentUserId', this.$store.getters['auth/userInfo'].id);
      }

      const data = "act=stop&";
      const payload = {
        recorderID: this.recorder.id,
        virtualIP: this.recorder.vpn.virtualip,
        method: 'POST',
        url: '/streaming_ctrl',
        data: data,
      };
      await this.$axios.post(process.env.cgiBaseUrl, payload, {
        timeout: 300000,
      }).catch(errorCode => {
        console.log(errorCode);
        console.log("CGI 映像配信制御 停止 [失敗]");
        this.isPaused = true;
        this.jpegPreparing = false;
        this.unlockLowLatencyLive();
        if(errorCode.response.status == 403) {
          return;
        }
      });

      this.startTime = new Date();

      let changeCameraSettingsResponse;
      try {
        const payload = 'GET\\r\\n/configwrite.cgi?venc_3_height=360&venc_3_tracking_rate=2&venc_3_image_framerate=5&';
        changeCameraSettingsResponse = await this.$axios.post(process.env.cgiBaseUrl, {
          recorderID: this.recorder.id,
          method: 'POST',
          url: '/forwarding.cgi',
          data: payload,
        }, {
          timeout: 300000,
          headers: {},
        });
      } catch (err) {
        console.error(err);
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'CGIに失敗しました。' });
        this.isPaused = true;
        this.unlockLowLatencyLive();
        return;
      }
      this.startJpegPolling();
      this.playerStyles['z-index'] = 2;
    },
    startJpegPolling() {
      this.timerId = setTimeout(this.request, this.delay);
    },
    /**
     * 前の/cgiへのPOSTリクエストが終わってから0.1秒後に次のリクエストを行う。
     * UIから停止ボタンを押されるか、5分経過するまでリクエストが繰り返される。
     */
    async request() {
      const now = new Date();
      if (now - this.startTime > 5 /* min */ * 60 /* sec */ * 1000 /* ms */) {
        this.isPaused = true;
        clearTimeout(this.timerId);

        try {
          await this.changeRecState(this.recorder.id, false);
        } catch (err) {
          console.error(err);
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '排他制御に失敗しました。' });
          this.isPaused = true;
          this.unlockLowLatencyLive();
          return;
        }
        return;
      }

      if (this.isPaused) {
        clearTimeout(this.timerId);

        try {
          await this.changeRecState(this.recorder.id, false);
        } catch (err) {
          console.error(err);
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '排他制御に失敗しました。' });
          this.isPaused = true;
          return;
        }

        return;
      }

      try {
        const data = 'GET\\r\\n/getjpeg.cgi?';
        const cgiResponse = await this.$axios.post(process.env.cgiBaseUrl, {
          recorderID: this.recorder.id,
          method: 'POST',
          url: '/forwarding.cgi',
          data: data,
        }, {
          timeout: 300000,
          headers: {},
        });

        if (cgiResponse && cgiResponse.data) {
          this.src = cgiResponse.data;
          this.jpegPreparing = false;
        }
      } catch (err) {
        console.error(err);
      } finally {
        clearTimeout(this.timerId);
        this.timerId = setTimeout(this.request, this.delay);
      }
    },
    async stopJpeg() {
      this.playerStyles['z-index'] = -1;

      clearTimeout(this.timerId);
      this.timerId = null;
      this.src = null;

      if (this.isPaused) {
        return;
      }

      try {
        await this.changeRecState(this.recorder.id, false);
      } catch (err) {
        console.error(err);
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '排他制御に失敗しました。' });
        return;
      } finally {
        this.isPaused = true;
        this.jpegPreparing = false;
      }
    },
    maximizePlayerSize() {
      this.$store.dispatch('view/setRecorders', { type: 'recorder', recorders: [this.recorder] });
      const recorders = this.$store.getters['view/recorders'];

      if (this.isIE()) {
        persistence.persistentState('view/recorders', recorders);
      }

      this.$customRouter.push('/view');
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "../../assets/style/player";

  .jpeg-container {
    .image {
      position: absolute;
      &.-fit-to-container {
        height: 100%;
        width: 100%;
      }
    }
  }
</style>
