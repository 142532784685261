





























































import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
import { MapState } from 'model/MapState'

@Component
export default class LoginPositionButton extends Vue {
  @Prop({ type: Object, required: true }) mapState!: MapState
  positionSetDialog = false
  snackbar = false
  snackBarColor = 'success'

  @Emit('position_submitted')
  positionSubmit(): void {
    this.positionSetDialog = true;
  }

  async changeMapDefaultPosition(): Promise<void> {
    await this.$store.dispatch('map/updateLoginPosition', this.mapState).catch(() => {
        this.positionSetDialog = false;
    })
    this.snackbar = true
    this.positionSetDialog = false
  }
}
