<template>
  <v-container>
    <p class="title">契約プラン</p>
    <v-layout justify-center wrap align-space-between>
      <v-flex xs6>
        <p class="subheading">サービスプラン:</p>
      </v-flex>
      <v-flex xs6>
        <p>{{ planMap.get(recorder.plan) }}</p>
      </v-flex>
      <template v-if="isLTE(recorder.type) && (!isLTEMailNotificationPlan(recorder.plan) || isPlanWide(recorder.plan))">
        <v-flex xs12>
          <DataPercentageBar
            :usage="sim.sim_traffic_sum"
            :capacity="sim.sim_capacity_total"
            disable-inline-text
          />
        </v-flex>
        <v-flex xs6>
          <p class="subheading">SIMデータ利用量:</p>
        </v-flex>
        <v-flex xs6 d-flex align-center>
          <p>{{ sim.sim_traffic_sum }} GB</p>
        </v-flex>
        <v-flex xs6>
          <p class="subheading">SIMデータ容量:</p>
        </v-flex>
        <v-flex xs6 d-flex align-center>
          <p>
            {{ sim.sim_capacity_total }} GB
            <span v-if="sim.psg_name != ''">（シェア）</span>
          </p>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import DataPercentageBar from "~/components/DataPercentageBar.vue";

export default {
  components: {
    DataPercentageBar
  },
  props: {
    recorder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      planMap: null
    };
  },
  computed: {
    sim() {
      return this.$store.state.recorders.recorder.sim;
    }
  },
  watch: {
    recorder: {
      immediate: true,
      handler() {
        if (this.isSigfox(this.recorder.type)) {
          return;
        }

        if (!this.recorder.id) {
          return;
        }
        // LTEの場合のみSIM通信量を取得
        this.$store.dispatch("recorders/getSimTraffic", this.recorder.id);
      }
    }
  },
  mounted() {
    if (this.isSigfox(this.recorder.type)) {
      return;
    }
    // LTEの場合のみSIM通信量を取得
    this.$store.dispatch("recorders/getSimTraffic", this.recorder.id);
  },
  created() {
    // レコーダープランIDとプラン名のマップ
    this.planMap = new Map([
      ["10", "メール通知プラン"],
      ["11", "メール通知プランLTE"],
      ["12", "メール通知プラン(G-R301)"],
      ["13", "ダウンロードプランワイド"],
      ["21", "拡声ライブプラン"],
      ["22", "拡声ライブプラン Light"],
      ["31", "拡声ライブプラン DL"],
      ["32", "拡声ライブプラン DL Light"],
      ["33", "拡声ライブプランDLワイド"]
    ]);
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.data_value {
  display: flex;
  align-items: center;
}
</style>
