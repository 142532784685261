/**
 * 放送状態取得
 * @param recorderId
 * @param axios
 * @returns -1: 取得エラー
 *           0 : 停止中
 *           1 : ファイル再生中
 *           2 : 音声放送中
 */
export const getAudioPlaybackStatus = async (recorderId, axios) => {
  let status = -1;
  try {
    const resp = await axios.post(process.env.cgiBaseUrl, {
      recorderID: recorderId,
      method: 'GET',
      url: '/audiofile_ctrl.cgi',
      data: "get=status",
    }, {
      timeout: 300000,
    });
    status = resp.data;
  } catch (e) {
    console.log(e);
  }
  return status;
}

/**
 * プログラム再生トリガ状態取得
 * @param recorderId
 * @param axios
 * @returns -1: 取得エラー
 *           0 : off
 *           1 : on
 */
export const getAudioFilePlayTriggerRepeat = async (recorderId, axios) => {
  let status = -1;
  try {
    const cmd = "audio_file_play_trigger_repeat=";
    const resp = await axios.post(process.env.cgiBaseUrl, {
      recorderID: recorderId,
      method: 'GET',
      url: '/configread.cgi',
      data: cmd,
    }, {
      timeout: 300000,
    });
    if (resp.data.substring(cmd.length) === "on") {
      status = 1;
    } else {
      status = 0;
    }
  } catch (e) {
    console.log(e);
  }
  return status;
}

/**
 * プログラム再生トリガ状態設定
 * @param recorderId
 * @param status 0 : off / 1 : on
 * @param axios
 * @returns true : 成功
 *          false: 失敗
 */
export const setAudioFilePlayTriggerRepeat = async (recorderId, status, axios) => {
  let result = false;
  try {
    let cmd = "audio_file_play_trigger_repeat=";
    if(status === 1) {
      cmd = cmd + "on";
    } else {
      cmd = cmd + "off";
    }
    await axios.post(process.env.cgiBaseUrl, {
      recorderID: recorderId,
      method: 'GET',
      url: '/configwrite.cgi',
      data: cmd,
    }, {
      timeout: 300000,
    });
    result = true;
  } catch (e) {
    console.log(e);
  }
  return result;
}

/**
 * 時刻指定再生トリガ状態取得
 * @param recorderId
 * @param axios
 * @returns -1: 取得エラー
 *           0 : off
 *           1 : on
 */
export const getAudioFilePlayTriggerTime = async (recorderId, axios) => {
  let status = -1;
  try {
    const cmd = "audio_file_play_trigger_time=";
    const resp = await axios.post(process.env.cgiBaseUrl, {
      recorderID: recorderId,
      method: 'GET',
      url: '/configread.cgi',
      data: cmd,
    }, {
      timeout: 300000,
    });
    if (resp.data.substring(cmd.length) === "on") {
      status = 1;
    } else {
      status = 0;
    }
  } catch (e) {
    console.log(e);
  }
  return status;
}

/**
 * 時刻指定再生トリガ状態設定
 * @param recorderId
 * @param status 0 : off / 1 : on
 * @param axios
 * @returns true : 成功
 *          false: 失敗
 */
export const setAudioFilePlayTriggerTime = async (recorderId, status, axios) => {
  let result = false;
  try {
    let cmd = "audio_file_play_trigger_time=";
    if(status === 1) {
      cmd = cmd + "on";
    } else {
      cmd = cmd + "off";
    }
    await axios.post(process.env.cgiBaseUrl, {
      recorderID: recorderId,
      method: 'GET',
      url: '/configwrite.cgi',
      data: cmd,
    }, {
      timeout: 300000,
    });
    result = true;
  } catch (e) {
    console.log(e);
  }
  return result;
}
