<template>
  <v-container>
    <p class="title">音声ファイル再生</p>
    <v-layout
      justify-center
      wrap
      align-space-between
    >
      <v-flex xs10></v-flex>
      <v-flex xs2>
        <v-btn :disabled="!hasBroadcastPermission || buttonDisabled" @click="confirmStatus">状態確認</v-btn>
      </v-flex>
    </v-layout>
    <v-layout
      justify-center
      wrap
      align-space-between
    >
      <v-flex xs10>
        <v-select
          :disabled="!hasBroadcastPermission"
          :items="items"
          v-model="selected"
          class="break-word"
          item-text="title"
          item-value="fileName"
          style="flex-grow: 2"
        />
      </v-flex>
      <v-flex xs2>
        <v-btn :disabled="!hasBroadcastPermission || buttonDisabled" @click="confirmPlayAudio">再生</v-btn>
        <v-btn :disabled="!hasBroadcastPermission || buttonDisabled" @click="confirmStopAudio">停止</v-btn>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="confirm"
      max-width="560"
    >
      <v-card>
        <v-card-text>
          <p class="subheading mb-4">{{ confirmMessage }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="confirm=false"
            color="grey darken-2"
            outline
          >
            いいえ
          </v-btn>
          <v-btn @click="confirmAction" class="primary">
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="info"
      persistent
      max-width="560"
    >
      <v-card>
        <v-card-text>
          <p class="subheading mb-4">{{ infoMessage }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            @click="info=false"
            class="primary"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import * as types from '~/store/types';
  import { getAudioPlaybackStatus } from "~/utils/cgi_utils";

  export default {
    name: 'RecorderAudioPlay',
    props:{
      recorder : {
        type : Object,
        required : true
      }
    },
    data() {
      return {
        selected : '',

        confirm : false,
        confirmMessage : '',
        confirmAction : function (){console.log("nothing to do.")},

        info : false,
        infoMessage : '',

        unsub : null,
      }
    },
    computed : {
      buttonDisabled() {
        return this.selected.length === 0
      },

      hasBroadcastPermission() {
        return this.hasBroadcastOperation(this.recorder.organization_id);
      },

      items: {
        get() {
          const items = this.$store.getters['available_audio/items'];
          if(items) {
            return items.sort(function (a, b){
              return a.title.localeCompare(b.title);
            });
          } else {
            return [];
          }
        }
      },
    },
    watch : {
      recorder(nv, ov) {
        if(nv.id === ov.id) return;
        this.$store.dispatch('available_audio/loadItems', this.recorder.id);
      }
    },
    mounted() {
      this.$store.dispatch('available_audio/loadItems', this.recorder.id);

      this.unsub = this.$store.subscribe((mutation) => {
        if(mutation.type === 'available_audio/setItems'){
          this.initSelected();
        }});
    },
    beforeDestroy() {
      this.unsub();
    },
    methods : {
      getAudioPlaybackStatus,
      initSelected() {
        this.selected = this.items ? 1 <= this.items.length ? this.items[0].fileName : '' : '';
      },
      confirmPlayAudio() {
        this.confirmMessage = "音声再生してもよろしいですか？";
        this.confirmAction = this.playAudio;
        this.confirm = true;
      },
      confirmStopAudio() {
        this.confirmMessage = "音声再生を停止してもよろしいですか？";
        this.confirmAction = this.stopAudio;
        this.confirm = true;
      },
      async confirmStatus() {
        // メッセージ
        const msg_err = 'レコーダーの音声再生状態取得に失敗しました。';
        const msg_007 = '現在は何も放送しておりません。音声再生が可能です。';
        const msg_008 = '既に音声再生中です。新しく音声再生は実施できません。しばらくお待ちいただくか、音声ファイル再生を停止させてください。';
        const msg_009 = '遠隔放送中です。音声再生は実施できません。しばらくお待ちいただいたのち、再度ご確認ください。';
        // 再生状況を確認する
        await this.$store.dispatch('setLoadingState', true);
        const status = await this.getAudioPlaybackStatus(this.recorder.id, this.$axios)
        await this.$store.dispatch('setLoadingState', false);
        // 停止中
        if (status === 0){
          this.infoMessage = msg_007;
          this.info = true;
          return;
        }
        // 音声再生中
        if (status === 1){
          this.infoMessage = msg_008;
          this.info = true;
          return;
        }
        // 遠隔放送中
        if(status === 2) {
          this.infoMessage = msg_009;
          this.info = true;
          return;
        }
        // その他以上の場合
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: msg_err });
        return;
      },

      async playAudio(){
        this.confirm = false;

        // 再生状況を確認する
        await this.$store.dispatch('setLoadingState', true);
        const status = await this.getAudioPlaybackStatus(this.recorder.id, this.$axios)
        await this.$store.dispatch('setLoadingState', false);

        // 再生できない状態の場合はそのメッセージを表示する
        // 音声再生中
        if (status === 1){
          this.infoMessage = '既に音声再生中です。新しく音声再生は実施できません。しばらくお待ちいただくか、音声ファイル再生を停止させてください。';
          this.info = true;
          return;
        }
        // 遠隔放送中
        if(status === 2) {
          this.infoMessage = '遠隔放送中のため、音声再生できません。しばらくお待ち下さい。';
          this.info = true;
          return;
        }
        // その他以上の場合
        if(status !== 0) {
          await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'レコーダーの音声再生状態取得に失敗しました。' });
          return;
        }

        // 停止中であるので音声再生を実施する
        try {
          await this.$axios.post(process.env.cgiBaseUrl, {
            recorderID: this.recorder.id,
            method: 'GET',
            url: '/audiofile_ctrl.cgi',
            data: "act=start&filename=" + encodeURIComponent(this.selected),
          }, {
            timeout: 300000,
          });
        } catch (e) {
          console.info(e);
          if(e.response){
            console.info(e.response);
            if (e.response.status === 501) {
              await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '指定ファイルが存在しません。管理者にお問い合わせください。' });
            } else if (e.response.status === 503){
              await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '音声再生中のため、音声再生に失敗しました。' });
            } else {
              await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '音声再生に失敗しました。' });
            }
          } else {
            await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '音声再生に失敗しました。' });
          }

        }
      },

      async stopAudio(){
        this.confirm = false;
        try {
          await this.$axios.post(process.env.cgiBaseUrl, {
            recorderID: this.recorder.id,
            method: 'GET',
            url: '/audiofile_ctrl.cgi',
            data: "act=stop",
          }, {
            timeout: 300000,
          });
        } catch (e) {
          console.error(e);
          await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '音声停止に失敗しました。' });
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .break-word {
    word-break: break-all;
  }
</style>
