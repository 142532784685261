// メール通知プラン
export const MAIL_NOTIFICATION = "10";
// メール通知プランLTE
export const LTE_MAIL_NOTIFICATION = "11";
// メール通知プラン(G-R301)
export const LTE_MAIL_NOTIFICATION_GR301 = "12";
// ダウンロードプランワイド
export const LTE_MAIL_NOTIFICATION_DL_WIDE = "13";

// 拡声ライブプラン
export const LOUD_LIVE = "21";
// 拡声ライブプラン Light
export const LOUD_LIVE_LIGHT = "22";
// 拡声ライブプラン DL
export const LOUD_LIVE_DL = "31";
// 拡声ライブプラン DL Light
export const LOUD_LIVE_DL_LIGHT = "32";
// 拡声ライブプランDLワイド
export const LOUD_LIVE_DL_WIDE = "33";

const PLAN_BASE = [
  { text: "拡声ライブプラン",          value: LOUD_LIVE },
  { text: "拡声ライブプラン Light",    value: LOUD_LIVE_LIGHT },
  { text: "拡声ライブプラン DL",       value: LOUD_LIVE_DL },
  { text: "拡声ライブプラン DL Light", value: LOUD_LIVE_DL_LIGHT },
  { text: "拡声ライブプランDLワイド",   value: LOUD_LIVE_DL_WIDE },
];

export const LTE_PLAN_LIST = [
  { text: "メール通知プランLTE",       value: LTE_MAIL_NOTIFICATION },
  { text: "メール通知プラン(G-R301)",  value: LTE_MAIL_NOTIFICATION_GR301 },
  { text: "ダウンロードプランワイド",   value: LTE_MAIL_NOTIFICATION_DL_WIDE },
  ...PLAN_BASE,
];

export const SIGFOX_PLAN_LIST = [
  { text: "メール通知プラン", value: MAIL_NOTIFICATION },
  ...PLAN_BASE,
];

export const LIVE_PLAN_LIST = [
  { text: "メール通知プラン", value: MAIL_NOTIFICATION },
  ...PLAN_BASE,
];

//メモ：プラン変更の場合、components/admin/recorders/streaming_ctrl.vue にある「配信時間」「画像サイズ」の設定の修正も必要
