<template>
  <div>
    <div class="left-drawer-container">
      <p class="subheading org">
        <v-icon
          v-if="existsInvalidStateRecorders"
          color="amber"
        >
          warning
        </v-icon>
        組織
      </p>
      <v-icon
        class="arrow-back-ios"
        @click="closeLeftDrawerInMap()">arrow_back_ios</v-icon>
      <v-icon
        class="arrow-back-ios-second"
        @click="closeLeftDrawerInMap()">arrow_back_ios</v-icon>
    </div>
    <ViewLeftDrawerInMap />
  </div>
</template>

<script>
import ViewLeftDrawerInMap from '~/components/maps/leftDrawerInMap.vue';
import leftDrawerMixin from '@/components/mixins/leftDrawer';

export default {
  components: {
    ViewLeftDrawerInMap,
  },
  mixins: [ leftDrawerMixin ],
  data() {
    return {
    }
  },
  computed: {
    existsInvalidStateRecorders() {
      const recorders = this.$store.getters['map/permittedRecorders'];
      if (!recorders) return false;
      const deviceStatuses = recorders.map((recorder) => recorder.device_status);
      return this.existsInvalidStatus(deviceStatuses);
    }
  },
  mounted() {
  },
  methods: {
    closeLeftDrawerInMap() {
      this.$store.dispatch('closeLeftDrawerInMap');
    },
  }
}
</script>
<style lang="scss" scoped>
  @import "../assets/style/treeview";
</style>
