export default {
  data() {
    return {
      //機器異常確認用
      errorMap: {
        operation_status: { 0: "通信異常", 1: "", 9: "情報取得中" },
        rec_sd_status: { 0: "", 1: "", 2: "SDカード録画異常" },
        sd_status: { 0: "SDカード異常", 1: "", 2: "" },
        rec_ssd_status: { 0: "SSD録画異常", 1: "" },
        ssd_status: { 0: "SSD異常", 1: "" },
        wlan_status: { 0: "無線LAN異常", 1: "" },
        temp_status: {
          0: "オーバーヒート（ファン異常）",
          1: "オーバーヒート",
          2: "",
          3: ""
        },
        lte_signal_strength: { 0: "", 1: "", 2: "", 3: "", 4: "", 5: "" },
        cam_mcu_status: { 0: "カメラマイコン異常", 1: "" },
        sub_mcu_status: { 0: "サブマイコン異常", 1: "" },
        auxin_1: { on: "オン", off: "" },
        auxin_2: { on: "オン", off: "" },
        auxin_3: { on: "オン", off: "" },
        cam_status: { 0: "カメラ未接続", 1: "", 2: "カメラ異常" },
        // box_state: { 0: "", 1: "", },
        auxout_1: { on: "", off: "" },
        auxout_2: { on: "", off: "" },
        auxout_3: { on: "", off: "" },
        fan_status: { 0: "", 1: "" }
      },
      device_errors: ""
    };
  },
  computed: {
    existsInvalidStateRecorders() {
      if (!this.recorders) return false;
      const allOrgRecorders = this.recorders.map(recorder => recorder.children);
      const deviceStatuses = [];
      for (const recordersBelongOrg of allOrgRecorders) {
        for (const recorderBelongOrg of recordersBelongOrg) {
          deviceStatuses.push(recorderBelongOrg.device_status);
        }
      }
      return this.existsInvalidStatus(deviceStatuses);
    }
  },
  methods: {
    existsInvalidStatus(deviceStatuses) {
      for (const deviceStatus of deviceStatuses) {
        for (const key in deviceStatus) {
          switch (key) {
            case "rec_ssd_status":
              // SSD録画状態は「0:異常」「1:正常」
              if (deviceStatus.rec_ssd_status === "0") {
                return true;
              }
              break;
            case "rec_sd_status":
              // SDカード録画状態は「0:録画停止」「1:正常」「2:異常」
              if (deviceStatus.rec_sd_status === "2") {
                return true;
              }
              break;
            case "ssd_status":
              // SSD状態は「0:異常」「1:正常」
              if (deviceStatus.ssd_status === "0") {
                return true;
              }
              break;
            case "sd_status":
              // SD状態は「0:異常」「1:正常」「2:SDカードなし」
              if (deviceStatus.sd_status === "0") {
                return true;
              }
              break;
            case "wlan_status":
              // 無線LAN状態は「0:異常」「1:正常」
              if (deviceStatus.wlan_status === "0") {
                return true;
              }
              break;
            case "temp_status":
              // 機器内温度状態は「0:オーバーヒート(ファン異常)」「1:オーバーヒート」「2:正常(ファン異常)」「3:正常」
              if (
                deviceStatus.temp_status === "0" ||
                deviceStatus.temp_status === "1"
              ) {
                return true;
              }
              break;
            case "cam_status":
              if (
                deviceStatus.cam_status === "0" ||
                deviceStatus.cam_status === "2"
              ) {
                return true;
              }
              break;
            case "cam_mcu_status":
              // 0が異常
              if (deviceStatus.cam_mcu_status === "0") {
                return true;
              }
              break;
            case "sub_mcu_status":
              // 0が異常
              if (deviceStatus.sub_mcu_status === "0") {
                return true;
              }
              break;
            case "auxin_1":
              if (deviceStatus.auxin_1 === "on") {
                return true;
              }
              break;
            case "auxin_2":
              if (deviceStatus.auxin_2 === "on") {
                return true;
              }
              break;
            case "auxin_3":
              if (deviceStatus.auxin_3 === "on") {
                return true;
              }
              break;
            case "operation_status":
              if (deviceStatus.operation_status === "0") {
                return true;
              }
          }
        }
      }
      return false;
    },
    getDeviceStatusesFromRecorders(recorders) {
      if (!recorders) return [];
      return recorders.map(recorder => recorder.device_status);
    },
    setDeviceErrors(item) {
      if (!item.device_status) {
        this.device_errors = "";
        return;
      }

      const errors = _.chain(this.errorMap)
        .map((patterns, cate) => {
          const targetStatus = item.device_status[cate];
          const errorMsg = patterns[targetStatus];
          if (!errorMsg) return;

          if (cate.startsWith("aux")) {
            const auxCate = cate
              .slice(3)
              .split("_")
              .join("");
            return item.aux[auxCate] + errorMsg;
          }
          return errorMsg;
        })
        .compact()
        .join(",")
        .value();

      this.device_errors = errors;
    }
  }
};
