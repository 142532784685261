<template>
  <div>
    <v-toolbar class="toolbar elevation-0">
      <v-toolbar-title>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on">レコーダー: {{ recorder.name }}</span>
          </template>
          <span>レコーダー: {{ recorder.name }}</span>
        </v-tooltip>
        <div
          class="close-btn"
          @click="closeRightDrawerInMap">
          <v-icon>close</v-icon>
        </div>
      </v-toolbar-title>
    </v-toolbar>
    <template v-if="this.isLTE(this.recorder.type) && !this.isLTEMailNotificationPlan(this.recorder.plan)">
      <Player
        :channel="channelID"
        :height="videoSize.height"
        :recorder="recorder"
      />
    </template>
    <RecordTabs :type="recorder.type" :plan="recorder.plan" :p_map="true"/>
  </div>
</template>

<script>
  import Player from '~/components/player/main.vue';
  import RecordTabs from '~/components/recordTabs.vue';

  export default {
    components: {
      Player,
      RecordTabs,
    },
    data() {
      return {
        videoSize: {
          width: "390",
          height: "240",
        },
        playerVarsMobile: {
          mute: 1,
        },
        unsubscribe: null,
        touchless: false,
      }
    },
    computed: {
      recorder() {
        if (this.$store.state.map) {
          const recorder = this.$store.state.map.selectedRecorder;
          return recorder;
        }
      },
      channelID() {
        if (this.recorder.youtube) {
          // 公開
          if (this.recorder.youtube.open_status && this.recorder.youtube.open_status === '1') {
            return this.recorder.youtube.public.channel;
          // 非公開
          } else {
            return this.recorder.youtube.private.channel;
          }
        }
      },
      player() {
        if (this.$refs.youtube) {
          return this.$refs.youtube.player;
        }
      },
    },
    methods: {
      closeRightDrawerInMap() {
        this.$store.dispatch('closeRightDrawerInMap');
      },
    },
  }
</script>
<style lang="scss" scoped>
  $toolbar-height: 8vh;

  .close-btn {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    top: 0.8em;
    right: 0.8em;
  }
  .toolbar {
    height: $toolbar-height;
  }
</style>
