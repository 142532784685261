import { render, staticRenderFns } from "./cameraSetting.vue?vue&type=template&id=2d0186e4&scoped=true&v-if=hasCustomerAdminPermission&"
import script from "./cameraSetting.vue?vue&type=script&lang=js&"
export * from "./cameraSetting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d0186e4",
  null
  
)

export default component.exports