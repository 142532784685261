import { DownloadAudioRequest } from '~/model/DownloadAudioRequest';
import { AvailableAudio } from '~/model/AvailableAudio';
const storeID = "download_audio_request/";
const initialState = () => ({
  items : []
});

export const state = initialState;

export const getters = {
  items: (state) => {
    return state.items;
  }
};

export const actions = {
  async loadItems({commit}, recorderId){
    const apiResponse = await this.$axios.get(process.env.apiBaseUrl + '/recorders/' + recorderId + '/download-audio-requests', {
      timeout: 300000,
    });

    let items = [];
    for (let i = 0; i < apiResponse.data.requests.length; i++) {
      const dar = apiResponse.data.requests[i];
      items.push(new DownloadAudioRequest(dar.recorder_id, dar.file_name, dar.download_url, dar.title, dar.size, dar.duration));
    }
    commit( 'setItems', items);
  },

};

export const mutations = {
  setItems(state, items) {
    console.log(items);
    state.items = items;
  },

};
