import firebase from 'firebase/app';
import 'firebase/auth';
import {persistence} from "./persistence";

export default function (context) {
  if (firebase.apps.length) {
    console.log('exists firebase.apps');
    return;
  }

  firebase.initializeApp(
    {
      apiKey: process.env.apiKey,
      authDomain: process.env.authDomain,
      databaseURL: process.env.databaseURL,
      projectId: process.env.projectId,
      storageBucket: process.env.storageBucket,
      messagingSenderId: process.env.messagingSenderId
    }
  );

  // 明示的なログアウトまではログイン状態を維持するモードとする
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL).catch(error => {
    console.log("errorCode:"+error.code+", errorMessage:"+error.message);
  });
}
