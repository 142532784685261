<template>
  <v-switch
    v-model="locked"
    :label="label"
    class="ma-0"
    color="primary"
  />
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '位置ロック',
      required: false,
    },
    recorder: {
      type: Object,
      default: null,
    }
  },
  computed: {
    locked: {
      cache: false,
      get() {
        const lockStateMap = this.$store.getters['map/recorderIdAndLockStateMap'];
        if (lockStateMap && this.recorder) {
          return lockStateMap[this.recorder.id];
        }
        return false;
      },
      set(newLockState) {
        this.$emit('lockStateChanged', newLockState);
      },
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
