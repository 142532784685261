// getters-types

export const NOTIFICATION = "notification";

export const IS_ACTIVATE = "isActivate";

// mutations-types

export const SET_NOTIFICATION = "setNotification";

// actions-types

export const ACTIVATE_NOTIFICATION = "activateNotification";
export const ACTIVATE_INFO_NOTIFICATION = "activateInfoNotification";
export const ACTIVATE_ERROR_NOTIFICATION = "activateErrorNotification";
export const DEACTIVATE_NOTIFICATION = "deactivateNotification";

export const SHOW_STREAMING_IN_RIGHT_DRAWER = "showStreamingInRightDrawer";
