<template>
  <div class="mode-selector">
    <v-radio-group v-model="mode" row>
      <span class="label -labelmargin">モード</span>
      <v-radio label="標準" value="youtube"></v-radio>
      <v-radio label="制御" value="jpeg"></v-radio>
    </v-radio-group>
    <div
      class="player-container"
      :class="{ '-active': isSelected }"
    >
      <Youtube
        v-if="mode == 'youtube'"
        :channel="channel"
        :height="height"
        :recorder="recorder"
      />
      <Jpeg
        v-if="mode == 'jpeg'"
        :height="height"
        :recorder="recorder"
      />
    </div>
  </div>
</template>

<script>
import Youtube from '~/components/player/youtube.vue';
import Jpeg from '~/components/player/jpeg.vue';

export default {
  props: [ 'channel', 'recorder', 'height', ],
  components: {
    Youtube,
    Jpeg,
  },
  data() {
    return {
      mode: 'youtube',
    }
  },
  computed: {
    isSelected() {
      const isPattern = this.$store.getters['view/type'] === 'pattern';
      if (isPattern) {
        return this.$store.getters['recorders/recorder'].data.id === this.recorder.id;
      }
      return false;
    }
  },
  watch: {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
  .mode-selector {
    .-labelmargin {
      margin-left: 1vw;
      margin-right: 1vw;
    }
  }
  .player-container {
    &.-active {
      border: solid medium #FF8200!important; // Vuetifyが!importantを指定しているので、!importantを使って無理やり上書きしている
    }
  }
</style>
