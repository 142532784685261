




























































import Vue from 'vue';
import { persistence } from '@/plugins/persistence';
import * as types from '~/store/types';
import deviceStatusMixin from '~/components/mixins/deviceStatus';

enum ControlType {
  PLAY = 'playVideo',
  PAUSE = 'pauseVideo',
  STOP = 'stopVideo',
  MUTE = 'mute',
  UN_MUTE = 'unMute',
}
const RELOAD_PLAYER_TIME_HOUR = 24; // 長時間再生時のプレイヤーリロード時間
const WARM_UP_WAITING_SEC = 4; // TRの再生ウォームアップが必要であったと判断するまでの時間
const LIVE_PLAYER_BUFFERING_SEC = 8; // ライブプレイヤーの安定再生に必要なバッファ時間
const LIVE_TIME_EXTEND_SEC = 20; // TRの再生準備のためにかかる時間の調整

const PLAYER_ADJUST_SLEEP = 100; // msec
const PLAYER_ADJUST_TARGET_DIFF = 20; // sec
const PLAYER_ADJUST_INTERVAL = 5; // sec
const PLAYER_ADJUST_PAUSE_THRESHOLD = 1; // sec
const PLAYER_ADJUST_FORWARD_THRESHOLD = 4; // sec
const PLAYER_ADJUST_FORWARD = 0.2; // sec

interface Data {
  message: string;
  timerIdList: number[] | null[];
  isMuted: boolean;
  isPaused: boolean; // プレイヤーが停止中
  videoPreparing: boolean; // 動画準備中
  unsubscribe: Unsubscribe | null;
  playerStyles: {
    'z-index': number;
  };
  hlsSource: string;
  hls: object;
  hlsConfig: object;
  mediaWaitingIntervalId: number;
  playerCoordinateIntervalId: number;
  reloadPlayerTimeId: number;

  // bufEnd1: number;
  // bufEnd2: number;
  // current: number;
}

type Unsubscribe = () => void;

export default Vue.extend({
  name: 'Youtube',
  mixins: [deviceStatusMixin],
  props: {
    channel: {
      type: String,
    },
    height: {
      type: String,
    },
    recorder: {
      type: Object,
    }
  },
  data(): Data {
    return {
      message: '',
      timerIdList: [null, null, null],
      isMuted: true,
      isPaused: true,
      videoPreparing: false,
      unsubscribe: null,
      // オーバーレイとプレイヤーを重ねて描画し、z-indexの値によってどちらを前面に出すかをコントロールしている。
      playerStyles: {
        'z-index': -1,
      },
      hlsSource: '',
      hls: null,
      hlsConfig: {
        "startPosition" : -1,
        "initialLiveManifestSize" : 5,
        "maxBufferLength" : 60,
        "liveSyncDurationCount" : 5,
        "liveDurationInfinity" : true
      },
      mediaWaitingIntervalId: 0,
      playerCoordinateIntervalId: 0,
      reloadPlayerTimeId: 0,

      // bufEnd1:0,
      // bufEnd2:0,
      // current:0,
    };
  },
  computed: {
    overlayStyles(): any {
      return {
        background: `url(${process.env.thumbnailUrl})`,
        'background-size': 'cover',
        'background-repeat': 'no-repeat',
        'background-position': 'center center',
        width: '100%',
        height: '100%',
        position: 'relative',
      };
    },
    isMap(): boolean {
      return this.$route.path === '/maps';
    },
    isPermittedMaximize(): boolean {
      return this.$route.path === '/maps' && this.$vuetify.breakpoint.mdAndUp;
    },
  },
  mounted() {
    this.muteStreaming();
    this.isPaused = true;
    this.videoPreparing = false;
    this.isMuted = true;

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'closeRightDrawerInMap' ||
        mutation.type === 'map/selectRecorder' ||
        mutation.type === 'view/changeRecorder') {
        this.stopStreaming();
        this.muteStreaming();
        this.isPaused = true;
        this.videoPreparing = false;
        this.isMuted = true;
        this.playerStyles['z-index'] = -1;
      }

      // HLSがサポートされてない時のイベントハンドルを設定
      this.addEventListener();

      // 一斉ライブ再生のState
      if (mutation.type === 'view/setStreamingState') {
        if (state.view.bulkStreamingState) {
          // パターン画面の全レコーダーに対して再生を実施する
          state.view.recorders
            .filter(recorder => recorder.id !== '')
            .forEach((recorder) => {
              if (this.recorder.id === recorder.id) {
                this.startStreaming();
              }
            });
        } else {
          state.view.recorders
            .filter(recorder => recorder.id !== '')
            .forEach((recorder) => {
              if (this.recorder.id === recorder.id) {
                this.pauseStreaming();
              }
            });
        }
      }
    });
  },
  beforeDestroy() {
    this.muteStreaming();
    this.stopStreaming();
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    this.isMuted = true;
    this.isPaused = true;
    this.videoPreparing = false;
    this.playerStyles['z-index'] = -1;
    this.$refs.youtube = null;
    delete this.$refs.youtube;
    this.clearAllTimers();
  },
  methods: {
    async waitMedia(source) {
      const start = new Date();
      return new Promise((resolve, reject)=>{
        let ok = false;
        this.mediaWaitingIntervalId = setInterval(async ()=> {
          console.log("waiting media...");
          const xhr = new XMLHttpRequest();
          xhr.open('GET', source, true);
          xhr.send();
          xhr.onload = ()=>{
            if(xhr.status === 200){
              console.info("status = 200. Media has been prepared.");
              console.info(xhr.response);
              clearInterval(this.mediaWaitingIntervalId);
              const end = new Date();
              const waitingTime = end.getTime() - start.getTime();
              ok = true;
              clearInterval(this.mediaWaitingIntervalId);
              resolve(waitingTime);
            }
          };
        }, 3000)

        // wait は5分
        setTimeout(()=>{
          if(!ok){
            clearInterval(this.mediaWaitingIntervalId);
            reject();
          }
        }, 5 * 60 * 1000);
      });
    },

    createHls() {
      const hls = new Hls(this.hlsConfig);
      console.log("createHLS", hls);
      return hls;
    },

    // NetworkStateコードからメッセージを取得
    getNetworkStateMsg(networkState) {
      switch (networkState) {
        case 0:
          return "NETWORK_EMPTY : まだデータがありません。 また、readyState は HAVE_NOTHING です。";
        case 1:
          return "NETWORK_IDLE : HTMLMediaElement はアクティブで、リソースを選択しましたが、ネットワークを使用していません。";
        case 2:
          return "NETWORK_LOADING : ブラウザーは HTMLMediaElement のデータをダウンロードしています。"
        case 3:
          return "NETWORK_NO_SOURCE : HTMLMediaElement の src が見つかりません。"
      }
    },

    // HLSプレイヤーにm3u8プレイリストをロードする
    async loadSource(source) {
      return new Promise((resolve, reject) => {
        console.log('load source', source);
        if (!source) {
          return;
        }
        const p = this.$refs.hlsPlayer;
        if (Hls.isSupported()) {
          console.log('HLS is supported');
          if(this.hls){
            this.hls.destroy();
          }
          this.hls = this.createHls();
          this.hls.attachMedia(p); // player = video tag
          this.hls.loadSource(source); // source = m3u8ファイル
          let ready = false;
          this.hls.on(Hls.Events.MANIFEST_PARSED, function (){
            console.log("manifest parsed. hls player is ready.");
            ready = true;
            resolve();
          });
          setTimeout(()=>{
            if(!ready) {
              console.info("load source... timeout");
              reject();
            }
          }, 5 * 60 * 1000);
        } else {
          console.info('HLS is not supported.');
          p.src = source;
          p.addEventListener('loadedmetadata', () => {
            resolve();
          });
        }
      });
    },

    // HLSがサポートされてない時のイベントハンドルを設定
    addEventListener() {
      if (!Hls.isSupported()) {
        const p = this.$refs.hlsPlayer;
        // p.addEventListener('abort', () => {
        //   console.info("abort");
        // });
        // p.addEventListener('canplay', () => {
        //   const msg = "canplay : メディアデータの再生を再開することができるが、 いま再生を再開すれば、現在の再生レートで最後まで再生できず、途中でバッファリングのために停止すると推定される時"
        //   console.info(msg);
        // });
        // p.addEventListener('canplaythrough', () => {
        //   const msg = "canplaythrough : いま再生を再開すれば、現在の再生レートで最後まで再生できて、途中でバッファリングのために停止することはないと推定される時"
        //   console.info(msg);
        // });
        // p.addEventListener('durationchange', () => {
        //   const msg = "durationchange : duration属性（メディアリソースの長さ、再生継続時間）が更新された"
        //   console.info(msg);
        // });
        p.addEventListener('emptied', () => {
          const msg1 = "emptied : 読み込みエラーなどの理由で、読み込みデータが空となった。 "
          const msg2 = this.getNetworkStateMsg(p.networkState);
          console.info(msg1 + msg2);
        });
        // p.addEventListener('ended', () => {
        //   console.info("ended");
        // });
        p.addEventListener('error', () => {
          let msg1 = "error[" + p.error.code + "]: ";
          switch (p.error.code) {
            case 1:
              msg1 += "MEDIA_ERR_ABORTED / ";
              break;
            case 2:
              msg1 += "MEDIA_ERR_NETWORK / ";
              break;
            case 3:
              msg1 += "MEDIA_ERR_DECODE / ";
              break;
            case 4:
              msg1 += "MEDIA_ERR_SRC_NOT_SUPPORTED / ";
              break;
          }
          const msg2 = this.getNetworkStateMsg(p.networkState);
          console.info(msg1 + msg2);
        });
        p.addEventListener('loadeddata', () => {
          const msg = "loadeddata : メディアデータを現在の再生位置で描画できる状態になった(初回)。"
          console.info(msg);
        });
        p.addEventListener('loadedmetadata', () => {
          const msg = "loadedmetadata : メタデータの読み込みが完了して、メディアリソースの長さと大きさが決まってテキストトラックの準備が出来た"
          console.info(msg);
        });
        // p.addEventListener('loadstart', () => {
        //   const msg = "loadstart : メディアデータの読み込みを開始した"
        //   console.info(msg);
        // });
        // p.addEventListener('pause', () => {
        //   console.info("pause");
        // });
        // p.addEventListener('play', () => {
        //   const msg = "play : 再生中"
        //   console.info(msg);
        // });
        // p.addEventListener('playing', () => {
        //   const msg = "playing : 一時停止した後、または、メディアデータの不足で遅延した後で、再生を再開する準備ができた"
        //   console.info(msg);
        // });
        // p.addEventListener('ratechange', () => {
        //   console.info("ratechange");
        // });
        // p.addEventListener('seeked', () => {
        //   console.info("seeked");
        // });
        // p.addEventListener('seeking', () => {
        //   console.info("seeking");
        // });
        p.addEventListener('stalled', () => {
          const msg1 = "stalled : メディアデータを読み込もうとしているが、予期しない理由で読み込めない。 "
          const msg2 = this.getNetworkStateMsg(p.networkState);
          console.info(msg1 + msg2);
        });
        // p.addEventListener('suspend', () => {
        //   console.info("suspend");
        // });
        // p.addEventListener('timeupdate', () => {
        //   const msg = "timeupdate : 現在の再生位置が変更された"
        //   console.info(msg);
        // });
        // p.addEventListener('volumechange', () => {
        //   console.info("volumechange");
        // });
        // p.addEventListener('waiting', () => {
        //   const msg = "waiting : 次のフレームが利用できないため再生停止しているが、やがてそのフレームが利用できるようになるのを待っている時"
        //   console.info(msg);
        // });
      }
    },

    // ライブ画面（大画面）を表示する
    maximizePlayerSize() {
      this.$store.dispatch('view/setRecorders', { type: 'recorder', recorders: [this.recorder] });
      const recorders = this.$store.getters['view/recorders'];

      if (this.isIE()) {
        persistence.persistentState('view/recorders', recorders)
      }

      this.$customRouter.push('/view');
    },
    // 再生を停止する
    pauseStreaming() {
      this.videoControl(ControlType.PAUSE);
      this.isPaused = true;
      this.videoPreparing = false;
      this.playerStyles['z-index'] = -1;
    },
    // プレーヤーの再生を開始する
    async play() {
      this.muteStreaming();

      try {
        await this.videoControl(ControlType.PLAY);
      } catch (err) {
        console.log(err);
        this.stopStreaming();
        return;
      }
      this.videoPreparing = false;
      this.playerStyles['z-index'] = 2;
      const streamingSetting = await this.$axios.get(process.env.apiBaseUrl + '/youtube-streaming-settings/' + this.recorder.id, {
        timeout: 300000,
      });
      const streamingDurationAsSecond = Number(streamingSetting.data.time) * 1000;

      // streaming_settingテーブルのtime列は、0の場合無制限を意味している
      const isUnlimitedDuration = streamingDurationAsSecond === 0;
      if (isUnlimitedDuration) {
        this.reloadPlayerTimerId = setTimeout(async ()=> {
          await this.videoControl(ControlType.PLAY).catch(() => {
            this.stopStreaming();
          });
        }, RELOAD_PLAYER_TIME_HOUR * 60 * 60 * 1000);
        return;
      }
      // 再生時間制限がある場合は、規定時間後に再生を停止する
      this.timerIdList[0] = setTimeout( () => {
        this.stopStreaming();
      }, streamingDurationAsSecond);
    },

    // 機器に再生命令を出しつつ、プレイヤーを再生する
    async startStreaming() {
      console.info("startStreaming");
      this.isPaused = false;
      this.videoPreparing = true;

      const isAlreadyPlayed = await this.getRecStatus(this.recorder.id);
      if (isAlreadyPlayed) {
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '他ユーザーまたは他PCが利用しているため、ライブ開始できません。' });
        this.isPaused = true;
        this.videoPreparing = false;
        return;
      }

      // メール通知プランであるレコーダーの場合は実行不可
      if (this.isMailNotificationPlan(this.recorder.plan)) {
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'メール通知プランの場合は再生できません。' });
        this.isPaused = true;
        this.videoPreparing = false;
        return;
      }

      // 映像配信権限が無い場合はYoutube再生および配信制御をさせない
      // 選択されたレコーダーの組織IDでログインユーザーのレコーダー権限を判定
      if (!this.hasLiveOperation(this.recorder.organization_id)) {
        console.log('Youtube再生および配信制御  映像配信権限無し');
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'ライブ映像確認権限が付与されていません。' });
        this.isPaused = true;
        this.videoPreparing = false;
        return;
      }

      // 配信開始要求cgiが失敗した場合でも動画の再生は開始する
      this.play();
      await this.sendStreamingStartCommand();
    },
    stopStreaming() {
      console.info("stopStreaming");
      this.videoControl(ControlType.STOP);
      if(this.hls){
        this.hls.destroy();
      }
      this.isPaused = true;
      this.isMuted = true;
      this.videoPreparing = false;
      this.playerStyles['z-index'] = -1;
      this.clearAllTimers();
    },
    clearAllTimers() {
      this.timerIdList.forEach((timerId, index) => {
        clearTimeout(timerId);
        this.timerIdList[index] = null;
      });
      clearInterval(this.mediaWaitingIntervalId);
      clearInterval(this.playerCoordinateIntervalId);
      clearTimeout(this.reloadPlayerTimeId);
    },
    /**
     * Youtubeが埋め込まれたiframeを制御する
     *
     * @param action {ControlType} 操作の種別
     **/
    async videoControl(action: ControlType) {
      const p: HTMLVideoElement = this.$refs.hlsPlayer;
      switch (action) {
        case ControlType.PLAY: {
          if(!p.paused) {
            p.pause(); // すでに何か再生しているかもしれないので、一度停止する
          }
          try {
            const waitingTime = await this.waitMedia(this.channel);
            if (this.isIE()) {
              await new Promise((resolve)=>{
                setTimeout(()=>{ resolve();}, 1000)
              });
            }

            // TRがウォームアップを必要とすると判断した場合ウェイトを入れる。
            if(WARM_UP_WAITING_SEC * 1000 < waitingTime) {
              console.info("TR warm up ");
              await this.sleep(LIVE_PLAYER_BUFFERING_SEC * 1000);
            }
            await this.loadSource(this.channel);

            if(this.hls){
              console.log("register hls error handler.");
              // hlsプレイヤーでなんらかのエラーが発生した場合のハンドラを登録
              this.hls.on(Hls.Events.ERROR, async (e, d) => {
                console.log(e, d);
                if(d.details === 'bufferStalledError') {
                  console.log("bufferStalled発生。");
                  // this.dump();
                  // console.log("mode=another, Hlsを再生成し、プレイヤーに再度attachする, pause版");
                  // try {
                  //   await this.loadSource(this.channel); // source = m3u8ファイル
                  //   p.play();
                  // } catch (e) {
                  //   console.log("再生でエラーが発生しました。", e);
                  //   this.dump();
                  //   this.stopStreaming();
                  // }
                } else if(d.details === 'flagLoadError'){
                  // this.dump();
                  this.hls.destroy();
                  this.videoControl(ControlType.PLAY); // m3u8が見つからなくなった場合、再度m3u8を探し始める
                }
              });
            }

            // 再生開始
            console.info("再生開始start");
            await p.play();
            console.info("再生開始end");
            // 長時間再生時に再生が不安定になる場合があるので、バッファが常に20秒保たれるように再生ペースを調整する
            this.playerCoordinateIntervalId = setInterval(async () => {
              try {
                const diff = p.buffered.end(0) - p.currentTime;
                const tDiff = diff - PLAYER_ADJUST_TARGET_DIFF;
                if(tDiff < PLAYER_ADJUST_PAUSE_THRESHOLD){
                  console.info("adjust pause,", PLAYER_ADJUST_SLEEP);
                  // this.dump();
                  p.pause();
                  await this.sleep(PLAYER_ADJUST_SLEEP);
                  p.play();
                } else if (tDiff > PLAYER_ADJUST_FORWARD_THRESHOLD){
                  console.info("adjust forward,", PLAYER_ADJUST_FORWARD);
                  // this.dump();
                  p.currentTime = p.currentTime + PLAYER_ADJUST_FORWARD;
                }
              } catch ( e ) {
                console.error(e);
              }
            }, PLAYER_ADJUST_INTERVAL * 1000);

            // setInterval(() => {
            //   this.current = p.currentTime;
            //   try {
            //     this.bufEnd1 = p.buffered.end(0);
            //     this.bufEnd2 = p.buffered.end(1);
            //   } catch ( error ) {
            //   }
            // }, 100);
            return;
          } catch ( e ) {
            console.log(e);
            throw e;
          }
        }
        case ControlType.PAUSE:
          p.pause();
          return;
        case ControlType.STOP:
          p.pause();
          return;
        case ControlType.MUTE:
          p.muted = true;
          return;
        case ControlType.UN_MUTE:
          p.muted = false;
          return;
      }
    },
    // dump() {
    //   console.log("----dump-start---");
    //   console.log("bufEnd1:", this.bufEnd1);
    //   console.log("bufEnd2:", this.bufEnd2);
    //   console.log("current:", this.current);
    //   console.log("----dump-end---");
    // },
    async sleep(wait: number): Promise<void>{
      return new Promise((resolve)=>{
        setTimeout(()=>{
          resolve();
        }, wait);
      });
    },
    unMuteStreaming() {
      this.videoControl(ControlType.UN_MUTE);
      this.isMuted = false;
    },
    muteStreaming() {
      this.videoControl(ControlType.MUTE);
      this.isMuted = true;
    },
    /**
     * 配信開始 以下の処理を実行し、Youtube映像配信を行う
     *  1.CGI 機器設定変更(Youtube設定を機器に反映)
     *  2.CGI 映像配信制御実行
     *  3.API 映像配信設定更新(Actを更新)
     */
    async sendStreamingStartCommand() {
      // 映像配信権限が無い場合はCGI実行は行わない
      // 選択されたレコーダーの組織IDでログインユーザーのレコーダー権限を判定
      if (!this.hasLiveOperation(this.recorder.organization_id)) {
        console.log(this.recorder);
        console.log('ライブ映像確認権限無し');
        return;
      }

      // 低品質配信された場合にメッセージ出すためのフラグ
      let lowQualityStreaming = false;

      /**
       * 1.機器設定変更(Youtube設定を機器に反映)
       */
        // レコーダーの最新情報を取得
      let latestRecorder = null;
      const targetRecorderId = this.recorder.id
      try {
        latestRecorder = await this.$axios.get(process.env.apiBaseUrl + '/recorder/' + this.recorder.id, {
          // withCredentials: true,
          timeout: 300000,
        });
      } catch ( err ) {
        console.log(err);
        console.log('レコーダー最新情報取得 [失敗]');
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'レコーダー最新情報の取得に失敗しました。' });
        this.$store.commit('view/setWaitingStreamingState', {
          type: 'start',
          isWaiting: false,
        });
        console.log(err);
        return;
      }
      // レコーダー最新のVPN設定およびYoutube設定
      const youtubeData = latestRecorder.data.youtube;

      // 機器設定変更パラメータ
      // ライブ公開設定により値を変更
      let rtmpServer = youtubeData.private.rtmpServer;
      let rtmpKey = youtubeData.private.rtmpKey;
      if (youtubeData.open_status === '1') { // Youtube 公開
        rtmpServer = youtubeData.public.rtmpServer;
        rtmpKey = youtubeData.public.rtmpKey;
      }
      let configWriteCgiParams = 'youtube_url=' + rtmpServer + '&youtube_key=' + rtmpKey + '&equipment_name=' + latestRecorder.data.name + '&';

      //設定レコーダーとporpsで渡ってきたレコーダーIDが異なる場合は処理を止める
      if(targetRecorderId !== this.recorder.id){
        console.log('機器設定変更CGI実行 stop')
        return
      }

      try {
        // 機器設定変更CGI実行
        configWriteCgiParams = await this.$axios.post(process.env.cgiBaseUrl, {
          recorderID: this.recorder.id,
          method: 'POST',
          url: '/configwrite.cgi',
          data: configWriteCgiParams,
        }, {
          timeout: 300000,
        })
      } catch ( err ) {
        console.log(err);
        console.log('機器設定変更CGI実行(Youtube設定更新) [失敗]');
        if (err.response.status == 403) return;
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '機器設定変更に失敗しました。' });
        this.$store.commit('view/setWaitingStreamingState', {
          type: 'start',
          isWaiting: false,
        });
        console.log(err);
        return;
      }
      console.log(configWriteCgiParams);
      console.log('機器設定変更CGI実行[成功]');

      /**
       * 2.映像配信設定取得 & 映像配信制御実行
       */
      let streamingCtrl = null;
      try {
        streamingCtrl = await this.$axios.get(process.env.apiBaseUrl + '/youtube-streaming-settings/' + this.recorder.id, {
          // withCredentials: true,
          timeout: 300000,
        });
      } catch ( err ) {
        console.log(err);
        console.log('映像配信設定取得 [失敗]');
        if (err.response.status == 403) return;
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '映像配信設定取得の取得に失敗しました。' });
        this.$store.commit('view/setWaitingStreamingState', {
          type: 'start',
          isWaiting: false,
        });
        console.log(err);
        return;
      }
      // 映像配信制御要求パラメータ設定
      const act = 'start'; // 配信開始
      const mode = 0; // YoutubeLive への配信
      const encode_type = streamingCtrl.data.encode_type;

      // 配信時間
      const time = streamingCtrl.data.time === 0 ? 0 :  streamingCtrl.data.time + LIVE_TIME_EXTEND_SEC;

      // 配信品質
      let image_size = streamingCtrl.data.image_size;
      let frame_rate = streamingCtrl.data.frame_rate;
      let image_quarity = streamingCtrl.data.image_quarity;

      // レコーダーの通信容量
      let recorderSimTraffic = null;
      try {
        recorderSimTraffic = await this.$axios.get(process.env.apiBaseUrl + '/sim-traffic/' + this.recorder.id, {
          timeout: 300000,
        });
      } catch ( err ) {
        console.log(err);
        console.log('レコーダー通信量情報取得 [失敗]');
        if (err.response.status == 403) return;
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'レコーダー通信量情報の取得に失敗しました。' });
        this.$store.commit('view/setWaitingStreamingState', {
          type: 'start',
          isWaiting: false,
        });
        return;
      }

      // SIMの通信残量が0MB以下の場合、低画質で要求すること。
      // [HQVGAW ,低 , 1fps=64kbps]
      if ((recorderSimTraffic.data.sim_capacity_total_mb - recorderSimTraffic.data.sim_traffic_sum_mb) <= 0) {
        image_size = 0; // HQVGAW
        frame_rate = 0; // 1fps
        image_quarity = 0; // 低
        lowQualityStreaming = true;
      }
      // CGI実行
      const data = 'act=' + act +
        '&mode=' + mode +
        '&time=' + time +
        '&encode_type=' + encode_type +
        '&image_size=' + image_size +
        '&frame_rate=' + frame_rate +
        '&image_quarity=' + image_quarity +
        '&';
      try {
        await this.$axios.post(process.env.cgiBaseUrl, {
          recorderID: this.recorder.id,
          method: 'POST',
          url: '/streaming_ctrl',
          data: data,
        }, {
          timeout: 300000,
        });
      } catch ( err ) {
        console.log('CGI 映像配信制御 開始 [失敗]');
        console.log(err);
        if (err.response.status == 403) return;
        await this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '映像配信制御の開始に失敗しました。' });
        return;
      }

      console.log('CGI 映像配信制御 開始 [成功]');
      if (lowQualityStreaming) {
        this.$store.dispatch(types.ACTIVATE_INFO_NOTIFICATION, { message: '[' + latestRecorder.data.name + ']' + 'のSIM残量が少ないため、低品質で配信開始。' });
        lowQualityStreaming = false;
      }

      const recorderID = this.recorder.id;
      // 成功時は映像配信設定のactを更新
      let res = null;
      try {
        res = await this.$axios.put(process.env.apiBaseUrl + '/streaming_ctrl-act-start/' + recorderID, {
          // withCredentials: true,
          timeout: 300000,
        });
      } catch ( err ) {
        console.log('映像配信設定更新 [失敗]');
        this.$store.commit('view/setWaitingStreamingState', {
          type: 'start',
          isWaiting: false,
        });
        console.log(err);
        return;
      }
      console.log('映像配信設定更新 [成功]');
      console.log(res);
      this.$store.commit('view/setWaitingStreamingState', {
        type: 'start',
        isWaiting: false,
      });
    },
    sendStreamingStopCommand(): Promise<any> {
      return new Promise((resolve, reject) => {
        const data = 'act=stop&';
        this.$axios.post(process.env.cgiBaseUrl, {
          recorderID: this.recorder.id,
          method: 'POST',
          url: '/streaming_ctrl',
          data: data,
        }, {
          timeout: 300000,
        }).then((data) => {
          console.log(data);
          console.log('CGI 映像配信制御 停止 [成功]');

          // 成功時は映像配信設定のactを更新
          this.$axios.put(process.env.apiBaseUrl + '/streaming_ctrl-act-stop/' + this.recorder.id, {
            // withCredentials: true,
            timeout: 300000,
          }).then((res) => {
            console.log('映像配信設定更新 [成功]');
            console.log(res);
            this.$store.commit('view/setWaitingStreamingState', {
              type: 'end',
              isWaiting: false,
            });
            resolve(res);
          }).catch((err) => {
            console.log('映像配信設定更新 [失敗]');
            console.log(err);
            this.$store.commit('view/setWaitingStreamingState', {
              type: 'end',
              isWaiting: false,
            });
            reject(err);
          });
        }).catch((errorCode) => {
          console.log(errorCode);
          console.log('CGI 映像配信制御 停止 [失敗]');
          if (errorCode.response.status == 403) return;
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '映像配信制御の停止に失敗しました。' });

          this.$store.commit('view/setWaitingStreamingState', {
            type: 'end',
            isWaiting: false,
          });
          reject(errorCode);
        });
      });
    },
  },
});
