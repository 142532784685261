/**
 * マップ画面で使用するStoreなので一旦分業の観点からmap.jsとして配置したが、
 * 意味合い的にはレコーダーに関連するStoreなので、場合によってはそちらへ移動する予定。
 */
const initialState = () => ({
  permittedRecorders: [],
  loginPosition: {},
  selectedRecorder: {},
  recorderIdAndLockStateMap: {},
});

export const state = initialState;

export const getters = {
  permittedRecorders: (state) => {
    return state.permittedRecorders;
  },
  loginPosition: (state) => {
    return state.loginPosition;
  },
  selectedRecorder: (state) => {
    return state.selectedRecorder;
  },
  recorderIdAndLockStateMap: (state) => {
    return state.recorderIdAndLockStateMap;
  }
};

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  clearRecorderIdAndLockStateMap(state) {
    state.recorderIdAndLockStateMap = {};
  },
  setPermittedRecorders (state, recorders) {
    state.permittedRecorders = recorders;
    const currentRecorderIdMap = { ...state.recorderIdAndLockStateMap };

    state.recorderIdAndLockStateMap = state.permittedRecorders.reduce((accumulator, current) => {
      return { ...accumulator, [current.id]: true };
    }, {});

    for (const key of Object.keys(state.recorderIdAndLockStateMap)) {
      if (key in currentRecorderIdMap) {
        state.recorderIdAndLockStateMap[key] = currentRecorderIdMap[key];
      }
    }
  },
  setLoginPosition (state, loginPosition) {
    state.loginPosition = loginPosition;
  },
  selectRecorder(state, recorder) {
    state.selectedRecorder = recorder;
  },
  clearSelectedRecorder(state) {
    state.selectedRecorder = {};
  },
  setLocked(state, payload) {
    const recorder = state.permittedRecorders.find((recorder) => {
      return recorder.id === payload.recorderId;
    });
    if (payload.locked) {
      state.recorderIdAndLockStateMap[recorder.id] = true;
    } else {
      state.recorderIdAndLockStateMap[recorder.id] = false;
    }
  },
};

export const actions = {
  clearRecorderIdAndLockStateMap(ctx) {
    return new Promise((resolve) => {
      ctx.commit('clearRecorderIdAndLockStateMap');
      resolve();
    });
  },
  selectRecorder(ctx, recorder) {
    return new Promise((resolve) => {
      ctx.commit('selectRecorder', recorder);
      resolve();
    });
  },
  fetchPermittedRecorders (ctx) {
    return new Promise(async (resolve, reject) => {
      const permittedRecorders = await this.$axios.$get(process.env.apiBaseUrl + '/permitted-recorders')
        .catch((err) => {
          console.error(err);
          reject(err);
        });

      if (permittedRecorders) {
        permittedRecorders.map((recorder) => {
          recorder.locked = true;
        });
        ctx.commit('setPermittedRecorders', permittedRecorders);
      }

      resolve();
    });
  },
  updateLoginPosition (ctx, mapState) {
    return new Promise(async (resolve, reject) => {
      const apiEndpoint = process.env.apiBaseUrl + '/login-position';
      const newLoginPosition = await this.$axios.$put(apiEndpoint, mapState)
        .catch((err) => {
          console.error(err);
          reject(err);
        });
      ctx.commit('setLoginPosition', newLoginPosition);
      resolve();
    });
  },
  fetchLoginPosition (ctx) {
    return new Promise(async (resolve, reject) => {
      const loginPosition = await this.$axios.$get(process.env.apiBaseUrl + '/login-position')
        .catch((err) => {
          console.error(err);
          reject(err);
        });
      ctx.commit('setLoginPosition', loginPosition);
      resolve();
    });
  },
  enableGpsCoordinate (ctx, deviceId) {
    return new Promise(async (resolve, reject) => {
      await this.$axios.$post(process.env.apiBaseUrl + '/gps-coordinate', {device_id: deviceId})
        .catch((err) => {
          console.error(err);
          reject(err);
        });
      resolve();
    });
  }
};
