const initialState = () => ({
  // ログイン時点で取得した「ユーザー」情報
  userInfo: null,
  /**
   * ログインユーザーの全レコーダー権限
   *   組織IDを判定で受け取る必要があるためhelpers.jsに判定メソッドは実装
   * @see helpers.js
   */
  recorderPermissions: {},
});

export const state = initialState;

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setUserInfo(state, payload) {
    state.userInfo = _.clone(payload);
    // レコーダー権限を設定
    state.recorderPermissions = {};
    if (state.userInfo) {
      _.map(state.userInfo.recorder_permissions, (permissions, index) => {
        state.recorderPermissions[permissions.organization_id] = _.clone(permissions);
      });
    }
  },
};

export const actions = {
  setUserInfo({ commit }, payload) {
    commit('setUserInfo', payload);
  },
};

export const getters = {
  // 権限自体を取得する
  getPermission: (state) => {
    const userInfo = state.userInfo;
    console.log('[auth/getters.getPermission]');
    console.log(userInfo);
    // 未定義
    if (!userInfo) {
      return "99";
    }
    if (!userInfo['account_type']) {
      return "99";
    }
    return userInfo['account_type']
  },

  // 「システム管理者」権限を持つ
  hasSystemAdminPermission: (state) => {
    if (!state.userInfo) {
      return false;
    }
    switch (state.userInfo['account_type']) {
      case "1":
        return true;
      default:
        return false;
    }
  },

  // 「顧客管理者」権限を持つ
  hasCustomerAdminPermission: (state) => {
    if (!state.userInfo) {
      return false;
    }
    switch (state.userInfo['account_type']) {
      case "1":
      case "2":
        return true;
      default:
        return false;
    }
  },

  // 「ユーザー管理者」権限を持つ
  hasUserAdminPermission: (state) => {
    if (!state.userInfo) {
      return false;
    }
    switch (state.userInfo['account_type']) {
      case "1":
      case "2":
      case "3":
        return true;
      default:
        return false;
    }
  },

  // 「一般ユーザー」権限を持つ
  hasGeneralUserPermission: (state) => {
    if (!state.userInfo) {
      return false;
    }
    switch (state.userInfo['account_type']) {
      case "1":
      case "2":
      case "3":
      case "4":
        return true;
      default:
        return false;
    }
  },

  getLoginUserId: (state) => {
    if (!state.userInfo) {
      return '';
    }
    return state.userInfo['id']
  },

  getLoginUserMail: (state) => {
    if (!state.userInfo) {
      return '';
    }
    return state.userInfo['mail_address']
  },

  // ログインユーザーの全レコーダー権限取得
  getRecorderPermissions: (state) => {
    return state.recorderPermissions;
  },

  userInfo: (state) => {
    return state.userInfo;
  }
};
