<template>
  <v-dialog
    v-model="dialog"
    persistent
    content
    content-class="centered-dialog">
    <v-card>
      <v-card-title
        class="subheading">
        <span class="dialog-title-margin">{{ message }}</span>
      </v-card-title>
      <v-card-actions>
        <v-spacer> </v-spacer>
        <v-btn class="dialog-button-margin"
               color="grey darken-2"
               outline
               @click="no()" >
               {{ button.no }}
        </v-btn>
        <v-btn class="dialog-button-margin"
               color="primary"
               @click="yes()" >
               {{ button.yes }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: 'よろしいですか？',
      button: {
        yes: 'はい',
        no: 'いいえ',
      },
    };
  },
  methods: {
      confirm(options) {
        this.dialog = true
        if(options && options.message)
          this.message = options.message;
        if(options && options.button)
          this.button = Object.assign(this.button, options.button)

        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        })
      },
      yes() {
        this.resolve(true);
        this.dialog = false;
      },
      no() {
        this.resolve(false);
        this.dialog = false;
      }
    }
};
</script>
