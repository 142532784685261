export default {
  methods: {
    /**
     * 低遅延モードの配信状態を取得する。
     * @param {string} id 対象デバイスのID
     * @return {boolean} 配信中かどうか true: 配信中 false: 配信していない
     * */
    async getRecStatus(id) {
      const apiEndpoint = process.env.apiBaseUrl + '/device-status/rec-status/' + id;
      const apiResponse = await this.$axios.get(apiEndpoint, {
        timeout: 300000,
      });
      return apiResponse.data.rec_status;
    },
    /**
     * 低遅延モードの配信状態を更新する。
     * @param {string} id 対象デバイスのID
     * @param {boolean} nextState true: 配信開始 false: 配信終了
     * @return {Promise<void>}
     **/
    async changeRecState(id, nextState) {
      const apiEndpoint = process.env.apiBaseUrl + '/device-status/rec-status/' + id;
      let currentUserId

      if (this.isIE()) {
        if (this.$store.getters['auth/userInfo']) {
          currentUserId = this.$store.getters['auth/userInfo'].id;
        } else {
          currentUserId = localStorage.getItem('currentUserId');
        }
      } else {
        // 認証情報がstoreにない場合は処理しない。
        if(!this.$store.getters['auth/userInfo']){
          return;
        }
        currentUserId = this.$store.getters['auth/userInfo'].id;
      }

      if (!currentUserId) {
        return;
      }

      await this.$axios.put(apiEndpoint, {
        user_id: currentUserId,
        rec_status: nextState,
      }, {
        timeout: 300000,
      });
    }
  },
}
