import firebase from 'firebase/app';
import 'firebase/auth';
import * as types from "~/store/types";

// contextの中のaxiosとstoreとrouterプロパティを取る
export default function ({$axios, store, app}) {
  $axios.onRequest(async function (config) {
    if (!process.env.useAuth) {
      const email = localStorage.getItem('local-email');
      config.headers.common['LocalEmail'] = email;
      return config;
    }
    if( config.url.indexOf("/operation_history/loginlogout") !== -1 ){
      return config;
    }
    let user = firebase.auth().currentUser;
    if(!user){
      // ログイン直後など、まだFirebase上からログイン済みのユーザが取得できない時はwaitをかけて取得する
      const wait = (resolve, reject)=>{
        setTimeout(()=>{
          const user = firebase.auth().currentUser;
          console.log("firebase user: ", user);
          resolve(user);
        }, 1000);
      };
      let cnt = 0;
      while(cnt < 10) {
        console.log('user not found. try to wait 1000ms');
        user = await new Promise(wait);
        if(user){
          console.log('user found. break.');
          break;
        }
        cnt++;
      }
    }

    let token = '';
    if(user) {
      token = await user.getIdToken();
    }

    config.headers.common['Authorization'] = 'Bearer ' + token;
    return config;
  });

  // エラーハンドリングモーダルテスト用
  $axios.interceptors.response.use(
    response => {
      if (response.config.url.includes('simulateError')) {
        const simulatedError = {
          response: {
            status: 500,
            data: { message: 'エラーが発生しました' }
          }
        };
        return Promise.reject(simulatedError);
      }
      return response;
    },
    error => Promise.reject(error)
  );

  $axios.onResponseError(async error => {
    store.dispatch("setLoadingState", false);

    if (!error) {
      console.log('[axios.js] 想定外のエラーが発生しました。');
      store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '想定外のエラーが発生しました。'});
      return;
    }

    const code = parseInt(error.response && error.response.status);
    if (code === 401) {
      await firebase.auth().signOut();
      // pingAPIの結果はエラー表示を出さない
      if (error.response.config.url.indexOf("ping") !== -1) {
        return;
      }
      app.$customRouter.push('/login');
      console.log('[axios.js] 認証に失敗しました。有効なアカウントで再ログインしてください。');
      store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '認証に失敗しました。有効なアカウントで再ログインしてください。'});
      store.dispatch('auth/setUserInfo', null);
    } else {
      console.log('[axios.js] Other Error: ' + error);

      if (!error || !error.response || !error.response.data || !error.response.data.message) {
        return Promise.reject(error);
      }
      // TODO: 本来はサーバーサイドからエラーコードとかを投げてもらいたい
      if (error.response.data.message === 'メール通知プランの場合は実行できません。') {
        return Promise.reject(error);
      }

      // FIXME 呼び出し元でのハンドリングにするため、いつか消さねばならない
      store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: error.response.data.message});
      return Promise.reject(error);
    }
  });
}
