const initialState = () => ({
  filter_name: null,
  filter_company_name: null,

  showForm: false,
});

export const state = initialState;

export const getters = {
  filters: (state) => {
    return {
      name: state.filter_name,
      company_name: state.filter_company_name,
    };
  },
};

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setFilterName(state, data) {
    state.filter_name = _.clone(data);
  },
  setFilterCompanyName(state, data) {
    state.filter_company_name = _.clone(data);
  },

  showForm(state) {
    state.showForm = !state.showForm;
  },
};

export const actions = {
  filterName({ commit }, data) {
    commit('setFilterName', data);
  },
  filterCompanyName({ commit }, data) {
    commit('setFilterCompanyName', data);
  },
  clearFilters({ commit }) {
    commit('setFilterName', null);
    commit('setFilterCompanyName', null);
  },

  showForm({ commit }) {
    commit('showForm');
  },
};
