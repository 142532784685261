<template>
  <v-container>
    <p class="title">
      カメラ状態
    </p>
    <v-layout justify-center wrap align-space-between>
      <v-flex xs6>
        <p class="subheading">
          最終通信日時：
        </p>
      </v-flex>
      <v-flex :class="isConnectionError ? 'red_bold' : ''" xs6>
        {{ formatDate(status.created_at, "YYYY/MM/DD HH:mm") }}
        <p v-if="isConnectionError">
          （通信異常）
        </p>
        <p v-if="isLoading">
          （情報取得中）
        </p>
      </v-flex>
      <template v-if="!isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs6>
          <p class="subheading">
            SDカード録画状態:
          </p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.rec_sd_status, 2)" xs6>
          {{ statusMap.rec_sd_status[status.rec_sd_status] }}
        </v-flex>
        <v-flex xs6>
          <p class="subheading">
            SDカード状態:
          </p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.sd_status, 0)" xs6>
          {{ statusMap.sd_status[status.sd_status] }}
        </v-flex>
      </template>
      <v-flex xs6>
        <p class="subheading">
          SSD録画状態:
        </p>
      </v-flex>
      <v-flex :class="checkAbnormal(status.rec_ssd_status, 0)" xs6>
        {{ statusMap.rec_ssd_status[status.rec_ssd_status] }}
      </v-flex>
      <v-flex xs6>
        <p class="subheading">
          SSD状態:
        </p>
      </v-flex>
      <v-flex :class="checkAbnormal(status.ssd_status, 0)" xs6>
        {{ statusMap.ssd_status[status.ssd_status] }}
      </v-flex>
      <v-flex xs6>
        <p class="subheading">
          Wi-Fiモジュール:
        </p>
      </v-flex>
      <v-flex :class="checkAbnormal(status.wlan_status, 0)" xs6>
        {{ statusMap.wlan_status[status.wlan_status] }}
      </v-flex>
      <v-flex xs6>
        <p class="subheading">
          機器内温度状態:
        </p>
      </v-flex>
      <v-flex :class="checkAbnormal(status.temp_status, 0, 1)" xs6>
        {{ statusMap.temp_status[status.temp_status] }}
      </v-flex>
      <!-- Sigfox通信仕様(Rev1.18)では該当する項目なし -->
      <template v-if="this.isLTE(recorder.type)">
        <v-flex xs6>
          <p class="subheading">
            LTE 電波強度:
          </p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.lte_signal_strength)" xs6>
          {{ statusMap.lte_signal_strength[status.lte_signal_strength] }}
        </v-flex>
      </template>
      <template v-if="isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs6>
          <p class="subheading">
            カメラマイコン状態:
          </p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.cam_mcu_status, 0)" xs6>
          {{ statusMap.cam_mcu_status[status.cam_mcu_status] }}
        </v-flex>
        <v-flex xs6>
          <p class="subheading">
            サブマイコン状態:
          </p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.sub_mcu_status, 0)" xs6>
          {{ statusMap.sub_mcu_status[status.sub_mcu_status] }}
        </v-flex>
      </template>

      <v-flex xs6>
        <p class="subheading word-wrap">{{ recorder.aux.in1 }}:</p>
      </v-flex>
      <v-flex :class="checkAbnormal(status.auxin1, 'on')" xs6>
        {{ statusMap.auxin1[status.auxin1] }}
      </v-flex>
      <v-flex xs6>
        <p class="subheading word-wrap">{{ recorder.aux.in2 }}:</p>
      </v-flex>
      <v-flex :class="checkAbnormal(status.auxin2, 'on')" xs6>
        {{ statusMap.auxin2[status.auxin2] }}
      </v-flex>
      <template v-if="!isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs6>
          <p class="subheading word-wrap">{{ recorder.aux.in3 }}:</p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.auxin3, 'on')" xs6>
          {{ statusMap.auxin3[status.auxin3] }}
        </v-flex>
      </template>
      <template v-if="!isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs6>
          <p class="subheading">
            カメラ状態:
          </p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.cam_status, 0, 2)" xs6>
          {{ statusMap.cam_status[status.cam_status] }}
        </v-flex>
      </template>
      <template v-if="!isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs6>
          <p class="subheading">
            ボックス開閉状態:
          </p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.box_state)" xs6>
          {{ statusMap.box_state[status.box_state] }}
        </v-flex>
      </template>
      <v-flex xs6>
        <p class="subheading word-wrap">{{ recorder.aux.out1 }}:</p>
      </v-flex>
      <v-flex :class="checkAbnormal(status.auxout1)" xs6>
        {{ statusMap.auxout1[status.auxout1] }}
      </v-flex>
      <v-flex xs6>
        <p class="subheading word-wrap">{{ recorder.aux.out2 }}:</p>
      </v-flex>
      <v-flex :class="checkAbnormal(status.auxout2)" xs6>
        {{ statusMap.auxout2[status.auxout2] }}
      </v-flex>
      <template v-if="!isLTEMailNotificationPlanGR301(recorder.plan)">
        <v-flex xs6>
          <p class="subheading word-wrap">{{ recorder.aux.out3 }}:</p>
        </v-flex>
        <v-flex :class="checkAbnormal(status.auxout3)" xs6>
          {{ statusMap.auxout3[status.auxout3] }}
        </v-flex>
      </template>
      <!-- Sigfox通信仕様(Rev1.18)では該当する項目なし -->
      <!-- MEMO: ファン状態はユーザには見せない。
      <template v-if="this.isLTE(recorder.type)">
        <v-flex xs6> <p class="subheading">ファン状態: </p> </v-flex>
        <v-flex xs6> {{ statusMap.fan_status[status.fan_status] }} </v-flex>
      </template>
      -->
    </v-layout>
  </v-container>
</template>

<script>
import * as types from "~/store/types";
import moment from "moment";
export default {
  props: {
    recorder: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      statusMap: {
        rec_sd_status: { 0: "録画停止", 1: "録画中", 2: "異常" },
        sd_status: { 0: "異常", 1: "正常", 2: "SD カードなし" },
        rec_ssd_status: { 0: "録画停止", 1: "録画中" },
        ssd_status: { 0: "異常", 1: "正常" },
        wlan_status: { 0: "異常", 1: "正常" },
        temp_status: {
          0: "オーバーヒート（ファン異常）",
          1: "オーバーヒート",
          2: "正常（ファン異常）",
          3: "正常"
        },
        lte_signal_strength: {
          0: "圏外",
          1: "アンテナ 1 本",
          2: "アンテナ 2 本",
          3: "アンテナ 3 本",
          4: "アンテナ 4 本",
          5: "アンテナ 5 本"
        },
        cam_mcu_status: { 0: "異常", 1: "正常" },
        sub_mcu_status: { 0: "異常", 1: "正常" },
        auxin1: { on: "オン", off: "オフ" },
        auxin2: { on: "オン", off: "オフ" },
        auxin3: { on: "オン", off: "オフ" },
        cam_status: { 0: "未接続", 1: "接続中", 2: "異常" },
        box_state: { 0: "開", 1: "閉" },
        auxout1: { on: "オン", off: "オフ" },
        auxout2: { on: "オン", off: "オフ" },
        auxout3: { on: "オン", off: "オフ" },
        fan_status: { 0: "異常", 1: "正常" }
      },

      deviceId: null
    };
  },
  computed: {
    status() {
      return this.$store.getters["recorders/recorderStatus"];
    },
    isConnectionError() {
      return this.$store.getters["recorders/isConnectionError"];
    },
    isLoading() {
      return this.$store.getters["recorders/isLoading"];
    }
  },
  watch: {
    recorder: {
      immediate: true,
      handler() {
        if (!this.recorder.id) {
          return;
        }
        this.$store.dispatch("recorders/getStatus", this.recorder.id);
      }
    }
  },
  methods: {
    checkAbnormal(target, ...abnormalPatterns) {
      const strPatterns = _.map(abnormalPatterns, pattern => String(pattern));
      const isAbnormal = strPatterns.indexOf(target) !== -1;
      if (this.$store.getters["recorders/isConnectionError"]) {
        return isAbnormal ? "red_grey_bold" : "greyout";
      } else if (isAbnormal) {
        return "red_bold";
      }
    },
    formatDate(date, format) {
      return moment(date).format(format);
    }
  }
};
</script>

<style lang="scss" scoped>
$red: #f00;
$grey: #bbb;
.red_bold {
  color: $red;
  font-weight: bold;
}
.red_grey_bold {
  color: $red + $grey;
  font-weight: bold;
}
.greyout {
  color: $grey;
}
.word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
