const initialState = () => ({
  users: [],
  // 「会社」コンボボックス表示用データ
  companyNames: {},
  // 「組織」コンボボックス表示用データ
  // ユーザ新規登録/編集画面のフォーム、及び、ユーザ一覧のフィルター項目等、複数箇所で使用するため共有化
  filterOrganizations: {},

  organizations: {},

  filter_company_name: null,
  filter_organization: null,
  filter_user_name: null,
  filter_mail_address: null,

  showForm: false,
});

export const state = initialState;
export const getters = {
  users: (state) => {
    return state.users;
  },
  // 「会社」コンボボックス表示用データの取得
  companyNames: (state) => {
    return state.companyNames;
  },
  // 「組織」コンボボックス表示用データの取得
  filterOrganizations: (state) => {
    return state.filterOrganizations;
  },
  organizations: (state) => {
    return state.organizations;
  },

  filters: (state) => {
    return {
      company_name: state.filter_company_name,
      organization: state.filter_organization,
      user_name: state.filter_user_name,
      mail_address: state.filter_mail_address,
    };
  },
};

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setUsers(state, data) {
    state.users = data;
  },
  setCompanyNames(state, data) {
    state.companyNames = data;
  },
  setFilterOrganizations(state, data) {
    state.filterOrganizations = data;
  },
  setOrganizations(state, data) {
    state.organizations = data;
  },

  setFilterCompanyName(state, data) {
    state.filter_company_name = _.clone(data);
  },
  setFilterOrganization(state, data) {
    state.filter_organization = _.clone(data);
  },
  setFilterUserName(state, data) {
    state.filter_user_name = _.clone(data);
  },
  setFilterMailAddress(state, data) {
    state.filter_mail_address = _.clone(data);
  },

  showForm(state) {
    state.showForm = !state.showForm;
  },
};

export const actions = {
  list (ctx) {
    return new Promise(async (resolve, reject) => {
      const res = await this.$axios.post(process.env.apiBaseUrl + "/users-with-organization", {}, {
        // IEでレスポンスがキャッシュされてしまう問題に対処
      }).catch((err) => {
          console.error(err);
          reject(err);
      });
      ctx.commit('setUsers', res.data);
      resolve();
    });
  },
  // 「会社」コンボボックス表示用データの取得
  listCompanyNames({ commit }) {
    this.$axios.get(process.env.apiBaseUrl + "/company-name-combo-box", {
      //withCredentials: true,
      timeout: 300000,
    }).then(res => {
      commit('setCompanyNames', res.data);
    });
  },
  // 「組織」コンボボックス表示用データの取得
  listFilterOrganizations({ commit }) {
    this.$axios.post(process.env.apiBaseUrl + "/organizations-name-combo-box", {
      //withCredentials: true,
      timeout: 300000,
    }).then(res => {
      commit('setFilterOrganizations', res.data);
    });
  },
  listOrganizations({ commit }) {
    return new Promise(((resolve, reject) => {
      this.$axios.post(process.env.apiBaseUrl + "/organizations-combo-box", {
        //withCredentials: true,
        timeout: 300000,
      }).then(res => {
        commit('setOrganizations', res.data);
        resolve();
      }).catch((err) => {
        reject(err);
      });
    }));
  },

  filterCompanyName({ commit }, data) {
    commit('setFilterCompanyName', data);
  },
  filterOrganization({ commit }, data) {
    commit('setFilterOrganization', data);
  },
  filterUserName({ commit }, data) {
    commit('setFilterUserName', data);
  },
  filterMailAddress({ commit }, data) {
    commit('setFilterMailAddress', data);
  },
  clearFilters({ commit }) {
    commit('setFilterCompanyName', null);
    commit('setFilterOrganization', null);
    commit('setFilterUserName', null);
    commit('setFilterMailAddress', null);
  },

  showForm({ commit }) {
    commit('showForm');
  },

  passwordChange({ commit }, data) {
    return new Promise(((resolve, reject) => {
      this.$axios.put(process.env.apiBaseUrl + "/user/updatePassword/" + data.id, data.payload, {
        timeout: 300000,
      }).then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    }));
  },
};

