<template>
  <div>
    <v-tabs
      v-model="tabs"
      slider-color="orange"
    >
      <v-tab
        v-for="tabDetail in tabDetails"
        :key="tabDetail.id"
      >
        <v-icon>{{ tabDetail.icon }}</v-icon>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-touch:swipe="handleSwipe"
      v-model="tabs"
      :touchless="touchless"
    >
      <v-tab-item
        v-for="tabDetail in tabDetails"
        :key="tabDetail.id"
      >
        <v-card flat>
          <v-card-text>
            <template v-if="tabDetail.icon == 'settings_input_component'">
              <RecorderAux :recorder="recorder" />
              <RecorderRS485 :recorder="recorder" />
            </template>
            <template v-if="tabDetail.icon == 'switch_video'">
              <RecorderPreset :recorder="recorder" />
              <RecorderControl />
            </template>
            <template v-if="tabDetail.icon == 'assessment'">
              <RecorderStatus :recorder="recorder" />
            </template>
            <template v-if="tabDetail.icon == 'schedule'">
              <RecorderLog @swipe="handleLogTabSwipe" />
            </template>
            <template v-if="tabDetail.icon == 'settings'">
              <PositionLock :recorder-id="recorder.id" />
              <RecorderMaintenanceDevInfo />
              <template v-if="p_lte">
                <RecorderMaintenanceSimCard />
              </template>
              <template v-if="!p_lte">
                <RecorderMaintenanceSigfoxModule />
              </template>
              <RecorderMaintenanceContractPlan :recorder="recorder" />
              <RecorderMaintenanceVersion :recorder="recorder" />
              <template v-if="p_lte && !isLTEMailNotificationPlan(recorder.plan)">
                <RecorderMaintenanceReboot />
                <RecorderMaintenanceLTEReflesh />
                <RecorderMaintenanceFarmUp />
              </template>
              <!-- 応答制御コンポーネントの表示 -->
              <template v-if="(hasCustomerAdminPermission || hasSystemAdminPermission) && showResponseControl">
                <RecorderMaintenanceResponseControl :recorder="recorder" />
              </template>
              <template v-if="hasCustomerAdminPermission && !isLTEMailNotificationPlanGR301(recorder.plan)">
                <RecorderMaintenanceWifi />
                <template v-if="p_lte">
                  <RecorderMaintenanceYoutubeSetting />
                  <RecorderMaintenanceVPNSetting :recorder="recorder" />
                </template>
                <RecorderMaintenanceRECSetting />
                <RecorderMaintenanceCameraSetting />
              </template>
            </template>
            <template v-if="tabDetail.icon === 'speaker'">
              <RecorderAudioPlay :recorder="recorder" />
            </template>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
  import RecorderAux from '~/components/recorders/auxiliary.vue';
  import RecorderRS485 from '~/components/recorders/rs485.vue';
  import RecorderPreset from '~/components/recorders/preset.vue';
  import RecorderControl from '~/components/recorders/control.vue';
  import RecorderStatus from '~/components/recorders/status.vue';
  import RecorderLog from '~/components/recorders/log.vue';

  import RecorderMaintenanceDevInfo from '~/components/recorders/deviceInfo.vue';
  import RecorderMaintenanceSimCard from '~/components/recorders/simCard.vue';
  import RecorderMaintenanceSigfoxModule from '~/components/recorders/sigfoxModule.vue';
  import RecorderMaintenanceContractPlan from '~/components/recorders/contractPlan.vue';
  import RecorderMaintenanceVersion from '~/components/recorders/version.vue';
  import RecorderMaintenanceReboot from '~/components/recorders/reboot.vue';
  import RecorderMaintenanceLTEReflesh from '~/components/recorders/LTEReflesh.vue';
  import RecorderMaintenanceFarmUp from '~/components/recorders/farmUp.vue';
  import RecorderMaintenanceResponseControl from '~/components/recorders/responseControl.vue';
  import RecorderMaintenanceWifi from '~/components/recorders/wifi.vue';
  import RecorderMaintenanceYoutubeSetting from '~/components/recorders/youtubeSetting.vue';
  import RecorderMaintenanceVPNSetting from '~/components/recorders/VPNSetting.vue';
  import RecorderMaintenanceRECSetting from '~/components/recorders/RECSetting.vue';
  import RecorderMaintenanceCameraSetting from '~/components/recorders/cameraSetting.vue';

  import PositionLock from '~/components/recorders/positionLockContainer.vue';
  import RecorderAudioPlay from '~/components/recorders/audioPlay';

  export default {
    components: {
      RecorderAudioPlay,
      RecorderAux,
      RecorderRS485,
      RecorderPreset,
      RecorderControl,
      RecorderStatus,
      RecorderLog,
      RecorderMaintenanceDevInfo,
      RecorderMaintenanceSimCard,
      RecorderMaintenanceSigfoxModule,
      RecorderMaintenanceContractPlan,
      RecorderMaintenanceVersion,
      RecorderMaintenanceReboot,
      RecorderMaintenanceLTEReflesh,
      RecorderMaintenanceFarmUp,
      RecorderMaintenanceResponseControl,
      RecorderMaintenanceWifi,
      RecorderMaintenanceYoutubeSetting,
      RecorderMaintenanceVPNSetting,
      RecorderMaintenanceRECSetting,
      RecorderMaintenanceCameraSetting,
      PositionLock,
    },
    props: ['type', 'plan', 'pMap'],
    data() {
      return {
        tabs: null,
        touchless: false,
      }
    },
    computed: {
      isControllable() {
        return this.isLTE(this.type) && !this.isLTEMailNotificationPlan(this.plan);
      },
      p_lte(){
        return this.isLTE(this.type);
      },
      recorder() {
        if (this.$store.state.map) {
          const recorder = this.$store.state.recorders.recorder.data;
          return recorder;
        }
        return this.$store.state.recorders.recorder.data;
      },
      showResponseControl() {
        // BE の control_flag が true（または undefined の場合も true）なら表示
        return this.recorder && this.recorder.control_flag !== false;
      },
      player() {
        if (this.$refs.youtube) {
          return this.$refs.youtube.player;
        }
        return null;
      },
      tabDetails() {
        return this.getTabDetails();
      },
      // タブ内コンテンツの高さ調整はMAPの時のみ行う。
      // また、LTEレコーダーか否かでYoutubeビュー表示有無が変わるため、クラスも切り替える。
      classTabContent() {
        if (!this.p_map) {
          return '';
        }
        if (this.isLTE(this.type) && !this.isLTEMailNotificationPlan(this.plan)) {
          return 'tab-content'
        } else {
          return 'tab-content-sigfox'
        }
      },
      showResponseControl() {
        // BEのcontrol_flagがtrueの場合のみ表示する
        return this.recorder && this.recorder.control_flag !== false;
      },
    },
    methods: {
      handleLogTabSwipe(direction) {
        this.moveTabTo(direction);
      },
      handleSwipe(direction) {
        const logTabIndex = 3;
        // 「ログ」タブはcomponents/log.vue側にスワイプのハンドラーを書いてあるので、こちらでは何もしない 。
        if (this.tabs === logTabIndex) {
          return;
        }
        this.moveTabTo(direction);
      },
      moveTabTo(direction) {
        if (direction === 'right') {
          if (this.tabs >= 0) {
            this.tabs--;
          }
        }
        if (direction === 'left') {
          if (this.tabs < this.tabDetails.length - 1) {
            this.tabs++;
          }
        }
      },
      getTabDetails() {
        this.tabs = 0;
        const icons = [];
        if (this.isLTE(this.type) && !this.isLTEMailNotificationPlan(this.plan)) {
          icons.push('settings_input_component');
        }
        if (this.isLTE(this.type) && !this.isLTEMailNotificationPlan(this.plan)) {
          icons.push('switch_video');
        }
        if (this.isLTE(this.type) && !this.isLTEMailNotificationPlan(this.plan)) {
          icons.push('speaker');
        }
        icons.push('assessment');
        icons.push('schedule');
        icons.push('settings');

        const tabDetails = [];
        icons.forEach(function (icon, idx) {
          tabDetails.push({
            id: idx,
            icon: icon
          });
        });
        return tabDetails;
      },
    },
  }
</script>
<style lang="scss" scoped>
  $video-height: 360px;
  $toolbar-height: 8vh;
  $button-container-height: 9vh;
  $tab-icon-height: 48px;
  $header-height: 60px;
  $player-changer-height: 52px;

  .tab-content {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(
      100vh -
      #{$video-height} -
      #{$toolbar-height} -
      #{$button-container-height} -
      #{$tab-icon-height} -
      #{$header-height} +
      #{$player-changer-height}
    );
  }

  .tab-content-sigfox {
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(
      100vh -
      #{$toolbar-height} -
      #{$button-container-height} -
      #{$tab-icon-height} -
      #{$header-height} +
      #{$player-changer-height}
    );
  }
</style>
