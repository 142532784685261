<template>
  <v-app>
    <v-toolbar
      clipped-left
      clipped-right
      fixed
      app
      height="60"
      class="black"
    >
      <v-layout
        row
        justify-start
      >
        <v-flex>
          <img
            v-if="isLoginPage"
            class="toalogo"
            src="../static/toa-logo.png"
            alt="toa-logo"
          >
          <img
            v-else
            @click="openInitialView"
            class="toalogo -clickable"
            src="../static/toa-logo.png"
            alt="toa-logo"
          >
        </v-flex>
      </v-layout>
      <v-spacer />
      <v-toolbar-items v-if="!isLoginPage">
        <v-btn
          :class="getButtonClassByCurrentPath('/maps')"
          @click="$customRouter.push('/maps')"
          class="grey--text text--lighten-2"
          flat
        >
          地図
        </v-btn>
        <v-btn
          :class="getButtonClassByCurrentPath('/view')"
          @click="$customRouter.push('/view')"
          class="grey--text text--lighten-2 hidden-sm-and-down"
          flat
        >
          ライブ
        </v-btn>
        <v-btn
          :class="getButtonClassByCurrentPath('/recorders')"
          @click="$customRouter.push('/recorders')"
          class="grey--text text--lighten-2 hidden-sm-and-down"
          flat
        >
          機器状態
        </v-btn>
        <v-btn
          :class="getButtonClassByCurrentPath('/streaming')"
          @click="$customRouter.push('/streaming')"
          class="grey--text text--lighten-2 hidden-sm-and-down"
          flat
        >
          遠隔放送
        </v-btn>
        <v-btn
          @click="$customRouter.push('/notice')"
          class="grey--text text--lighten-2"
          icon
          flat
        >
          <v-icon>notifications</v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-menu
        v-if="!isLoginPage"
        offset-y
        left
      >
        <v-btn
          slot="activator"
          class="grey--text text--lighten-2 hidden-sm-and-down"
          icon
          flat
        >
          <v-icon>settings</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile
            v-for="(item, index) in getSettings"
            :key="index"
            @click="$customRouter.push(item.link)"
          >
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
      <v-menu
        v-if="!isLoginPage"
        offset-y
        left
      >
        <v-btn
          slot="activator"
          dark
          icon
        >
          <v-icon>person</v-icon>
        </v-btn>
        <v-list>
          <v-list-tile
            @click="$customRouter.push('/patterns')"
            class="hidden-sm-and-down"
          >
            <v-list-tile-title>
              パターン登録
            </v-list-tile-title>
          </v-list-tile>
          <v-divider />
          <v-list-tile
            @click="$customRouter.push('/operation_history')"
            class="hidden-sm-and-down"
          >
            <v-list-tile-title>
              操作履歴
            </v-list-tile-title>
          </v-list-tile>
          <v-divider />
          <v-list-tile @click="logout()">
            <v-list-tile-title>
              ログアウト
            </v-list-tile-title>
          </v-list-tile>
        </v-list>
      </v-menu>
    </v-toolbar>
    <v-content>
      <template v-if="!isIE()">
        <Map v-show="isMap" />
      </template>
      <nuxt />
      <FullScreenSpinner :value="loading" />
      <Notification />
      <AlertBox ref="alertbox" />
    </v-content>
  </v-app>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/auth';
import { v4 as uuid } from 'uuid';
import FullScreenSpinner from '~/components/fullScreenSpinner.vue';
import Notification from '~/components/notification.vue';
import AlertBox from '~/components/alertbox.vue';
import Map from '~/components/maps/container.vue';
import deviceStatusMixin from '@/components/mixins/deviceStatus';

const sessionId = (() => {
  let sessionId = sessionStorage.getItem("sessionId");
  if (!sessionId) {
    sessionId = uuid();
    sessionStorage.setItem("sessionId", sessionId);
  }
  return sessionId;
})();

export default {
  components: {
    Map,
    FullScreenSpinner,
    Notification,
    AlertBox
  },
  mixins: [ deviceStatusMixin ],
  data() {
    return {
      title: 'タウンレコーダー遠隔見守りサービス',
      settings: [], // 設定メニュー
      drawer: null,
      timer: {},
      pingTimer: {},
    }
  },
  computed: {
    loading() {
      return this.$store.getters.isLoading;
    },
    getSettings() {
      this.setSettings();
      return this.settings;
    },
    isMap() {
      return this.$route.path === '/maps';
    },
    isLoginPage() {
      return this.$route.path === '/login';
    },
  },

  /**
   * Vueの初期表示で常に実行される。
   * IE11の場合は画面遷移の度に実行される。
   * @return {Promise<void>}
   */
  async mounted() {
    window.addEventListener("unhandledrejection", function (event) {
      console.warn("WARNING: Unhandled promise rejection. Reason: " + event.reason, event);
    });
    document.querySelector('body').style.overflowY = 'scroll';
    this.$store.dispatch('setLoadingState', false);
    this.setSettings();
    this.$root.$confirm = this.$refs.alertbox.confirm;
    console.log(this.$route);
    this.startPing();

    // firebase auth のログイン状態の変化に対するハンドラ
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!process.env.useAuth) {
        await this.$store.dispatch("startPing"); //ping開始
        if (this.$route.path === '/') {
          this.$customRouter.push('/maps');
        }
        return;
      }

      if (user) {
        // ★ログイン状態への変更を検知！★
        console.log("##### Logging in #####");

        // pingの有効化。ログイン画面の場合はpingを実施しない。
        if (this.$route.path !== '/login') {
          await this.$store.dispatch("startPing"); // ping開始
        }

        await user.getIdToken(/* forceRefresh */ true)
        .catch((error) => {
          console.log(error);
        });

        // "/" への接続時はmaps画面に遷移させる。
        if (this.$route.path === '/') {
          this.$customRouter.push('/maps');
        }
      } else {
        // ★ログアウト状態への変更を検知！★
        console.log("##### Logging out #####");
        await this.$store.dispatch('auth/setUserInfo', null);
        await this.$store.dispatch('setLoadingState', false);
        await this.$store.dispatch("stopPing"); // ping停止
        this.$customRouter.push('/login');
      }
    });

    if (this.isIE()) {
      if (window.CollectGarbage) {
        window.CollectGarbage();
        setTimeout(() => {
          window.CollectGarbage();
        }, 1000/* ms */ * 30/* sec */);
      }

      // 脆弱性対応#1643:deviceId > recorderIdに修正のため
      // const currentRecorderDeviceId = localStorage.getItem('currentRecorderDeviceId');
      const currentRecorderId = localStorage.getItem('currentRecorderId')
      await this.changeRecState(currentRecorderId, false);
      localStorage.removeItem('currentRecorderId');
      localStorage.removeItem('currentUserId');
    }

    await this.ping();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.pingTimer);
  },
  methods: {
    startPing() {
      this.pingTimer = setInterval(async () => {
        await this.ping();
      }, 1000 * 10);
    },

    async ping() {
      try {
        const pingStatus = this.$store.getters['ping'];
        if (!pingStatus) {
          console.log("ping status false. don't send ping.");
          return;
        }
        const resp = await this.$axios.get(
          process.env.apiBaseUrl + "/ping?session-id=" + sessionId
        );
        if (resp && resp.data === true) {
          await this.$store.dispatch("stopPing"); // ping停止
          await this.clearSession(); // このセッションを削除する。
          window.alert("同時にログインできる数が上限に達したため、ログアウトします。しばらくお待ちいただいた後、再度ログインしてください");
          const email = this.$store.getters['auth/getLoginUserMail'];
          await this.operationHistory(email, '3'); // ログアウト
          this.$customRouter.push('/login');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async clearSession() {
      try {
        await this.$axios.delete(
          process.env.apiBaseUrl + '/sessions/' + sessionId
        );
        console.info('session is cleared successfully.');
      } catch (e) {
        console.warn('clearing session is failed.');
        console.error(e);
      }
    },

    /**
     * ログアウト。Firebase auth からもログアウトする。
     * @return {Promise<void>}
     */
    async logout() {
      const email = this.$store.getters['auth/getLoginUserMail'];
      if (!process.env.useAuth) {
        await this.$store.dispatch('stopPing'); // ping停止
        await this.operationHistory(email, '3'); // ログアウトログ書き込み
        await this.clearSession(); // Activeセッションの削除
        localStorage.removeItem('local-email');
        this.$customRouter.push('/login');
        return;
      }
      try {
        await this.$store.dispatch("stopPing"); // ping停止
        await this.operationHistory(email, '3'); // ログアウトログ書き込み
        await this.clearSession(); // Activeセッションの削除
        await firebase.auth().signOut();
        this.resetAllState();
        this.$customRouter.push('/login');
      } catch (e) {
        await this.operationHistory(email, '4');
        console.log('errorCode:' + e.code + ', errorMessage:' + e.message);
      }
    },
    async operationHistory(email, type) {
      return new Promise((resolve, reject) => {
        const payload = {
          'mail_address': email,
          'operation_type': type,
        };
        this.$axios.post(process.env.apiBaseUrl + '/operation_history/loginlogout', payload, {
          timeout: 300000,
        }).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        })
      });
    },

    openInitialView() {
      if (this.$route.path === '/maps') {
        this.$store.commit('clickToaLogo');
      }
      this.$customRouter.push('/maps');
    },
    /**
     * ログインユーザの権限を確認し、メニューを制御する
     */
    setSettings() {
      this.settings = [];
      if (this.$store.getters['auth/hasCustomerAdminPermission']) {
        // 顧客管理者権限以上のケース
        this.settings.push({ title: '組織管理', link: '/admin/organizations' });
        this.settings.push({ title: 'ユーザー管理', link: '/admin/users' });
        this.settings.push({ title: 'レコーダー管理', link: '/admin/recorders' });
        this.settings.push({ title: '放送グループ管理', link: '/admin/broadcastgroups' });
        this.settings.push({ title: 'SIMデータ管理グループ管理', link: '/admin/simdatamanagementgroups' });
      } else if (this.$store.getters['auth/hasUserAdminPermission']) {
        // ユーザー管理者権限以上のケース
        this.settings.push({ title: 'ユーザー管理', link: '/admin/users' });
        this.settings.push({ title: 'レコーダー管理', link: '/admin/recorders' });
        this.settings.push({ title: '放送グループ管理', link: '/admin/broadcastgroups' });
        this.settings.push({ title: 'SIMデータ管理グループ管理', link: '/admin/simdatamanagementgroups' });
      } else if (this.$store.getters['auth/hasGeneralUserPermission']) {
        // 一般ユーザー権限は「ユーザー管理」のみ参照可
        this.settings.push({ title: 'ユーザー管理', link: '/admin/users' });
      }
    },
    getButtonClassByCurrentPath(path) {
      return {
        '-active': this.$route.path === path
      };
    }
  }
}
</script>
<style lang="scss">
  .v-btn {
    &.-active {
      border-bottom: solid 3px #ff7c00;
    }
  }
  .toalogo {
    &.-clickable {
      cursor: pointer;
    }
  }
  .conceal {
    font-family: 'text-security-disc';
  }
</style>
