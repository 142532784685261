<template>
  <v-progress-linear
    :value="value"
    :height="height"
    v-if="usage && capacity"
    class="radius"
    color="#71D0FF"
    background-color="#E6E6E6"
  >
    <v-container fill-height class="inside-box">
      <v-flex
        v-if="!disableInlineText"
        class="slot-val"
      >
        <strong>{{ usage }}</strong>／{{ capacity }}
        <span v-if="isShare">（シェア）</span>
      </v-flex>
    </v-container>
  </v-progress-linear>
</template>

<script>
  export default {
    name: 'DataPercentageBar',
    props: {
        usage: {
          type: [Number,String],
          default: 0
        },
        capacity: {
          type: [Number,String],
          default: 0
        },
        height: {
          type: Number,
          default: 30
        },
        isShare: {
          type: [Boolean,String],
          default: false
        },
        disableInlineText: {
          type: [Boolean],
          default: false
        }
    },
    computed: {
      value() {
        if(!isNaN(this.usage) && !isNaN(this.capacity)){
          return parseFloat(this.capacity) === 0
            ? 0
            : parseFloat(this.usage)/parseFloat(this.capacity)*100
        }
        return 0
      },
    },
  }
</script>

<style scoped lang="scss">
  .slot-val {
    text-align: center;
    font-size: 1.5em;
  }
  .inside-box {
    padding: 0;
  }
  .radius {
    border-radius: 4px;
  }
</style>