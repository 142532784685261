export default async function ({ $axios, store, route, redirect, app }) {
  console.log("auth.js", route.name);
  app.store.commit('setPath', route.path);

  if (
    route.name === 'login' ||
    route.name === 'index'
      ) {
    return;
  }

  if (process.env.useAuth) {
    await $axios.get(process.env.apiBaseUrl + "/user-info", {timeout: 300000}).then(res => {
      store.dispatch('auth/setUserInfo', res.data);
    });
  } else {
    const email = localStorage.getItem("local-email");
    await $axios.get(process.env.apiBaseUrl + "/user-info-local-dummy/" + email, {timeout: 300000}).then(res => {
      store.dispatch('auth/setUserInfo', res.data);
    });
  }

  // 仮にWebAPIを１つもコールしないページであっても
  // 認証が必要なページでは認証チェックをサーバ側で行う必要があるため
  // ログイン画面表示のルーティング以外では認証チェック用のAPIを必ずコール
  //
  await $axios.head(process.env.apiBaseUrl + "/auth", {timeout: 300000}).catch(error => {
    console.log(error);
    // 実際にWebAPIからステータス401が返却された場合は、plugins/axios.js で定義しておいた
    // 「$axios.onResponseError()」が発動して、ログイン画面にリダイレクトされるため、ここでは処置なし。
    return;
  });
}
