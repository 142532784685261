<template>
  <v-container>
    <p class="title">カメラ制御</p>
    <v-layout
      justify-center
      wrap
      align-space-between>
      <v-flex xs8>
        <v-slider
          label="速度"
          v-model="speed"
          :tick-labels="ticksLabels"
          color="grey lighten-2"
          :max="4"
          step="1"
          ticks="always"
          tick-size="1"
          :disabled="!this.hasPtzOperation(this.recorder.organization_id)"></v-slider>
      </v-flex>
      <v-flex xs2></v-flex>
      <v-flex xs5></v-flex>
      <v-flex xs2>
        <v-btn icon :class="controlColor" @click="camctrl('up')" :disabled="!this.hasPtzOperation(this.recorder.organization_id)">
          <v-icon>arrow_upward</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs5></v-flex>

      <v-flex xs3>
      </v-flex>
      <v-flex xs2>
        <v-btn icon :class="controlColor" @click="camctrl('left')" :disabled="!this.hasPtzOperation(this.recorder.organization_id)">
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs2>
        <v-btn icon :class="controlColor" @click="camctrl('af')" :disabled="!this.hasPtzOperation(this.recorder.organization_id)">
          AF
        </v-btn>
      </v-flex>
      <v-flex xs2>
        <v-btn icon :class="controlColor" @click="camctrl('right')" :disabled="!this.hasPtzOperation(this.recorder.organization_id)">
          <v-icon>arrow_forward</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs3>
      </v-flex>

      <v-flex xs5></v-flex>
      <v-flex xs2>
        <v-btn icon :class="controlColor" @click="camctrl('down')" :disabled="!this.hasPtzOperation(this.recorder.organization_id)">
          <v-icon>arrow_downward</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs5></v-flex>

      <v-flex xs3>
      </v-flex>
      <v-flex xs2>
        <v-btn icon :class="controlColor" @click="camctrl('tele')" :disabled="!this.hasPtzOperation(this.recorder.organization_id)">
          <v-icon>add</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs2>
      </v-flex>
      <v-flex xs2>
        <v-btn icon :class="controlColor" @click="camctrl('wide')" :disabled="!this.hasPtzOperation(this.recorder.organization_id)">
          <v-icon>remove</v-icon>
        </v-btn>
      </v-flex>
      <v-flex xs3>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import * as types from "~/store/types";
export default {
  data() {
    return {
      selected: 0,
      name: "",
      controlColor: "grey lighten-2",
      speed: 0,
      ticksLabels: [ '1', '2', '3', '4', '5' ]
    }
  },
  computed: {
    items() {
      const preset = this.$store.state.recorders.recorder.preset;
      return _.map(this.$store.state.recorders.recorder.preset, i => {
        if(i.Name) {
          return {text: i.NO + ": " + i.Name, value: i.NO };
        } else {
          return {text: i.NO, value: i.NO };
        }
      });
    },
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
  },
  mounted() {
  },
  methods: {
    saveRecorderPreset() {
      // 操作権限判定
      if (!this.hasPtzOperation(this.recorder.organization_id)) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '操作権限がありません。'});
        return;
      }

      this.$store.dispatch('recorders/createPreset', {
        recorder_id: this.$store.state.recorders.recorder.data.id,
        no: this.selected,
        name: this.name,
      });
    },
    camctrl(action) {
      // 操作権限判定
      if (!this.hasPtzOperation(this.recorder.organization_id)) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '操作権限がありません。'});
        return;
      }

      // forwarding.cgi
      let url = "http://" + this.recorder.vpn.virtualip + '/forwarding.cgi';
      //let url = "http://" + this.recorder.vpn.url + '/camctrl.cgi?';
      let data = "GET\\r\\n/camctrl.cgi?"
      if(action === 'up') {
        data += 'tilt=up'
      } else if(action === 'down') {
        data += 'tilt=down'
      } else if(action === 'left') {
        data += 'pan=left'
      } else if(action === 'right') {
        data += 'pan=right'
      } else if(action === 'af') {
        data += 'focus=auto'
      } else if(action === 'tele') {
        data += 'zoom=tele'
      } else if(action === 'wide') {
        data += 'zoom=wide'
      }
      const speeds = ['veryslow', 'slow', 'middle', 'fast', 'veryfast',];
      data += "&speed=" + speeds[this.speed] + "&\\r\\n\\r\\n";
      this.$axios.post(process.env.cgiBaseUrl, {
        recorderID: this.recorder.id,
        method: 'POST',
        url: '/forwarding.cgi',
        data: data,
      }, {
        timeout: 300000,
      }).then(data => {
        console.log(data)
      }).catch(errorCode => {
        console.log(errorCode)
        if(errorCode.response.status == 403) return;
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '転送要求に失敗しました。'});
      }, data);
    },
    reboot() {
      // 操作権限判定(再起動：メンテナンス操作権限)
      if (!this.hasMaintenanceOperation(this.recorder.organization_id)) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '操作権限がありません。'});
        return;
      }

      this.$axios.post(process.env.cgiBaseUrl, {
        recorderID: this.recorder.id,
        method: 'GET',
        url: '/reboot.cgi',
        data: 'mode=reboot',
      }, {
        timeout: 300000,
      }).then(data => {
        console.log(data)
      }).catch(errorCode => {
        console.log(errorCode)
        if(errorCode.response.status == 403) return;
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '機器再起動に失敗しました。'});
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
