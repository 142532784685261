const initialState = () => ({
  // 一覧画面においてフィルタリングした結果を保持
  // フィルタ部品があるコンポーネントやフィルタ結果を反映するコンポーネント等、複数コンポーネントで共有する情報のため store に保持
  filters: {},
});

export const state = initialState;

// MEMO: ミューテーションは、ストアの状態を変更する。
export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setFilters(state, data) {
    state.filters = _.clone(data);
  },
};

// MEMO: アクションは、ミューテーションをコミットする。
// MEMO: アクションは、 store.dispatch がトリガーとなって実行される。
export const actions = {
  // 一覧画面で「操作履歴」を絞り込むためにフィルタを変更する。(dispatch from filter.vue)
  filter({ commit }, data) {
    commit('setFilters', data);
  },
};

// MEMO: ゲッターは、ストア内の算出プロパティ
export const getters = {
  filters: (state) => {
    return state.filters;
  },
};
