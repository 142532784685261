export interface Router {
  push(path: string): void;
}

class VueRouter implements Router {
  private router;
  public constructor(router) {
    this.router = router;
  }

  public push(path): void {
    this.router.push(path);
  }
}

class IERouter implements Router {
  public push(path: string): void {
    if(path === '/login' && window.location.href.indexOf('#' + path) !== -1) {
      console.log('move to /login to /login will be ignored.')
      return;
    }
    window.location.href = '#' + path;
    window.location.reload(true); // キャッシュではなくサーバからデータを取得しなおす
  }
}

export default ({ app }, inject): void => {
  let router: Router;
  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIE = userAgent.indexOf('msie') !== -1 || userAgent.indexOf('trident') !== -1;

  if (isIE) {
    router = new IERouter();
  } else {
    router = new VueRouter(app.router);
  }
  inject('customRouter', router);
}
