// https://github.com/inorganik/digest-auth-request
// https://github.com/blueimp/JavaScript-MD5

import Vue from 'vue'

function safeAdd (x, y) {
  var lsw = (x & 0xffff) + (y & 0xffff)
  var msw = (x >> 16) + (y >> 16) + (lsw >> 16)
  return (msw << 16) | (lsw & 0xffff)
}

function bitRotateLeft (num, cnt) {
  return (num << cnt) | (num >>> (32 - cnt))
}

function md5cmn (q, a, b, x, s, t) {
  return safeAdd(bitRotateLeft(safeAdd(safeAdd(a, q), safeAdd(x, t)), s), b)
}
function md5ff (a, b, c, d, x, s, t) {
  return md5cmn((b & c) | (~b & d), a, b, x, s, t)
}
function md5gg (a, b, c, d, x, s, t) {
  return md5cmn((b & d) | (c & ~d), a, b, x, s, t)
}
function md5hh (a, b, c, d, x, s, t) {
  return md5cmn(b ^ c ^ d, a, b, x, s, t)
}
function md5ii (a, b, c, d, x, s, t) {
  return md5cmn(c ^ (b | ~d), a, b, x, s, t)
}

function binlMD5 (x, len) {
  /* append padding */
  x[len >> 5] |= 0x80 << (len % 32)
  x[((len + 64) >>> 9 << 4) + 14] = len

  var i
  var olda
  var oldb
  var oldc
  var oldd
  var a = 1732584193
  var b = -271733879
  var c = -1732584194
  var d = 271733878

  for (i = 0; i < x.length; i += 16) {
    olda = a
    oldb = b
    oldc = c
    oldd = d

    a = md5ff(a, b, c, d, x[i], 7, -680876936)
    d = md5ff(d, a, b, c, x[i + 1], 12, -389564586)
    c = md5ff(c, d, a, b, x[i + 2], 17, 606105819)
    b = md5ff(b, c, d, a, x[i + 3], 22, -1044525330)
    a = md5ff(a, b, c, d, x[i + 4], 7, -176418897)
    d = md5ff(d, a, b, c, x[i + 5], 12, 1200080426)
    c = md5ff(c, d, a, b, x[i + 6], 17, -1473231341)
    b = md5ff(b, c, d, a, x[i + 7], 22, -45705983)
    a = md5ff(a, b, c, d, x[i + 8], 7, 1770035416)
    d = md5ff(d, a, b, c, x[i + 9], 12, -1958414417)
    c = md5ff(c, d, a, b, x[i + 10], 17, -42063)
    b = md5ff(b, c, d, a, x[i + 11], 22, -1990404162)
    a = md5ff(a, b, c, d, x[i + 12], 7, 1804603682)
    d = md5ff(d, a, b, c, x[i + 13], 12, -40341101)
    c = md5ff(c, d, a, b, x[i + 14], 17, -1502002290)
    b = md5ff(b, c, d, a, x[i + 15], 22, 1236535329)

    a = md5gg(a, b, c, d, x[i + 1], 5, -165796510)
    d = md5gg(d, a, b, c, x[i + 6], 9, -1069501632)
    c = md5gg(c, d, a, b, x[i + 11], 14, 643717713)
    b = md5gg(b, c, d, a, x[i], 20, -373897302)
    a = md5gg(a, b, c, d, x[i + 5], 5, -701558691)
    d = md5gg(d, a, b, c, x[i + 10], 9, 38016083)
    c = md5gg(c, d, a, b, x[i + 15], 14, -660478335)
    b = md5gg(b, c, d, a, x[i + 4], 20, -405537848)
    a = md5gg(a, b, c, d, x[i + 9], 5, 568446438)
    d = md5gg(d, a, b, c, x[i + 14], 9, -1019803690)
    c = md5gg(c, d, a, b, x[i + 3], 14, -187363961)
    b = md5gg(b, c, d, a, x[i + 8], 20, 1163531501)
    a = md5gg(a, b, c, d, x[i + 13], 5, -1444681467)
    d = md5gg(d, a, b, c, x[i + 2], 9, -51403784)
    c = md5gg(c, d, a, b, x[i + 7], 14, 1735328473)
    b = md5gg(b, c, d, a, x[i + 12], 20, -1926607734)

    a = md5hh(a, b, c, d, x[i + 5], 4, -378558)
    d = md5hh(d, a, b, c, x[i + 8], 11, -2022574463)
    c = md5hh(c, d, a, b, x[i + 11], 16, 1839030562)
    b = md5hh(b, c, d, a, x[i + 14], 23, -35309556)
    a = md5hh(a, b, c, d, x[i + 1], 4, -1530992060)
    d = md5hh(d, a, b, c, x[i + 4], 11, 1272893353)
    c = md5hh(c, d, a, b, x[i + 7], 16, -155497632)
    b = md5hh(b, c, d, a, x[i + 10], 23, -1094730640)
    a = md5hh(a, b, c, d, x[i + 13], 4, 681279174)
    d = md5hh(d, a, b, c, x[i], 11, -358537222)
    c = md5hh(c, d, a, b, x[i + 3], 16, -722521979)
    b = md5hh(b, c, d, a, x[i + 6], 23, 76029189)
    a = md5hh(a, b, c, d, x[i + 9], 4, -640364487)
    d = md5hh(d, a, b, c, x[i + 12], 11, -421815835)
    c = md5hh(c, d, a, b, x[i + 15], 16, 530742520)
    b = md5hh(b, c, d, a, x[i + 2], 23, -995338651)

    a = md5ii(a, b, c, d, x[i], 6, -198630844)
    d = md5ii(d, a, b, c, x[i + 7], 10, 1126891415)
    c = md5ii(c, d, a, b, x[i + 14], 15, -1416354905)
    b = md5ii(b, c, d, a, x[i + 5], 21, -57434055)
    a = md5ii(a, b, c, d, x[i + 12], 6, 1700485571)
    d = md5ii(d, a, b, c, x[i + 3], 10, -1894986606)
    c = md5ii(c, d, a, b, x[i + 10], 15, -1051523)
    b = md5ii(b, c, d, a, x[i + 1], 21, -2054922799)
    a = md5ii(a, b, c, d, x[i + 8], 6, 1873313359)
    d = md5ii(d, a, b, c, x[i + 15], 10, -30611744)
    c = md5ii(c, d, a, b, x[i + 6], 15, -1560198380)
    b = md5ii(b, c, d, a, x[i + 13], 21, 1309151649)
    a = md5ii(a, b, c, d, x[i + 4], 6, -145523070)
    d = md5ii(d, a, b, c, x[i + 11], 10, -1120210379)
    c = md5ii(c, d, a, b, x[i + 2], 15, 718787259)
    b = md5ii(b, c, d, a, x[i + 9], 21, -343485551)

    a = safeAdd(a, olda)
    b = safeAdd(b, oldb)
    c = safeAdd(c, oldc)
    d = safeAdd(d, oldd)
  }
  return [a, b, c, d]
}

function binl2rstr (input) {
  var i
  var output = ''
  var length32 = input.length * 32
  for (i = 0; i < length32; i += 8) {
    output += String.fromCharCode((input[i >> 5] >>> (i % 32)) & 0xff)
  }
  return output
}

function rstr2binl (input) {
  var i
  var output = []
  output[(input.length >> 2) - 1] = undefined
  for (i = 0; i < output.length; i += 1) {
    output[i] = 0
  }
  var length8 = input.length * 8
  for (i = 0; i < length8; i += 8) {
    output[i >> 5] |= (input.charCodeAt(i / 8) & 0xff) << (i % 32)
  }
  return output
}

function rstrMD5 (s) {
  return binl2rstr(binlMD5(rstr2binl(s), s.length * 8))
}

function rstrHMACMD5 (key, data) {
  var i
  var bkey = rstr2binl(key)
  var ipad = []
  var opad = []
  var hash
  ipad[15] = opad[15] = undefined
  if (bkey.length > 16) {
    bkey = binlMD5(bkey, key.length * 8)
  }
  for (i = 0; i < 16; i += 1) {
    ipad[i] = bkey[i] ^ 0x36363636
    opad[i] = bkey[i] ^ 0x5c5c5c5c
  }
  hash = binlMD5(ipad.concat(rstr2binl(data)), 512 + data.length * 8)
  return binl2rstr(binlMD5(opad.concat(hash), 512 + 128))
}

function rstr2hex (input) {
  var hexTab = '0123456789abcdef'
  var output = ''
  var x
  var i
  for (i = 0; i < input.length; i += 1) {
    x = input.charCodeAt(i)
    output += hexTab.charAt((x >>> 4) & 0x0f) + hexTab.charAt(x & 0x0f)
  }
  return output
}

function str2rstrUTF8 (input) {
  return unescape(encodeURIComponent(input))
}

function rawMD5 (s) {
  return rstrMD5(str2rstrUTF8(s))
}
function hexMD5 (s) {
  return rstr2hex(rawMD5(s))
}
function rawHMACMD5 (k, d) {
  return rstrHMACMD5(str2rstrUTF8(k), str2rstrUTF8(d))
}
function hexHMACMD5 (k, d) {
  return rstr2hex(rawHMACMD5(k, d))
}

function md5 (string, key, raw) {
  if (!key) {
    if (!raw) {
      return hexMD5(string)
    }
    return rawMD5(string)
  }
  if (!raw) {
    return hexHMACMD5(key, string)
  }
  return rawHMACMD5(key, string)
}

Vue.prototype.$cgi = function (method, url, username, password) {
  var self = this;
  username = "townrec_cloud";
  password = "tOwnrEcOdEr6809";

  this.scheme = null;
  this.nonce = null;
  this.realm = null;
  this.qop = null;
  this.response = null;
  this.opaque = null;
  this.nc = 1;
  this.cnonce = null;

  this.timeout = 10000;
  this.loggingOn = false;

  this.post = false;
  if (method.toLowerCase() === 'post' || method.toLowerCase() === 'put') {
    this.post = true;
  }

  this.request = function(successFn, errorFn, data) {
    if (data) {
      self.data = data;
    }
    self.successFn = successFn;
    self.errorFn = errorFn;

    if (!self.nonce) {
      self.makeUnauthenticatedRequest(self.data);
    } else {
      self.makeAuthenticatedRequest();
    }
  }
  this.makeUnauthenticatedRequest = function(data) {
    self.firstRequest = new XMLHttpRequest();
    self.firstRequest.open(method, url, true);
    self.firstRequest.timeout = self.timeout;
    self.firstRequest.setRequestHeader('X-TOA', 'TOA');
    if (self.post) {
      self.firstRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    }

    self.firstRequest.onreadystatechange = function() {
      if (self.firstRequest.readyState === 2) {
        var responseHeaders = self.firstRequest.getAllResponseHeaders();
        responseHeaders = responseHeaders.split('\n');
        var digestHeaders;
        for(var i = 0; i < responseHeaders.length; i++) {
          if (responseHeaders[i].match(/www-authenticate/i) != null) {
            digestHeaders = responseHeaders[i];
          }
        }
        if (digestHeaders != null) {
          digestHeaders = digestHeaders.slice(digestHeaders.indexOf(':') + 1, -1);
          digestHeaders = digestHeaders.split(',');
          self.scheme = digestHeaders[0].split(/\s/)[1];
          for (var i = 0; i < digestHeaders.length; i++) {
            var equalIndex = digestHeaders[i].indexOf('='),
              key = digestHeaders[i].substring(0, equalIndex),
              val = digestHeaders[i].substring(equalIndex + 1);
            val = val.replace(/['"]+/g, '');
            if (key.match(/realm/i) != null) {
              self.realm = val;
            }
            if (key.match(/nonce/i) != null) {
              self.nonce = val;
            }
            if (key.match(/opaque/i) != null) {
              self.opaque = val;
            }
            if (key.match(/qop/i) != null) {
              self.qop = val;
            }
          }
          self.cnonce = self.generateCnonce();
          self.nc++;
          self.log('received headers:');
          self.log('	realm: '+self.realm);
          self.log('	nonce: '+self.nonce);
          self.log('	opaque: '+self.opaque);
          self.log('	qop: '+self.qop);
          self.makeAuthenticatedRequest();
        }
      }
      if (self.firstRequest.readyState === 4) {
        if (self.firstRequest.status === 200) {
          self.log('Authentication not required for '+url);
          if (self.firstRequest.responseText !== 'undefined') {
            if (self.firstRequest.responseText.length > 0) {
              self.successFn(self.firstRequest.responseText);
            }
          } else {
            self.successFn();
          }
        }
      }
    }
    if (self.post) {
      self.firstRequest.send(self.data);
    } else {
      self.firstRequest.send();
    }
    self.log('Unauthenticated request to '+url);

    self.firstRequest.onerror = function() {
      if (self.firstRequest.status !== 401) {
        self.log('Error ('+self.firstRequest.status+') on unauthenticated request to '+url);
        self.errorFn(self.firstRequest.status);
      }
    }
  }
  this.makeAuthenticatedRequest= function() {

    self.response = self.formulateResponse();
    self.authenticatedRequest = new XMLHttpRequest();
    self.authenticatedRequest.open(method, url, true);
    self.authenticatedRequest.timeout = self.timeout;
    var parser = document.createElement('a');
    parser.href = url;
    var uri = parser.pathname + parser.search + parser.hash;

    var digestAuthHeader = self.scheme+' '+
      'username="'+username+'", '+
      'realm="'+self.realm+'", '+
      'nonce="'+self.nonce+'", '+
      'uri="'+uri+'", '+
      'response="'+self.response + '"';
    self.authenticatedRequest.setRequestHeader('Authorization', digestAuthHeader);
    self.log('digest auth header response to be sent:');
    self.log(digestAuthHeader);
    if (self.post) {
      self.authenticatedRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    }
    self.authenticatedRequest.onload = function() {
      if (self.authenticatedRequest.status >= 200 && self.authenticatedRequest.status < 400) {
        self.nc++;
        if (self.authenticatedRequest.responseText !== 'undefined' && self.authenticatedRequest.responseText.length > 0 ) {
          self.successFn(self.authenticatedRequest.responseText);
        } else {
          self.successFn();
        }
      }
      else {
        self.nonce = null;
        self.errorFn(self.authenticatedRequest.status);
      }
    }
    self.authenticatedRequest.onerror = function() {
      self.log('Error ('+self.authenticatedRequest.status+') on authenticated request to '+url);
      self.nonce = null;
      self.errorFn(self.authenticatedRequest.status);
    };
    if (self.post) {
      self.authenticatedRequest.send(self.data);
    } else {
      self.authenticatedRequest.send();
    }
    self.log('Authenticated request to '+url);
  }
  this.formulateResponse = function() {
    var HA1 = md5(username+':'+self.realm+':'+password).toString();
    var parser = document.createElement('a');
    parser.href = url;
    var uri = parser.pathname + parser.search + parser.hash;
    var HA2 = md5(method+':'+uri).toString();
    var response = md5(HA1+':'+ self.nonce + ':' + HA2).toString();
    return response;
  }
  this.generateCnonce = function() {
    var characters = 'abcdef0123456789';
    var token = '';
    for (var i = 0; i < 16; i++) {
      var randNum = Math.round(Math.random() * characters.length);
      token += characters.substr(randNum, 1);
    }
    return token;
  }
  this.abort = function() {
    self.log('[digestAuthRequest] Aborted request to '+url);
    if (self.firstRequest != null) {
      if (self.firstRequest.readyState != 4) self.firstRequest.abort();
    }
    if (self.authenticatedRequest != null) {
      if (self.authenticatedRequest.readyState != 4) self.authenticatedRequest.abort();
    }
  }
  this.isJson = function(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  this.log = function(str) {
    if (self.loggingOn) {
      console.log('[digestAuthRequest] '+str);
    }
  }
  this.version = function() { return '0.8.0' }
}

