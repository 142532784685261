import Vue from 'vue';
import moment from 'moment';

Vue.filter('formatDate', (value) => {
  if (value) {
    return moment(String(value)).format('YYYY/MM/DD HH:mm');
  }
});

Vue.filter('convertRecorderType', (value) => {
  if (value) {
    if (value === '1') {
      return 'LTE';
    } else if (value === '2') {
      return 'Sigfox';
    }
    return '';
  }
});

Vue.filter('convertNoticePublishFlg', (flg) => {
  if (flg) {
    return '公開';
  } else {
    return '非公開';
  }
});

Vue.filter('openingDegree', (value) => {
  if (value === 255) {
    return '-';
  }
  return value;
});
