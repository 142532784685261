<template>
  <VContainer>
    <p class="title">位置ロック</p>
    <VLayout
      wrap
      align-space-between
    >
      <VFlex xs12>
        <div>
          <PositionLock
            :recorder="recorder"
            :label="label"
            class="position-lock-btn"
            @lockStateChanged="handleLockStateChanged"
          />
        </div>
      </VFlex>
    </VLayout>
  </VContainer>
</template>

<script>
import PositionLock from '~/components/recorders/positionLock'
export default {
  components: {
    PositionLock,
  },
  props: {
    recorderId: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    label() {
      return this.isLocked ? 'ON' : 'OFF';
    },
    isLocked() {
      const lockStateMap = this.$store.getters['map/recorderIdAndLockStateMap'];
      if (lockStateMap) {
        return lockStateMap[this.recorderId];
      }
      return false;
    },
    recorder() {
      return this.$store.getters['map/permittedRecorders'].find((recorder) => {
          return recorder.id === this.recorderId;
      });
    },
  },
  methods: {
    handleLockStateChanged(isLocked) {
      this.$store.commit('map/setLocked', { recorderId: this.recorderId, locked: isLocked });
    },
  }
}
</script>

<style lang="scss" scoped>
  .position-lock-btn {
    display: inline-block;
  }
</style>
