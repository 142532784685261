import Vue from 'vue';

const initialState = () => ({
  data: [],
  companyNames: {},
  organizations: {},

  // adminレコーダー管理の「組織」フィルタ用
  // （「会社」フィルタが別であるため、ここだけ「組織名」のみの表示）
  filterOrganizations: {},

  filters: {
    companyName: null,
    organization: null,
  },
  statusFilters: {
    organization: null, name: null, operation: null, youtubeOpenStatus: null, ssd_record: null, sd_record: null,
  },
  showForm: false,
  recorder: {
    data: { aux: {} },
    rs485: [],
    preset: [],
    logs: [],
    status: {
      operation_status: "9"
    },
    info: {},
    sim: {},
    device_id: null,
  },
  displayedDeviceStopLog: false,

  waterGateOpeningStatuses: [],

  paginationForCsv: { sortBy: null, descending: null }, // 状態通知CSVダウンロード時に画面ソートと並びを同じにするため状態保持
});

export const state = initialState;

export const getters = {
  data: (state) => {
    return state.data;
  },
  companyNames: (state) => {
    return state.companyNames;
  },
  organizations: (state) => {
    return state.organizations;
  },
  filterOrganizations: (state) => {
    return state.filterOrganizations;
  },
  // TODO: かなり冗長な書き方になってしまっているので、修正したい
  filtered: (state) => {
    const filters = state.filters;
    return _.chain(state.data)
      .filter((i) => {
        // 全選択
        if (filters.companyName === 'すべて' && filters.organization === 'すべて') {
          return true;
        }

        // 会社名のみ「すべて」パターン
        if (filters.companyName === 'すべて') {
          if (!filters.organization) {
            return true;
          }
          if (filters.organization === 'none' && !i.organization_id) {
            return true;
          }
          return filters.organization === i.organization_id;
        }

        // 組織名のみ「すべて」パターン
        if (filters.organization === 'すべて') {
          if (!filters.companyName) {
            return true;
          }
          if (filters.companyName === '会社なし' && !i.company_name) {
            return true;
          }
          return filters.companyName === i.company_name;
        }

        // 以下、会社名・組織名ともに「すべて」ではないパターン
        if (filters.companyName === '会社なし' && filters.organization === 'none') {
          if (!i.company_name && !i.organization_id) {
            return true;
          }
        }

        // 「会社なし」選択時
        if ((filters.companyName === '会社なし' && !i.company_name)) {
          if (filters.organization === i.organization_id || !filters.organization) {
            return true;
          }
        }
        // 「組織なし」選択時
        if (filters.organization === 'none' && !i.organization_id) {
          if (filters.companyName === i.company_name || !filters.companyName) {
            return true;
          }
        }

        // 以下、「すべて」「組織なし」「会社なし」以外のパターン

        // 会社名・組織名ともに指定
        if (filters.companyName && filters.organization) {
          return filters.companyName === i.company_name && filters.organization === i.organization_id;
        }

        // 会社名のみ指定
        if (filters.companyName) {
          return filters.companyName === i.company_name;
        }

        // 組織名のみ指定
        if (filters.organization) {
          return filters.organization === i.organization_id;
        }

        return true;
      }).value();
  },
  recorder: (state) => {
    return state.recorder;
  },
  recorderStatus:(state) => {
    return state.recorder.status;
  },
  isConnectionError: (state) => {
    return state.recorder.status.operation_status === "0";
  },
  isLoading: (state) => {
    return state.recorder.status.operation_status === "9";
  },

  status(state) {
    return state.status;
  },
  info(state) {
    return state.info;
  },
  sim(state) {
    return state.sim;
  },
  paginationForCsv: (state) => {
    return state.paginationForCsv;
  },
  statusFiltered: (state) => {
    const filters = state.statusFilters;

    if (filters.organization === 'すべて') {
      return _.chain(state.status)
      .filter((i) => {
        let a = true;
        if (filters.name) a = a && i.name.indexOf(filters.name) > -1; // 部分一致
        if (filters.operation != null) a = a && filters.operation === (i.operation_status === '1');
        if (filters.youtubeOpenStatus != null) a = a && filters.youtubeOpenStatus === i.youtube_open_status; // 公開設定状態
        if (filters.ssd_record != null) a = a && filters.ssd_record === (i.rec_ssd_status === '1');
        if (filters.sd_record != null) a = a && filters.sd_record === (i.rec_sd_status === '-1' ? null : i.rec_sd_status === '1'); // -1であればフィルターの値によらず返さない
        return a;
      }).value();
    }

    return _.chain(state.status)
    .filter((i) => {
      let a = true;
      if (filters.organization != null) a = a && filters.organization === i.organization_id;
      if (filters.name) a = a && i.name.indexOf(filters.name) > -1; // 部分一致
      if (filters.operation != null) a = a && filters.operation === (i.operation_status === '1');
      if (filters.youtubeOpenStatus != null) a = a && filters.youtubeOpenStatus === i.youtube_open_status; // 公開設定状態
      if (filters.ssd_record != null) a = a && filters.ssd_record === (i.rec_ssd_status === '1');
      if (filters.sd_record != null) a = a && filters.sd_record === (i.rec_sd_status === '-1' ? null : i.rec_sd_status === '1'); // -1であればフィルターの値によらず返さない
      return a;
    }).value();
  },
  waterGateOpeningStatuses: (state) => {
    return state.waterGateOpeningStatuses;
  },
  statusFilters: (state) => {
    return state.statusFilters;
  },
  filters: (state) => {
    return state.filters;
  },
};

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach((key) => { state[key] = initial[key] });
  },
  setRecorders(state, data) {
    state.data = data;
  },
  setCompanyNames(state, data) {
    state.companyNames = data;
  },
  setOrganizations(state, data) {
    state.organizations = data;
  },
  setFilterOrganizations(state, data) {
    state.filterOrganizations = data;
  },
  setFilters(state, data) {
    state.filters = _.clone(data);
  },
  setStatusFilters(state, data) {
    state.statusFilters = _.clone(data);
  },
  setStatusFilterByUser(state, data) {
    state.statusFilters = _.clone(data);
  },
  clearFilter(state) {
    state.statusFilters = {};
  },
  showForm(state) {
    state.showForm = !state.showForm;
  },
  setPaginationForCsv(state, pagination) {
    state.paginationForCsv = pagination;
  },
  deleteRecorder(state, id) {
    state.data = _.chain(state.data).filter(i => i.id !== id).value();
  },
  setYoutubeOpenStatus(state, data) {
    _.each(state.data, (i) => {
      if (i.id === data.id) {
        i.youtube.open_status = data.youtubeOpenStatus;
      }
    });
  },
  setRecorder(state, data) {
    state.recorder.data = data;
  },
  setRecorderControl(state, data) {
    if (data.control_code !== undefined) {
      Vue.set(state.recorder.data, 'control_code', data.control_code);
    }
    if (data.plan !== undefined) {
      Vue.set(state.recorder.data, 'plan', data.plan);
    }
    if (data.device_id !== undefined) {
      Vue.set(state.recorder.data, 'device_id', data.device_id);
    }
  },
  setRS485(state, data) {
    state.recorder.rs485 = [];
    _.each(data, (i) => {
      state.recorder.rs485[i.NO] = i;
    });
  },
  setPresetList(state, data) {
    state.recorder.preset = _.map(_.range(256), i => ({ NO: i, Name: '' }));
    _.each(data, (i) => {
      state.recorder.preset[i.NO] = i;
    });
  },
  setPreset(state, data) {
    state.recorder.preset[data.no].Name = data.name;
  },
  resetLogs(state) {
    state.recorder.logs = [];
    this.displayedDeviceStopLog = false;
  },
  setDisplayedDeviceStopLog() {
    this.displayedDeviceStopLog = true;
  },
  setLogs(state, data) {
    state.recorder.logs = state.recorder.logs.concat(data);
  },
  setStatus(state, data) {
    state.recorder.status = data;
  },
  setRecordersStatus(state, data) {
    state.status = data;
  },
  setInfo(state, data) {
    state.recorder.info = data;
  },
  setSimTraffic(state, data) {
    state.recorder.sim = data;
  },
  setExtdeviceWaterGateOpeningDegrees(state, data) {
    state.waterGateOpeningStatuses = data;
  },
  clearRecorder(state) {
    const currentRecorder = _.cloneDeep(state.recorder.data);
    state.recorder = _.cloneDeep(initialState().recorder);
    state.recorder.data = currentRecorder;
  }
};

export const actions = {
  clearRecorder({ commit }) {
    commit('clearRecorder');
  },
  filter({ commit }, data) {
    commit('setFilters', data);
  },
  statusFilter({ commit }, data) {
    commit('setStatusFilters', data);
  },
  statusFilterByUser({ commit }, data) {
    commit('setStatusFilterByUser', data);
  },
  clearFilter({ commit }) {
    commit('clearFilter');
  },
  showForm({ commit }) {
    commit('showForm');
  },
  // GET API を呼び出して、結果を返すだけ
  get({ commit, state }, id) {
    return new Promise(async (resolve, reject) => {
      try {
        const apiResponse = await this.$axios.get(process.env.apiBaseUrl + '/recorder/' + id, {
          timeout: 300000,
        });
        resolve(apiResponse.data);
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },
  // updateRecorder: GET API の結果と現在の state を比較し、変更があれば commit する
  updateRecorder({ commit, state, dispatch }, id) {
    return dispatch('get', id)
      .then((data) => {
        if (!_.isEqual(state.recorder.data, data)) {
          commit('setRecorder', data);
        }
        return data;
      });
  },
  async list({ commit }) {
    await this.$axios.post(process.env.apiBaseUrl + '/recorders', {}, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      commit('setRecorders', res.data);
    }).catch((err) => {
      console.log(err);
    });
  },
  create({ commit }, payload) {
    this.$axios.post(process.env.apiBaseUrl + '/recorder', payload, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      if (res.data.ok) {
        console.log(11)
      } else {
        console.log(22)
      }
    }).catch((err) => {
      console.log(err);
    })
  },
  update({ commit }, data) {
    return new Promise((resolve, reject) => {
      this.$axios.put(process.env.apiBaseUrl + '/recorder/' + data.id, data.payload, {
        // withCredentials: true,
        timeout: 300000,
      }).then((res) => {
        if (res.data.ok) {
          resolve(11);
        } else {
          resolve(22);
        }
      }).catch((err) => {
        reject(err);
      })
    });
  },
  updateCoordinates({ commit }, data) {
    return new Promise((resolve, reject) => {
      const apiEndpoint = process.env.apiBaseUrl + '/recorder-coordinates/' + data.id;
      this.$axios.put(apiEndpoint, data.payload, {
        timeout: 300000,
      }).then((res) => {
        if (res.data.id) {
          resolve(11);
        } else {
          resolve(22);
        }
      }).catch((err) => {
        reject(err);
      })
    });
  },
  delete({ commit }, id) {
    commit('deleteRecorder', id);
  },
  setYoutubeOpenStatus({ commit }, data) {
    commit('setYoutubeOpenStatus', data);
  },
  clear({ commit }) {
    commit('setRecorder', { aux: {} });
  },
  listCompanyNames({ commit }) {
    this.$axios.get(process.env.apiBaseUrl + '/company-name-combo-box', {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      commit('setCompanyNames', res.data);
    }).catch((err) => {
      console.log(err);
    });
  },
  async listOrganizations({ commit }) {
    await this.$axios.post(process.env.apiBaseUrl + '/organizations-combo-box', {}, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      commit('setOrganizations', res.data);
    }).catch((err) => {
      console.log(err);
    });
  },
  async listFilterOrganizations({ commit }) {
    await this.$axios.post(process.env.apiBaseUrl + '/organizations-name-combo-box', {}, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      commit('setFilterOrganizations', res.data);
    }).catch((err) => {
      console.log(err);
    });
  },
  async listRS485({ commit, state }, recorder_id) {
    if(!recorder_id) {
      return;
    }
    try {
      const { data } = await this.$axios.get(process.env.apiBaseUrl + '/rs485?recorder_id=' + recorder_id, {
        // withCredentials: true,
        timeout: 300000,
      });
      // 既に別TRが選択された場合、処理しない
      const currentId = state.recorder.data.id;
      if (currentId === recorder_id) {
        commit('setRS485', data);
      } else {
        console.log("[Another recorder is selected!]");
      }
    } catch (err) {
      console.log(err);
    }
  },
  createRS485({ commit }, data) {
    this.$axios.post(process.env.apiBaseUrl + '/rs485', data, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      if (res.data.ok) {
        console.log(11)
      } else {
        console.log(22)
      }
    }).catch((err) => {
      console.log(err);
    })
  },
  updateRS485({ commit }, data) {
    this.$axios.put(process.env.apiBaseUrl + '/rs485/' + data.id, data, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      if (res.data.ok) {
        console.log(11)
      } else {
        console.log(22)
      }
    }).catch((err) => {
      console.log(err);
    })
  },
  createStreamingCtrl({ commit }, data) {
    return new Promise((resolve, reject) => {
      this.$axios.post(process.env.apiBaseUrl + '/streaming_ctrl/' + data.recorder_id, data.payload, {
        timeout: 300000,
      }).then((res) => {
        if (res.data.ok) {
          console.log(11)
        } else {
          console.log(22)
        }
        resolve();
      }).catch((err) => {
        console.log(err);
        reject(err);
      })
    });
  },
  updateStreamingCtrl({ commit }, data) {
    return new Promise((resolve, reject) => {
      this.$axios.put(process.env.apiBaseUrl + '/streaming_ctrl/' + data.recorder_id, data.payload, {
        timeout: 300000,
      }).then((res) => {
        if (res.data.ok) {
          console.log(11)
        } else {
          console.log(22)
        }
        resolve();
      }).catch((err) => {
        console.log(err);
        reject();
      })
    });
  },
  updateBroadcastSetting({ commit }, data) {
    return new Promise((resolve, reject) => {
      this.$axios.put(process.env.apiBaseUrl + '/streaming_ctrl-broadcast-setting/' + data.recorder_id, data.payload, {
        // withCredentials: true,
        timeout: 300000,
      }).then((res) => {
        if (res.data.ok) {
          console.log(11)
        } else {
          console.log(22)
        }
        resolve();
      }).catch((err) => {
        console.log(err);
        reject(err);
      })
    });
  },
  async listPreset({ commit, state }, recorder_id) {
    if(!recorder_id) {
      return;
    }
    try {
      const { data } = await this.$axios.get(process.env.apiBaseUrl + '/preset?recorder_id=' + recorder_id, {
        timeout: 300000,
      });
      // 既に別TRが選択された場合、処理しない
      const currentId = state.recorder.data.id;
      if (currentId === recorder_id) {
        commit('setPresetList', data);
      } else {
        console.log("[Another recorder is selected!]");
      }
    } catch (err) {
      console.log(err);
    }
  },
  createPreset({ commit }, data) {
    return new Promise((resolve, reject) => {
      this.$axios.post(process.env.apiBaseUrl + '/preset', data, {
        // withCredentials: true,
        timeout: 300000,
      }).then((res) => {
        commit('setPreset', data);
        resolve(res);
      }).catch((err) => {
        console.log(err);
        reject(err);
      })
    });
  },
  getLogs({ commit }, data) {
    this.$axios.get(process.env.apiBaseUrl + '/copy-events/' + data.recorder_id + '?startTime=' + data.startTime, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      // 稼働状態停止のログは、停止している場合必ず取得させるため
      // すでに表示している場合は除外
      _.each(res.data, (log, index) => {
        if (log.kind == '99') {
          // すでに表示している場合は除外
          if (this.displayedDeviceStopLog === true) {
            res.data.splice(index, 1);
          }
          this.displayedDeviceStopLog = true;
        }
      });
      commit('setLogs', res.data);
    }).catch((err) => {
      console.log(err);
    });
  },
  async listRecordersStatus({ commit }) {
    await this.$axios.post(process.env.apiBaseUrl + '/recorders-status', {}, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      commit('setRecordersStatus', res.data);
    }).catch((err) => {
      console.log(err);
    });
  },
  getStatus({ commit, state }, id) {
    if(!id) {
      return;
    }
    this.$axios.get(process.env.apiBaseUrl + '/push-status/' + id, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      // 既に別TRが選択された場合、処理しない
      // const currentId = state.recorder.data.device_id;// 脆弱性対応#1643のため、ここのdevice_idをidに修正
      const currentId = state.recorder.data.id;
      if (currentId === id) {
        commit('setStatus', res.data);
      } else {
        console.log("[Another recorder is selected!]");
      }
    }).catch((err) => {
      console.log(err);
    });
  },
  async getInfo({ commit, state }, id) {
    if(!id) {
      return;
    }
    try {
      const { data } = await this.$axios.get(process.env.apiBaseUrl + '/push-Info/' + id, {
        // withCredentials: true,
        timeout: 300000,
      });
      // 既に別TRが選択された場合、処理しない
      // const currentId = state.recorder.data.device_id;// 脆弱性対応#1643のため、ここのdevice_idをidに修正
      const currentId = state.recorder.data.id
      if (currentId === id) {
        commit('setInfo', data);
      } else {
        console.log("[Another recorder is selected!]");
      }
    } catch (err) {
      console.log(err);
    }
  },
  getSimTraffic({ commit }, id) {
    if(!id){
      return;
    }
    this.$axios.get(process.env.apiBaseUrl + '/sim-traffic/' + id, {
      // withCredentials: true,
      timeout: 300000,
    }).then((res) => {
      commit('setSimTraffic', res.data);
    }).catch((err) => {
      console.log(err);
    });
  },
  getExtdeviceWaterGateOpeningDegrees({ commit }, id) {
    if(!id) {
      return;
    }
    this.$axios.get(process.env.apiBaseUrl + '/extdevice-data/plc/watergate/' + id + '/all', {
      timeout: 300000
    }).then((res) => {
      if (!res || !res.data || !res.data.water_gate_statuses) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'RS485入力情報取得に失敗しました。' });
        return;
      }
      console.log(res.data.water_gate_statuses);
      commit('setExtdeviceWaterGateOpeningDegrees', res.data.water_gate_statuses);
    });
  },
};
