const initialState = () => ({
  leftDrawer:  false,
  rightDrawer: false,
  type: null,
  recorders: [],
  streaemingState: false,
  targetRecorderChanged: false,
  bulkStreamingState: false,
  waitingStreamingState: { type: '', isWaiting: false }
});

export const state = initialState;

export const getters = {
  type: (state) => {
    return state.type;
  },
  recorders: (state) => {
    return state.recorders;
  },
  leftDrawer: (state) => {
    return state.leftDrawer;
  },
  rightDrawer: (state) => {
    return state.rightDrawer;
  },
  bulkStreamingState: (state) => {
    return state.bulkStreamingState;
  },
  waitingStreamingState: (state) => {
    return state.waitingStreamingState;
  },
};

export const mutations = {
  resetState(state) {
    const initial = initialState();
    Object.keys(initial).forEach(key => { state[key] = initial[key] });
  },
  setRecorders(state, data) {
    state.type = data.type;
    state.recorders = data.recorders;
    console.log(state.recorders);
  },
  setStreamingState(state, data) {
    state.streamingState = data;
  },
  toggleLeftDrawer(state) {
    state.leftDrawer = !state.leftDrawer;
  },
  toggleRightDrawer(state) {
    state.rightDrawer = !state.rightDrawer;
  },
  showRightDrawer(state) {
    state.rightDrawer = true;
  },
  showLeftDrawer(state) {
    state.leftDrawer = true;
  },
  closeRightDrawer(state) {
    state.rightDrawer = false;
  },
  closeLeftDrawer(state) {
    state.leftDrawer = false;
  },
  closeDrawer(state) {
    state.leftDrawer = false;
    state.rightDrawer = false;
  },
  changeRecorder(state, data) {
    state.targetRecorderChanged = data;
  },
  setBulkStreamingState(state, isStreaming) {
    state.bulkStreamingState = isStreaming;
  },
  setWaitingStreamingState(state, data) {
    state.waitingStreamingState = data;
  },
};

export const actions = {
  setRecorders({ commit }, data) {
    commit('setRecorders', data);
  },
  setStreamingState({ commit }, data) {
    commit('setStreamingState', data);
  },
  toggleLeftDrawer({ commit }) {
    commit('toggleLeftDrawer');
  },
  toggleRightDrawer({ commit }) {
    commit('toggleRightDrawer');
  },
  showRightDrawer({ commit }) {
    commit('showRightDrawer');
  },
  showLeftDrawer({ commit }) {
    commit('showLeftDrawer');
  },
  closeDrawer({ commit }) {
    commit('closeDrawer');
  },
  closeRightDrawer({ commit }) {
    commit('closeRightDrawer');
  },
  closeLeftDrawer({ commit }) {
    commit('closeLeftDrawer');
  },
};
