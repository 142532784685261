import { AvailableAudio } from '~/model/AvailableAudio';

const initialState = () => ({
  items : [],
  isAudioListFetched : false,
});

export const state = initialState;

export const getters = {
  items: (state) => {
    return state.items;
  },
  isAudioListFetched: (state) => {
    return state.isAudioListFetched;
  }
};

export const actions = {
  async loadItems({commit}, recorderId){
    console.log("loadItems", recorderId);

    const apiResponse = await this.$axios.get(process.env.apiBaseUrl + '/recorders/' + recorderId + '/available-audios', {
      timeout: 300000,
    });

    const items = [];
    for (let i = 0; i < apiResponse.data.available_audios.length; i++) {
      const aa = apiResponse.data.available_audios[i];
      items.push(new AvailableAudio(aa.recorder_id, aa.title, aa.file_name, aa.size, aa.duration, aa.ownership));
    }

    commit('setItems', items);
  },

  async saveItem({commit}, item) {
    console.log("saveItems", item);
    await this.$axios.post(process.env.apiBaseUrl + '/recorders/' +
      item.recorderID + '/available-audios/' + item.fileName, {
      title: item.title
    },{
      timeout: 300000,
    });
    commit('setItem', item);
  },

  edit({commit}, item){
    console.log("edit", item);
    item.editing = true;
    commit('setItem', item);
  },

  async remove({commit}, {recorderID, fileName}){
    console.log("remove", {recorderID, fileName});
    commit('removeItem', {recorderID, fileName});
  },

  setAudioListFetched({commit}, isGetted) {
    commit('setAudioListFetched',isGetted)
  },
};

export const mutations = {
  setItems(state, items) {
    console.log("ao setitems");
    state.items = items;
  },

  setItem(state, item) {
    for (let i = 0; i < state.items.length; i++) {
      const a = state.items[i];
      if(a.id === item.id) {
        state.items[i] = item;
      }
    }
  },

  removeItem(state, {recorderID, fileName}) {
    for (let i = 0; i < state.items.length; i++) {
      const a = state.items[i];
      if(a.id === recorderID+fileName) {
        state.items.splice(i, 1);
      }
    }
  },

  setAudioListFetched(state,status){
    state.isAudioListFetched = status;
  }
};
