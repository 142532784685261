export const persistence = {
  persistentState: (key: string, obj: object): void => {
    sessionStorage.setItem(key, JSON.stringify(obj));
  },
  restoreState: (key: string): object => {
     const serializedState = sessionStorage.getItem(key);
     if (!serializedState) {
       return {};
     }
     return JSON.parse(serializedState);
  },
  removeState: (key) => {
     sessionStorage.removeItem(key);
  }
};
