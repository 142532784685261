<template>
  <v-container>
    <p class="title">
      ファームアップ
    </p>
    <v-layout
      justify-center
      wrap
      align-space-between
    >
      <v-flex xs6>
        <p class="subheading">
          対象:
        </p>
      </v-flex>
      <v-flex xs6>
        <v-select
          v-model="firmup"
          :items="items"
          label="対象"
          :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id)"
        />
      </v-flex>
      <v-flex xs6>
        <p class="subheading">
          予約:
        </p>
      </v-flex>
      <v-flex xs6>
        <v-select
          v-model="hour"
          :items="hours"
          label="HH"
          :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id)"
        />
        <v-select
          v-model="min"
          :items="mins"
          label="MM"
          :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id)"
        />
      </v-flex>
      <v-flex xs6>
        <v-btn :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id)" @click="dialog = true">
          実行
        </v-btn>
      </v-flex>
      <v-flex xs6>
        <v-btn :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id)" @click="firmUpCancelDialog = true">
          予約のキャンセル
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      max-width="480"
    >
      <v-card>
        <v-card-title class="subheading">
          <v-flex xs12 class="dialog-title-margin">
            ファームアップ設定を変更します。
          </v-flex>
          <v-flex xs12 class="dialog-title-margin">
            よろしいですか？
          </v-flex>
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn
            class="dialog-button-margin"
            color="grey darken-2"
            outline
            @click="dialog = false"
          >
            いいえ
          </v-btn>

          <v-btn
            class="dialog-button-margin"
            color="primary"
            @click="saveFirmup"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="firmUpCancelDialog"
      max-width="480"
    >
      <v-card>
        <v-card-title class="subheading">
          <v-flex xs12 class="dialog-title-margin">
            ファームアップ待機状態をキャンセルします。
          </v-flex>
          <v-flex xs12 class="dialog-title-margin">
            よろしいですか？
          </v-flex>
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn
            class="dialog-button-margin"
            color="grey darken-2"
            outline
            @click="firmUpCancelDialog = false"
          >
            いいえ
          </v-btn>

          <v-btn
            class="dialog-button-margin"
            color="primary"
            @click="cancel"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as types from '~/store/types';
export default {
  data() {
    return {
      items: [{ text: 'タウンレコーダー', value: 'townrec' },
      { text: 'タウンレコーダーマイコン', value: 'tr_mcu' },
      { text: 'LTEモジュール', value: 'ltemodule' },
      { text: 'カメラ', value: 'camera' },
      { text: 'カメラマイコン', value: 'cam_mcu' }],
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      mins: [0, 15, 30, 45],
      firmup: null,
      hour: 0,
      min: 0,
      dialog: false,
      firmUpCancelDialog: false,
    }
  },

  computed: {
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
  },
  methods: {
    saveFirmup() {
      this.dialog = false;
      if (this.firmup == null) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '対象を選択してください。' })
        return;
      }

      // デバイス制御CGI実行
      const url = 'http://' + this.recorder.vpn.virtualip + '/device_ctrl.cgi';
      const cgi = new this.$cgi('POST', url, this.recorder.vpn.username, this.recorder.vpn.password);

      // 10hまで設定可能
      const second = (this.hour * 3600) + (this.min * 60);
      if (second > 36000) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '10時間を超えているため、設定できません。' });
        return;
      }

      const cgiParams = 'firmup=' + this.firmup + '&schedule=' + second + '&';

      // CGI実行
      this.$axios.post(process.env.cgiBaseUrl, {
        recorderID: this.recorder.id,
        method: 'POST',
        url: '/device_ctrl.cgi',
        data: cgiParams,
      }, {
        timeout: 300000,
      }).then((data) => {
        console.log(data);
        console.log('デバイス制御CGI実行 [成功]');
      }).catch((errorCode) => {
        console.log(errorCode);
        console.log('デバイス制御CGI実行 [失敗]');

        if (errorCode.response.status === 403) {
          return;
        }

        const firmUpAlreadyReserved = errorCode.response.status === 400;
        if (firmUpAlreadyReserved) {
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '既にファームアップ予約済みです。' });
          return;
        }

        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'デバイス制御に失敗したため、ファームアップ設定を変更しませんでした。' });
      });
    },

    cancel() {
      this.firmUpCancelDialog = false;
      // デバイス制御CGI実行
      const url = 'http://' + this.recorder.vpn.virtualip + '/device_ctrl.cgi';
      const cgi = new this.$cgi('POST', url, this.recorder.vpn.username, this.recorder.vpn.password);

      const cgiParams = 'firmup=cancel&';

      // CGI実行
      this.$axios.post(process.env.cgiBaseUrl, {
        recorderID: this.recorder.id,
        method: 'POST',
        url: '/device_ctrl.cgi',
        data: cgiParams,
      }, {
        timeout: 300000,
      }).then((data) => {
        console.log(data)
        console.log('デバイス制御CGI実行 [成功]')
      }).catch((errorCode) => {
        console.log(errorCode);
        console.log('デバイス制御CGI実行 [失敗]');

        if (errorCode.response.status === 403) {
          return;
        }

        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'デバイス制御に失敗したため、ファームアップ設定を変更しませんでした。' });
      });
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
