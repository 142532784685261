<template>
  <v-container>
    <p class="title">
      RS485出力
    </p>
    <v-layout
      justify-center
      align-space-between
    >
      <v-flex xs10>
        <v-select
          class="word-break"
          v-model="selected"
          :items="rs485"
          :disabled="!this.hasRs485outOperation(this.recorder.organization_id)"
        />
      </v-flex>
      <v-flex xs2>
        <v-btn
          :disabled="!sendable || !this.hasRs485outOperation(this.recorder.organization_id) || processing"
          @click.prevent="confirm()"
        >
          送信
        </v-btn>
      </v-flex>
    </v-layout>
    <p class="title mt-4">
      RS485入力 <v-btn @click="rs485InConfirm = true">
        更新
      </v-btn>
    </p>
    <template>
      <table class="table-rs485-in">
        <tr
          v-for="openingStatus in waterGateOpeningStatuses"
          :key="openingStatus.no"
        >
          <td class="td-rs485-in">{{ openingStatus.name }}: </td>
          <td class="td-rs485-in">
            {{ openingStatus.opening_degree | openingDegree }} {{ openingStatus.unit }}
          </td>
        </tr>
      </table>
    </template>

    <!-- RS485出力確認ダイアログ -->
    <v-dialog
      v-model="rs485OutConfirm"
      max-width="480"
    >
      <v-card>
        <v-card-title class="subheading">
          <span class="dialog-title-margin">RS485出力({{ operationName }})を送信してもよろしいですか？</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn
            class="dialog-button-margin"
            color="grey darken-2"
            outline
            @click="cancel()"
          >
            いいえ
          </v-btn>

          <v-btn
            class="dialog-button-margin"
            color="primary"
            @click="submit"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- RS485入力(水門制御)確認ダイアログ -->
    <v-dialog
      v-model="rs485InConfirm"
      max-width="480"
    >
      <v-card>
        <v-card-title class="subheading">
          <span class="dialog-title-margin">RS485入力を送信してもよろしいですか？</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer />

          <v-btn
            class="dialog-button-margin"
            color="grey darken-2"
            outline
            @click="rs485InConfirm = false"
          >
            いいえ
          </v-btn>

          <v-btn
            class="dialog-button-margin"
            color="primary"
            @click="updateWaterGateOpeningInfo"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as types from '~/store/types';
export default {
  props: {
    recorder: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      processing: false,
      selected: 0,
      timer: {},
      rs485OutConfirm: false,
      rs485InConfirm: false,
      operationName: '',
      rs485InStartFlag: false
    }
  },
  computed: {
    rs485() {
      return _.chain(this.$store.state.recorders.recorder.rs485)
        .filter(i => i !== undefined && i.Name !== "")
        .map((i) => { return { text: i.Name, value: i.NO } })
        .value();
    },
    sendable() {
      const cnt = _.chain(this.$store.state.recorders.recorder.rs485)
        .filter(i => i !== undefined).size();
      return cnt > 0;
    },
    waterGateOpeningStatuses() {
      return this.$store.getters['recorders/waterGateOpeningStatuses'];
    }
  },
  watch: {
    recorder: {
      immediate: true,
      handler() {
        if (!this.recorder.id) {
          return;
        }
          this.$store.dispatch('recorders/listRS485', this.recorder.id);
          this.init();
      }
    }
  },
  beforeDestroy() {
    this.init();
  },
  mounted() {
    this.init();
    // console.log(this.recorder.device_id);
    this.getExtdeviceWaterGateOpeningDegrees(this.recorder.id);
  },
  methods: {
    cancel() {
      this.rs485OutConfirm = false;
    },
    init() {
      clearInterval(this.timer);
      this.timer = null;
    },
    confirm() {
      this.operationName = this.$store.state.recorders.recorder.rs485[this.selected].Name;
      this.rs485OutConfirm = true;
    },
    submit() {
      this.rs485OutConfirm = false;
      if (this.processing) return;
      this.processing = true;
      this.sendRS485()
        .then(() => {
          this.processing = false;
        });
    },
    async sendRS485() {
      // 操作権限判定
      if (!this.hasRs485outOperation(this.recorder.organization_id)) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '操作権限がありません。' });
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1000); // 多重送信防止タイムアウト
        });
      }

      const rs485 = this.$store.state.recorders.recorder.rs485;
      const data = 'data=' + rs485[this.selected].Data;

      try {
        // const device_id = this.recorder.device_id;
        const recorder_id = this.recorder.id

        await this.$axios.post(process.env.cgiBaseUrl, {
          recorderID: this.recorder.id,
          method: 'POST',
          url: '/extdevice_ctrl.cgi',
          data: data
        }, {
          timeout: 300000
        });

        const apiEndpoint = process.env.apiBaseUrl + '/copy-events/' + recorder_id;
        const payload = {};
        payload.data = '"' + data + '"';
        payload.kind = "3"
        await this.$axios.$post(apiEndpoint, payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      }catch(err) {
          console.log(err);
          if (err.response.status !== 403) {
            this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: '外部機器制御に失敗しました。' });
          }
      }

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // 多重送信防止タイムアウト
      });
    },
    // 水門開度情報取得をレコーダー（を介してPLC）に依頼
    updateWaterGateOpeningInfo() {

      this.rs485InConfirm = false;

      const CancelToken = this.$axios.CancelToken;
      const source = CancelToken.source();

      if(this.rs485InStartFlag){
        source.cancel('Operation canceled by the user.');
      }

      this.rs485InStartFlag = true;


      this.$axios.post(process.env.cgiBaseUrl, {
        recorderID: this.recorder.id,
        method: 'POST',
        url: '/extdevice_ctrl.cgi',
        data: '000000000000'
      }, {
        timeout: 300000,
        cancelToken: source.token
      }).then((data) => {
        console.log(data);
      }).catch((errorCode) => {
        console.log('updateWaterGateOpeningInfoError',errorCode);
        if (errorCode.response.status === 403) return;
        console.log('not 403');
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, { message: 'RS485入力情報取得に失敗しました。' });
      }).finally(()=> {
            // 通信が完了したならば、キャンセル用のコントローラーを入れる変数を空にする
            this.rs485InStartFlag = false;
      });
      // 水門開度情報取得要求の成否に関わらず、表示更新は始めてしまう。
      this.pollingExtdeviceCtrlData(this.recorder.id);
    },
    pollingExtdeviceCtrlData(id) {
      console.log('[pollingExtdeviceCtrlData]: ' + id);
      //タイマーが動いていたら一旦リセット
      clearInterval(this.timer);
      let counter = 1;
      this.timer = setInterval(() => {
        console.log(counter);
        this.getExtdeviceWaterGateOpeningDegrees(id);
        if (counter >= 6) {
          console.log('clearInterval!');
          clearInterval(this.timer);
        }
        counter++;
      }, 1000/* ms */ * 10/* sec */);
    },
    // 最新の全水門開度情報を取得
    getExtdeviceWaterGateOpeningDegrees(id) {
      this.$store.dispatch('recorders/getExtdeviceWaterGateOpeningDegrees', id);
    }
  }
}
</script>

<style lang="scss" scoped>
.table-rs485-in {
  width: 100%;
  table-layout: fixed;
}
.td-rs485-in {
    font-size: 16px;
    padding: 0 5px 20px 0;
    vertical-align: top;
    word-wrap: break-word;
}
.word-break {
  word-break: break-all;
}
</style>
