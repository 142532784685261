<template>
  <v-container>
    <p class="title">再起動</p>
    <v-layout
      justify-center
      wrap
      align-space-between>
      <v-flex xs6> <p class="subheading">タウンレコーダーの再起動: </p> </v-flex>
      <v-flex xs6>
        <v-btn
        :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id) || processing"
        @click.prevent="dialog = true" >
          再起動
        </v-btn>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      max-width="560"
    >
      <v-card>
        <v-card-title class="subheading">
          <v-flex xs12 class="dialog-title-margin">タウンレコーダー({{recorder.name}})を再起動します。</v-flex>
          <v-flex xs12 class="dialog-title-margin">よろしいですか？</v-flex>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="dialog-button-margin"
            color="grey darken-2"
            outline
            @click="dialog = false"
          >
            いいえ
          </v-btn>

          <v-btn
            class="dialog-button-margin"
            color="primary"
            @click="submit()"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as types from "~/store/types";
export default {
  data() {
    return {
      dialog: false,
      processing: false,
    }
  },
  computed: {
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
  },
  mounted() {
  },
  methods: {
    submit() {
      this.dialog = false;
      if (this.processing) return;
      this.processing = true;
      this.reboot()
        .then(() => {
          this.processing = false;
        });
    },
    reboot() {
      // 操作権限判定
      if (!this.hasMaintenanceOperation(this.recorder.organization_id)) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '操作権限がありません。'});
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1000); // 多重送信防止タイムアウト
        });
      }

      this.$axios.post(process.env.cgiBaseUrl, {
        recorderID: this.recorder.id,
        method: 'GET',
        url: '/reboot.cgi',
        data: 'mode=reboot',
      }, {
        timeout: 300000,
      }).then(data => {
        console.log(data)
      }).catch(errorCode => {
        console.log(errorCode)
        if(errorCode.response.status == 403) return;
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '機器再起動に失敗しました。'});
      });
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // 多重送信防止タイムアウト
      });
    },
  },
  watch: {
  },
}
</script>

<style lang="scss" scoped>
</style>
