<template>
  <v-container>
    <p class="title">録画設定</p>
    <v-layout
      justify-center
      wrap
      align-space-between>
      <!-- 実装時の値は未確定だったため、recorder.test を装填 -->
      <v-flex xs6> <p class="subheading">録画パスワード: </p> </v-flex>
      <v-flex xs6> {{ recorder.test }} </v-flex>
      <v-flex xs6> <p class="subheading">圧縮方式: </p> </v-flex>
      <v-flex xs6> {{ recorder.test }} </v-flex>
      <v-flex xs6> <p class="subheading">画像サイズ: </p> </v-flex>
      <v-flex xs6> {{ recorder.test }} </v-flex>
      <v-flex xs6> <p class="subheading">画質: </p> </v-flex>
      <v-flex xs6> {{ recorder.test }} </v-flex>
      <v-flex xs6> <p class="subheading">フレームレート: </p> </v-flex>
      <v-flex xs6> {{ recorder.test }} </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
    }
  },
  computed: {
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
  },
  mounted() {
  },
  methods: {
  },
  watch: {
  },
}
</script>

<style lang="scss" scoped>
</style>
