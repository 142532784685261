<template v-if="hasCustomerAdminPermission">
  <v-container>
    <p class="title">ライブ公開設定</p>
    <v-layout
      justify-center
      wrap
      align-space-between>
      <v-flex xs6> <p class="subheading">公開用:チャンネルURL: </p> </v-flex>
      <v-flex xs6> {{ recorder.publicChannel }} </v-flex>
      <v-flex xs6> <p class="subheading">公開用:配信サーバー: </p> </v-flex>
      <v-flex xs6> {{ recorder.publicRtmpServer }} </v-flex>
      <v-flex xs6> <p class="subheading">公開用:配信キー: </p> </v-flex>
      <v-flex xs6> {{ recorder.publicRtmpKey }} </v-flex>
      <v-flex xs6> <p class="subheading">非公開用:チャンネルURL: </p> </v-flex>
      <v-flex xs6> {{ recorder.privateChannel }} </v-flex>
      <v-flex xs6> <p class="subheading">非公開用:配信サーバー: </p> </v-flex>
      <v-flex xs6> {{ recorder.privateRtmpServer }} </v-flex>
      <v-flex xs6> <p class="subheading">非公開用:配信キー: </p> </v-flex>
      <v-flex xs6> {{ recorder.privateRtmpKey }} </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
      }
    },
    computed: {
      recorder() {
        const recorder =this.$store.state.recorders.recorder.data;
        if (recorder.vpn){
          return  {
            publicChannel : recorder.youtube.public.channel,
            publicRtmpServer : recorder.youtube.public.rtmpServer,
            publicRtmpKey : recorder.youtube.public.rtmpKey,
            privateChannel : recorder.youtube.private.channel,
            privateRtmpServer : recorder.youtube.private.rtmpServer,
            privateRtmpKey : recorder.youtube.private.rtmpKey,
          }
        }
        return recorder;
      },
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    },
  }
</script>

<style lang="scss" scoped>
</style>
