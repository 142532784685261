<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-container class="pa-2">
    <p v-touch:swipe="handleSwipe" class="title">
      <span style="display: inline-block" class="mb-4">ログ</span>
      <v-tooltip bottom>
        <v-btn
          slot="activator"
          :disabled="isDifferenceOverThreeMonths"
          @click="downloadCsv()"
          fab
          icon
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <span>ダウンロード</span>
      </v-tooltip>
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="startDate"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="startDate"
            v-on="on"
            label="開始日"
            prepend-icon="event"
            readonly
          />
        </template>
        <v-date-picker
          :day-format="date => new Date(date).getDate()"
          v-model="startPickerDate"
          locale="ja"
          no-title
          scrollable
        >
          <v-spacer />
          <v-btn @click="menu = false" flat color="primary">Cancel</v-btn>
          <v-btn @click="$refs.menu.save(startPickerDate)" flat color="primary">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <v-menu
        ref="menuEnd"
        v-model="menuEnd"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="endDate"
        lazy
        transition="scale-transition"
        offset-y
        full-width
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="endDate"
            v-on="on"
            label="終了日"
            prepend-icon="event"
            readonly
          />
        </template>
        <v-date-picker
          :day-format="date => new Date(date).getDate()"
          v-model="endPickerDate"
          locale="ja"
          no-title
          scrollable
        >
          <v-spacer />
          <v-btn @click="menuEnd = false" flat color="primary">Cancel</v-btn>
          <v-btn @click="$refs.menuEnd.save(endPickerDate)" flat color="primary">OK</v-btn>
        </v-date-picker>
      </v-menu>
      <v-subheader v-show="isDifferenceOverThreeMonths" class="red--text body-1">ダウンロード期間は３カ月以内をご指定ください</v-subheader>
    </p>
  </v-container>
</template>
<script>
  import * as types from "~/store/types";
  import moment from 'moment';

  export default {
    props: {
      progressColor: {type: String, default: 'primary'},
    },
    data() {
      return {
        menu: false,
        menuEnd: false,
        loading: false,
        startTime: Date.now() / 1000 | 0,
        endTime: Date.now() / 1000 | 0,
        logDate: new Date(),
        processing: false,

        // CSVダウンロード用途
        startDate: null,
        endDate: null,
        startPickerDate: null,
        endPickerDate: null,
        headers: [
          {
            text: '日時',
            value: 'event_date',
            sortable: false,
          },
          {
            text: '種別',
            value: 'log_type',
            sortable: false,
          },
          {
            text: '追加情報',
            value: 'event_meta',
            sortable: false,
          }
        ],
      }
    },
    computed: {
      isDifferenceOverThreeMonths() {
        return this.compareDate(this.startDate, this.endDate, 'months', diff => diff >= 3);
      },
      recorder() {
        return this.$store.state.recorders.recorder.data;
      },
      logs() {
        return this.$store.state.recorders.recorder.logs;
      }
    },
    mounted() {
      this.initDialogData();
    },
    created() {
      this.$store.commit('recorders/resetLogs');
    },
    methods: {
      handleSwipe(direction) {
        if (direction === 'right') {
          this.$emit('swipe', 'right');
        }
        if (direction === 'left') {
          this.$emit('swipe', 'left');
        }
      },
      async downloadCsv() {
        console.log("[INPUT] startDate: [" + this.startDate + "], endDate: [" + this.endDate + "]");
        const startDate = moment(this.startDate, "YYYY-MM-DD", true).unix();
        const endDate = moment(this.endDate, "YYYY-MM-DD", true).unix();
        console.log("[UNIX_TIMESTAMP] startDate: [" + startDate + "], endDate: [" + endDate + "]");

        if (startDate > endDate) {
          console.log("[ALERT] startDate > endDate");
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: "開始日と終了日の前後を見直して下さい。"});
          return
        }

        this.$store.dispatch("setLoadingState", true);

        const header = '"ログ発生日時"\t"ログ内容"\t"追加情報"\n';
        const copyEvents = await this.$axios.get(process.env.longrunBaseUrl + "/copy-events-download/" + this.recorder.id + "?startDate=" + startDate + "&endDate=" + endDate, {
          timeout: 300000,
        });
        console.log("[copyEvents]");
        console.log(copyEvents);
        const logs = _.map(copyEvents.data, log => {
          const rec =
            this.str(log.event_date) + '\t' +
            this.str(log.log_type) + '\t' +
            this.str(log.event_meta);
          console.log('[ONE_LINE] ' + rec);
          return rec;
        }).join('\n');

        if (logs === '') {
          console.log("[ALERT] no information");
          this.$store.dispatch("setLoadingState", false);
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: "ダウンロード可能な情報がありません。"});
          return;
        }

        // utf8
        const bom = '\uFEFF';
        const blob = new Blob([bom, header + logs], {type: 'text/tab-separated-values'});
        const startStr = moment(this.startDate, "YYYY-MM-DD").format("YYYYMMDD");
        const endStr = moment(this.endDate, "YYYY-MM-DD").format( "YYYYMMDD");
        const fileName = `${this.recorder.name}_${startStr}_${endStr}_ログ.tsv`;
        console.log("[INFO] CSV fileName: " + fileName);

        if (window.navigator.msSaveBlob) {
          console.log("[INFO][before] window.navigator.msSaveBlob");
          window.navigator.msSaveBlob(blob, fileName);
          console.log("[INFO][after] window.navigator.msSaveBlob");
        } else {
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
        this.$store.dispatch("setLoadingState", false);
      },
      str(v) {
        return "'" + v + "'";
      },
      initDialogData() {
        this.startDate = moment().format("YYYY-MM-DD");
        this.endDate = moment().format("YYYY-MM-DD");
        this.$store.dispatch("setLoadingState", false);
      },
      allowedDate: function (val) {
        const base = new Date(val);
        // 2019年1月1日から現在までを選択可能にする。
        return new Date(2019, 0, 1) <= base && base <= new Date();
      },
    },
  }
</script>

<style lang="scss" scoped>
</style>
