<template>
  <v-container>
    <p class="title">LTEリフレッシュ</p>
    <v-layout
      justify-center
      wrap
      align-space-between>
      <v-flex xs6> <p class="subheading">リフレッシュ設定: </p> </v-flex>
      <v-flex xs6>
        <v-select
          :items="items"
          v-model="refresh"
          label="設定"
          :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id)"
          ></v-select>
      </v-flex>
      <v-flex xs6> <p class="subheading">リフレッシュ時間: </p> </v-flex>
      <v-flex xs6>
        <v-select
          v-model="hour"
          :items="hours"
          label="HH"
          required
          :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id)"
          ></v-select>
        <v-select
          v-model="min"
          :items="mins"
          label="MM"
          required
          :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id)"
          ></v-select>
      </v-flex>
      <v-flex xs6>
        <v-btn
        :disabled="!this.hasMaintenanceOperation(this.recorder.organization_id) || processing"
        @click.prevent="dialog = true">保存</v-btn>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="dialog"
      max-width="480"
    >
      <v-card>
        <v-card-title class="subheading">
          <v-flex xs12 class="dialog-title-margin">LTEリフレッシュ設定を変更します。</v-flex>
          <v-flex xs12 class="dialog-title-margin">よろしいですか？</v-flex>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="dialog-button-margin"
            color="grey darken-2"
            outline
            @click="dialog = false"
          >
            いいえ
          </v-btn>

          <v-btn
            class="dialog-button-margin"
            color="primary"
            @click="submit()"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as types from "~/store/types";
import {validator} from "~/plugins/validator";

export default {
  data() {
    return {
      items: [{text: 'オン', value: 'on',}, {text: 'オフ', value: 'off'}],
      mins:["00","15","30","45"],
      valid: true,
      refresh: null,
      hour: "00",
      min: "00",
      processing: false,
      dialog: false,
    }
  },
  computed: {
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
    /**
     * 0埋めされた時間の配列を作成する。
     * @returns {string[]} 0埋めされた時間の配列('00', '01' ... '23')
     */
    hours() {
      return [...Array(24).keys()]
        .map(num => String(num))
        .map(numAsStr => numAsStr.padStart(2, '0'));
    }
  },
  methods: {
    submit() {
      this.dialog = false;
      if (this.processing) return;
      this.processing = true;

      this.saveRefresh()
        .then(() => {
          this.processing = false;
        });
    },
    saveRefresh(){
      const recorder = this.$store.state.recorders.recorder.data;
      if (this.refresh == null) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: 'リフレッシュ設定を選択してください。'})
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve();
          }, 1000); // 多重送信防止タイムアウト
        });
      }

      // 機器設定変更CGI実行

      // タウンレコーダー側の制約で分が0の場合は00として送る必要がある。
      const lteResetMin = this.min === 0 ? '00' : this.min;
      const cgiParams = "lte_reset_enable=" + this.refresh + "&lte_reset_hour=" + this.hour + "&lte_reset_min=" + lteResetMin;
      this.$axios.post(process.env.cgiBaseUrl, {
        recorderID: this.recorder.id,
        method: 'POST',
        url: '/configwrite.cgi',
        data: cgiParams,
      }, {
        timeout: 300000,
      }).then(data => {
        console.log(data)
        console.log("機器設定変更CGI実行 [成功]")
      }).catch(errorCode => {
        console.log(errorCode)
        console.log("機器設定変更CGI実行 [失敗]")
        if(errorCode.response.status == 403) return;
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '機器設定変更に失敗したため、LTEリフレッシュ設定を変更しませんでした。'});
      });
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // 多重送信防止タイムアウト
      });
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
