<template>
  <!-- 通知用スナックバー -->
  <v-snackbar
    v-model="snackbar"
    :color="notification.color"
    :timeout="timeout"
  >
    {{ notification.message }}
    <v-btn
      dark
      flat
      @click="closeSnackbar"
    >
      閉じる
    </v-btn>
  </v-snackbar>
</template>

<script>
  import * as types from "~/store/types";
  export default {
    data() {
      return {
        timeout: 5000,
        snackbar: false,
      }
    },

    mounted() {
    },

    computed: {
      notification() {
        const n = this.$store.getters[types.NOTIFICATION];
        this.snackbar = n.message ? true : false;
        // TODO: スナックバーのタイムアウト発動タイミングをフックする方法が不明のため、苦肉の策で「同じ時間経過後にスナックバーを非活性化」する対応を仕込む
        if (this.snackbar) {
          console.log('setTimeout');
          setTimeout(this.closeSnackbar, this.timeout);
        }
        return n;
      },
    },

    methods: {
      closeSnackbar() {
        this.$store.dispatch(types.DEACTIVATE_NOTIFICATION);
        this.snackbar = false;
      }
    },
  }
</script>

<style lang="scss" scoped>
</style>
