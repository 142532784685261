type ValidationResult = boolean | string

// FIXME: vee-validatorに乗り換えたい
export const validator = {
  validRequired: (val): ValidationResult => {
    return !!val || '必須です';
  },
  validMaxLength: (val, len): ValidationResult => {
    return (val && val.length <= len) || '最大' + len + '文字です';
  },
  validUserName: (val): ValidationResult => {
    const re = /^[^A-Z]+$/;
    return re.test(val) || '大文字が含まれています';
  },
  validEmail: (email, checkRequired): ValidationResult => {
    if (checkRequired) {
      const reqChk = validator.validRequired(email);
      if (reqChk !== true) {
        return reqChk;
      }
    }
    if (!email) {
      return true;
    }
    const lenChk = validator.validMaxLength(email, 128);
    if (lenChk !== true) {
      return lenChk;
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email) || '不正な形式です';
  },
  validPassword: (passwd, checkRequired): ValidationResult => {
    if (checkRequired) {
      const reqChk = validator.validRequired(passwd);
      if (reqChk !== true) {
        return reqChk;
      }
    }
    if (!passwd) {
      return true;
    }
    if (String(passwd).length < 8) {
      return '8文字以上'; // MEMO: FirebaseAuth上は6文字以上のようだが、要求仕様書にて8〜32文字とあるため
    }
    if (String(passwd).length > 32) {
      return '32文字以内';
    }
    // 要求仕様書にて「半角英数と半角記号を使用可能」とあるため
    const re = /^[a-zA-Z0-9!"#$%&'()\*\+\-\.,\/:;<=>?@\[\\\]^_`{|}~¥]*$/;
    if (!re.test(passwd)) {
      return '半角英数記号のみ';
    }
    // 要求仕様書にて「半角英数と半角記号をそれぞれ１文字は含むこと」とあるため
    // ↑2019/3/5 お客様要望により以下の仕様に変更となったため、チェック内容変更
    // 「半角アルファベット、半角数字を含む8字～32文字。ただし記号も入力可能」
    // 2019/05/21 半角英字と半角数字の両方を使用しなければ登録できないように変更
    // [12345678] => X　[a1234567] => O
    const hasEi = /[a-zA-Z]/.test(passwd);
    const hasSu = /[0-9]/.test(passwd);
    if (hasEi && hasSu) {
      return true;
    }
    return '半角アルファベット、半角数字のいずれも1文字は含むこと';
  },
  // 半角英数字(小文字大文字)半角アンダーバー半角ドット62文字
  validVPNtenantIDgroupID: (id, checkRequired): ValidationResult => {
    if (checkRequired) {
      const reqChk = validator.validRequired(id);
      if (reqChk !== true) {
        return reqChk;
      }
    }
    if (!id) {
      return true;
    }
    const lenChk = validator.validMaxLength(id, 62);
    if (lenChk !== true) {
      return lenChk;
    }
    const re = /^[a-zA-Z0-9_\-.]*$/;
    return re.test(id) || '不正な形式です';
  },
  // 半角数字4文字
  validVPNcode: (code, checkRequired): ValidationResult => {
    if (checkRequired) {
      const reqChk = validator.validRequired(code);
      if (reqChk !== true) {
        return reqChk;
      }
    }
    if (!code) {
      return true;
    }
    const lenChk = validator.validMaxLength(code, 4);
    if (lenChk !== true) {
      return lenChk;
    }
    const re = /^[0-9]*$/;
    return re.test(code) || '不正な形式です';
  },
  // 全角および半角英数字1~32文字
  validAux: (aux, checkRequired): ValidationResult => {
    if (!aux) {
      return true;
    }

    if (String(aux).length > 32) {
      return '32文字以内';
    }
    return true
  },
  // LTEリフレッシュ設定
  validHour: (hour, checkRequired): ValidationResult => {
    if (!hour) {
      return true;
    }
    if (hour > 23) {
      return '不正な値です';
    }
    const re = /^[0-9]*$/;
    return re.test(hour) || '不正な形式です';
  },
  validMinute: (minute, checkRequired): ValidationResult => {
    if (!minute) {
      return true;
    }
    if (minute > 59) {
      return '不正な値です';
    }
    const re = /^[0-9]*$/;
    return re.test(minute) || '不正な形式です';
  },
  //SIMデータ容量用のバリデーション
  validSimTrafficLimit: (limit): ValidationResult => {
    const errMsg = '整数のみ入力可能です';
    if(limit > 999999 || limit < 0){
      return errMsg;
    }
    const re = /^[0-9]{1,6}$/;
    return re.test(limit) || errMsg;
  }
};
