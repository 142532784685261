export class DownloadAudioRequest {
  public id: string;
  public recorderID: string;
  public fileName: string;
  public url: string;
  public title: string;
  public size: string;
  public duration: string;

  public constructor(id, fileName, url, title,  size, duration) {
    this.id = id + fileName;
    this.recorderID = id;
    this.fileName  = fileName;
    this.url = url;
    this.title = title;
    this.size = size;
    this.duration = duration;
  }

  public validate(): string[] {
    const errs = [];
    if(this.fileName === "" || this.fileName === undefined || this.fileName === null){
      errs.push("fileNameが空です。");
    }
    if(this.fileName){
      if(35 < this.fileName.length) {
        errs.push("ファイル名は35文字以内である必要があります。", this.fileName);
      }

      const ok = !!(this.fileName.match("^[a-zA-Z0-9-#\\.]+\\.wav$"));
      if(!ok){
        errs.push("fileNameの値が異常です。", this.fileName);
      }
    }

    if(this.url === "" || this.url === undefined || this.url === null){
      errs.push("urlが空です。")
    }
    if(this.url){
      const startByProtocol = !!(this.url.match("^http"));
      if(!startByProtocol){
        errs.push("URLがhttpから始まりません。");
      }

      const lengthOver65535 = 65535 < this.url.length;
      if(lengthOver65535){
        errs.push("URLの文字数は65535文字以内である必要があります。");
      }

      const zenkakuExist = !!(this.url.match("[^\x01-\x7E\xA1-\xDF]+"));
      if(zenkakuExist){
        errs.push("URLに全角文字列が含まれています。");
      }
    }
    if(this.size === "" || this.size === undefined || this.size === null){
      errs.push("sizeが空です。")
    }
    if(this.size){
      const ok = !!(this.size.match("^[0-9]+\\.[0-9]+$|^[0-9]+$"));
      if(!ok){
        errs.push("sizeの値が異常です。");
      }
    }

    //titleはブランクを許容
    if(this.title) {
      const titleOver32 = 32 < this.title.length;
      if(titleOver32) {
        errs.push("titleは32文字以内である必要があります。");
      }
    }

    if(this.duration === "" || this.duration === undefined || this.duration === null){
      errs.push("durationが空です。");
    }
    if(this.duration){
      const ok = !!(this.duration.match("^[0-9][0-9]:[0-9][0-9]:[0-9][0-9]$"))
      if(!ok){
        errs.push("durationの値が異常です。");
      }
    }
    return errs;
  }

}
