export class AvailableAudio {
  public id: string;
  public recorderID: string;
  public title: string;
  public fileName: string;
  public size: string;
  public duration: string;
  public ownership: string;

  public editing = false;

  public constructor(recorderID, title, fileName, size, duration, ownership: string) {
    this.id = recorderID + fileName;
    this.recorderID = recorderID;
    this.title = title;
    this.fileName  = fileName;
    this.size = size;
    this.duration = duration;
    this.ownership = ownership;
  }

  public canDelete(): boolean{
    return this.ownership === 'TOA';
  }
}
