export const state = () => ({
  count: 0,
});

export const getters = {
  count: (state) => {
    return state.count;
  }
};

export const mutations = {
  increment (state, v) {
    state.count += v;
  }
};

export const actions = {
  increment (context, v) {
    context.commit('increment', v);
  }
};
