<template>
  <v-container class="preset-container">
    <p class="title">プリセット</p>
    <v-layout
      justify-center
      wrap
      align-space-between>
      <v-flex xs10>
        <v-select
          class="selectbox -word-break-all"
          v-model="selected"
          :items="items"
          label="プリセット"
          :disabled="!this.hasPtzOperation(this.recorder.organization_id)"/>
      </v-flex>
      <v-flex xs2>
        <v-btn @click="presetPlay" :disabled="!this.hasPtzOperation(this.recorder.organization_id)">移動</v-btn>
      </v-flex>
      <v-flex xs10>
        <v-text-field
          v-model="name"
          label="名称"
          required
          :disabled="!this.hasPtzOperation(this.recorder.organization_id)"/>
      </v-flex>
      <v-flex xs2>
        <v-btn
          :disabled="disablePresetName"
          @click="submit">保存
        </v-btn>
      </v-flex>
    </v-layout>

    <!-- 更新ボタン押下時の確認ダイアログ -->
    <v-dialog
      v-model="presetConfirm"
      max-width="480"
    >
      <v-card>
        <v-card-title class="subheading">
          <span class="dialog-title-margin">プリセット名を更新してもよろしいですか？</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="dialog-button-margin"
            color="grey darken-2"
            outline
            @click="presetConfirm = false"
          >
            いいえ
          </v-btn>

          <v-btn
            class="dialog-button-margin"
            color="primary"
            @click="saveRecorderPreset"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import * as types from "~/store/types";

  export default {
    props: {
      recorder: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        presetConfirm: false,
        selected: 0,
        name: "",
        unsubscribe: {},
      }
    },
    computed: {
      items: {
        cache: false,
        get() {
          const mapping = _.map(this.$store.state.recorders.recorder.preset, i => {
            if (i.Name) {
              return {text: i.NO + ": " + i.Name, value: i.NO};
            } else {
              return {text: i.NO, value: i.NO};
            }
          });
          mapping.shift();
          return mapping
        }
      },
      currentPath() {
        return this.$store.getters['path'];
      },
      isOverPresetNameMaxLength() {
        return this.name.length > 32;
      },
      disablePresetName() {
        return !this.hasPtzOperation(this.recorder.organization_id) || this.isOverPresetNameMaxLength;
      },
    },
    watch: {
      currentPath(path) {
        if (path !== '/maps') {
          this.unsubscribe();
        }
      },
      recorder: {
        immediate: true,
        handler() {
          if (!this.recorder.id) {
            return;
          }
          this.$store.dispatch('recorders/listPreset', this.recorder.id);
        }
      }
    },
    mounted() {
      this.$store.dispatch('recorders/listPreset', this.recorder.id);
      this.subscribeRecorderChanges();
    },
    beforeDestroy() {
      this.unsubscribe();
    },
    methods: {
      submit() {
        const selected = this.selected - 1
        if (selected < 0) {
          this.$store.dispatch(types.ACTIVATE_INFO_NOTIFICATION, {message: 'プリセットを選択して下さい。'});
          return;
        }

        if (this.isOverPresetNameMaxLength) {
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: 'プリセット名は33文字以上指定できません。'});
          return;
        }
        this.presetConfirm = true;
      },

      async saveRecorderPreset() {
        // 操作権限判定
        if (!this.hasPtzOperation(this.recorder.organization_id)) {
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '操作権限がありません。'});
          this.presetConfirm = false;
          return
        }

        this.$store.dispatch('recorders/listPreset', this.recorder.id);
        const data = "GET\\r\\n/camctrl.cgi?presetmemory=" + this.selected + "&\\r\\n\\r\\n";
        try {
          const cgiResult = await this.$axios.post(process.env.cgiBaseUrl, {
              recorderID: this.recorder.id,
              method: 'POST',
              url: '/forwarding.cgi',
              data: data,
            }, {
              timeout: 300000,
            }
          );
          const payload = {
            recorder_id: this.$store.state.recorders.recorder.data.id,
            no: this.selected,
            name: this.name,
          };
          await this.$store.dispatch('recorders/createPreset', payload);
        } catch (errorCode) {
          if (errorCode.response.status == 403) {
            console.log(errorCode)
            return;
          }
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '転送要求に失敗しました。'});
        }
        this.presetConfirm = false;
      },
      presetPlay() {
        // 操作権限判定
        if (!this.hasPtzOperation(this.recorder.organization_id)) {
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '操作権限がありません。'});
          return;
        }

        const data = "GET\\r\\n/camctrl.cgi?presetplay=" + this.selected + "&\\r\\n\\r\\n";
        this.$axios.post(process.env.cgiBaseUrl, {
          recorderID: this.recorder.id,
          method: 'POST',
          url: '/forwarding.cgi',
          data: data,
        }, {
          timeout: 300000,
        }).then(data => {
          console.log(data)
        }).catch(errorCode => {
          console.log(errorCode)
          if (errorCode.response.status == 403) return;
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '転送要求に失敗しました。'});
        });
      },
      subscribeRecorderChanges() {
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
          if (mutation.type === 'map/selectRecorder' || mutation.type === 'view/changeRecorder') {
            this.name = '';
          }
        });
      },
      syncSelectedPresetNameAndInputFormValue() {
        /**
         * this.items[this.selected].textには、
         * プリセット名が存在する場合は1. タウンレコーダー1(String型)
         * 存在しない場合は1(Number型)のような形のものが入るようになっている。
         * Number型に対してindexOfを実行すると実行時エラーになってしまうので、型での分岐を入れている。
         * また、Number型が入ってくる場合はプリセット名が空なので、プリセット名の入力欄も空白にしている。
         */
        const selected = this.selected - 1
        if (typeof this.items[selected].text === 'string') {
          const spaceFirstOccurredIdx = this.items[selected].text.indexOf(' ');
          this.name = this.items[selected].text.slice(spaceFirstOccurredIdx + 1);
        } else {
          this.name = '';
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .preset-container {
    .selectbox {
      &.-word-break-all {
        word-break: break-all;
      }
    }
  }
</style>
