<template>
  <v-container>
    <p class="title">接点出力制御</p>
    <v-layout
      row
      align-center>
      <v-flex xs11>
        <span class="word-wrap">{{ label.aux1 }}</span>
      </v-flex>
      <v-flex xs1>
          <v-switch style="display:inline-block;" color="orange" v-model="aux.out1" @change="submit(1)" :disabled="loading || !this.hasAuxout1Operation(this.recorder.organization_id)"></v-switch>
      </v-flex>
    </v-layout>
    <v-layout
      row
      align-center>
      <v-flex xs11>
        <span class="word-wrap">{{ label.aux2 }}</span>
      </v-flex>
      <v-flex xs1>
        <v-switch style="display:inline-block;" color="orange" v-model="aux.out2" @change="submit(2)" :disabled="loading || !this.hasAuxout2Operation(this.recorder.organization_id)"></v-switch>
      </v-flex>
    </v-layout>
    <v-layout
      row
      align-center>
      <v-flex xs11>
        <span class="word-wrap">{{ label.aux3 }}</span>
      </v-flex>
      <v-flex xs1>
        <v-switch style="display:inline-block;" color="orange" v-model="aux.out3" @change="submit(3)" :disabled="loading || !this.hasAuxout3Operation(this.recorder.organization_id)"></v-switch>
      </v-flex>
    </v-layout>

    <!-- 制御ボタン押下時の確認ダイアログ -->
    <v-dialog
      v-model="auxConfirm"
      max-width="480"
    >
      <v-card>
        <v-card-title class="subheading">
          <span class="dialog-title-margin">変更してもよろしいですか？</span>
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="dialog-button-margin"
            color="grey darken-2"
            outline
            @click="cancel(auxnum)"
            :disabled="loading"
          >
            いいえ
          </v-btn>

          <v-btn
            class="dialog-button-margin"
            color="primary"
            @click="handleAux(auxnum)"
            :disabled="loading"
          >
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import * as types from "~/store/types";

export default {
  data() {
    return {
      auxConfirm : false,
      auxnum : 0,
      aux: {
        out1: false,
        out2: false,
        out3: false,
      },
      success : false,
      loading : true
    }
  },
  computed: {
    recorder() {
      return this.$store.state.recorders.recorder.data;
    },
    label() {
      const aux = this.$store.state.recorders.recorder.data.aux;
      return {
        aux1: aux.out1 ? aux.out1 : '接点1',
        aux2: aux.out2 ? aux.out2 : '接点2',
        aux3: aux.out3 ? aux.out3 : '接点3',
      };
    },
  },
  mounted() {
    // LPWAレコーダー→LTEレコーダーと選択するとaux.vue自体が一旦破棄されるので、watchだけじゃなくてmountedでも接点出力を初期化している。
    this.initAuxOuts();
  },
  methods: {
    submit(i) {
      this.auxnum = i;
      this.success = false;
      this.auxConfirm = true;
    },
    cancel(i){
      this.success = false;
      this.auxConfirm = false;
    },
    // watchでの判定の場合、CGI実行エラーで値を変更前に戻すと、その変更を検知して、また同じ関数が発動してしまうため、@change によるメソッド実行形式とする。
    // また、CGI実行エラーで値を変更前に戻す必要性から、メソッドのパラメータに、どの要素の値を戻すかを示すインデックスを用意。
    async handleAux(i) {
      // 操作権限判定
      if (!this.hasAuxoutOperation(this.recorder.organization_id, i)) {
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '操作権限がありません。'});
        return;
      }

      let data = '';
      switch (i) {
        case 1:
          data = 'out1=' + (this.aux.out1 ? 'on' : 'off');
          break;
        case 2:
          data = 'out2=' + (this.aux.out2 ? 'on' : 'off');
          break;
        case 3:
          data = 'out3=' + (this.aux.out3 ? 'on' : 'off');
          break;
      }
      try {
        this.loading = true;
        const res = await this.$axios.post(process.env.cgiBaseUrl, {
            recorderID: this.recorder.id,
            method: 'GET',
            url: '/ctrlaux.cgi',
            data: data,
        }, {
            //withCredentials: true,
            timeout: 300000,
        });
        console.log(res);
        this.success = true;
        this.auxConfirm = false;
      } catch (err){
        console.log(err);
        this.success = false;
        if(err.response.status == 403) return;
        this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: '接点出力制御の切り替えに失敗しました。'});
      } finally {
        this.loading = false;
      }
    },
    async initAuxOuts() {
      try {
        this.loading = true;
        const res = await this.$axios.post(process.env.cgiBaseUrl, {
          recorderID: this.recorder.id,
          method: 'GET',
          url: '/ctrlaux.cgi',
          data: 'out1=get&out2=get&out3=get',
        }, {
          timeout: 300000,
        });
        _.each(res.data.split('&'), e => {
          const o = e.split('=');
          this.aux[o[0]] = o[1] === 'on';
        });
        this.loading = false;
      } catch(err){
        console.log(err);
        const isActivate = this.$store.getters[types.IS_ACTIVATE];
        console.log(isActivate);
        if(!isActivate) {
          this.$store.dispatch(types.ACTIVATE_ERROR_NOTIFICATION, {message: 'レコーダー情報の取得に失敗しました。'});
        }
      }
    }
  },
  watch: {
    auxConfirm: function(val){
      if(val){ // 確認画面が開いた際はなにもしない
        return;
      }
      if (this.success) { // 確認画面での操作が完了した場合
        return;
      }

        // 失敗した場合はトグルを戻す
      const i = this.auxnum;
      switch (i) {
        case 1:
          this.aux.out1 = !this.aux.out1;
          break;
        case 2:
          this.aux.out2 = !this.aux.out2;
          break;
        case 3:
          this.aux.out3 = !this.aux.out3;
          break;
      }
    },
    recorder: {
      immediate: true,
      handler() {
        this.initAuxOuts();
      }
    },

  },
}
</script>

<style lang="scss" scoped>
.word-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
}
</style>
