export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    if (
      to.name === 'login' ||
      to.name === 'index'
    ) {
      next();
      return;
    }
    app.store.commit('setLoadingState', true);
    next();
  });
  app.router.afterEach(() => {
    app.store.commit('setLoadingState', false);
  });
};
