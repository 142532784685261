import Vue from 'vue'
import Vuetify from 'vuetify'
import colors from 'vuetify/es5/util/colors'

Vue.use(Vuetify, {
  theme: {
    primary: "#FF8200",
    accent: colors.grey.darken3,
    secondary: colors.amber.darken3,
    info: '#68DCBE',
    warning: '#a62632',
    error: colors.deepOrange.accent4,
    success: '#03AF7A',
  }
})
